const hasGate = (auth, gateName) => {
  return auth.gates.includes(gateName);
};
const hasScope = (auth, scopeName) => {
  return auth.user.scopes.includes(scopeName);
};
export const filterUnauthorizedRecommendations = (recommendations, auth) => {
  const filteredRecommendations = recommendations.filter(rec => {
    const {
      gates,
      scopes,
      permissionOverride
    } = rec;
    if (permissionOverride) {
      return permissionOverride(auth);
    }
    let isUngated = true;
    let hasScopeAccess = true;
    if (gates) {
      isUngated = gates.every(gate => hasGate(auth, gate));
    }
    if (scopes) {
      hasScopeAccess = scopes.every(scope => hasScope(auth, scope));
    }
    return isUngated && hasScopeAccess;
  });
  return filteredRecommendations;
};