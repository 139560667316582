'use es6';

export default {
  failedDmarc: 'editors.replyTo.dmarcFailedNoLink',
  preview_text: 'panes.review.sections.settings.items.preview_text.brief_warning',
  campaign: 'panes.review.sections.settings.items.campaign.brief_warning',
  hasInvalidDynamicPageSourceValue: 'editor.errors.hasInvalidDynamicPageSourceValue',
  cannotUseCrmObjectTypeBecauseRestricted: 'editor.errors.cannotUseCrmObjectTypeBecauseRestricted',
  noHubDbCustomDomainAccess: 'editor.errors.noHubDbCustomDomainAccess',
  smartRules: 'panes.review.sections.content.items.smart_content.brief_warning',
  primaryDomainMissing: 'editor.errors.primaryDomainMissing',
  invalidDomain: 'editor.errors.invalidDomain',
  isSitePageOnPagebuilderDomainWithAdvancedAccess: 'editor.warnings.isSitePageOnPagebuilderDomainWithAdvancedAccess',
  isBlogOnPagebuilderDomainWithAdvancedAccess: 'editor.warnings.isBlogOnPagebuilderDomainWithAdvancedAccess',
  isSchedulingAlreadyPublished: 'publish.cannotSchedulePublishedPage',
  userHasNoPublishAccess: 'editor.errors.userHasNoPublishAccess',
  passwordAudienceAccess: 'editor.errors.passwordAudienceAccess',
  passwordAudienceAccessTooShort: 'editor.errors.passwordAudienceAccessTooShort',
  accessGroupsRequired: 'editor.errors.accessGroupsRequired',
  svgImageWarning: 'panes.review.sections.content.items.svg_image_checker.brief_warning',
  styleWithinBody: 'panes.review.sections.content.items.styles_in_body.brief_warning',
  missing_ab_permission: 'panes.review.sections.content.items.missing_ab_permission.brief_warning',
  externalStylesheets: 'panes.review.sections.content.items.external_stylesheets.brief_warning',
  DEFAULT_CONTENT: 'errors.DEFAULT_CONTENT',
  CONTENT_BLANK: 'errors.CONTENT_BLANK',
  RSS_ENTRIES_NOT_RENDERED: 'errors.RSS_ENTRIES_NOT_RENDERED',
  language: 'components.header.validationErrorPopover.settingsErrors.languageExists',
  DEPRECATED_GENEVA_FONT_FAMILY_VALIDATION: 'errors.DEPRECATED_GENEVA_FONT_FAMILY_VALIDATION'
};