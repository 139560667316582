import produce from 'immer';
import { FETCH_MODULE_VERSIONS_SUCCESS } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  downgradableAssets: {},
  otherAssets: {},
  pinnedAssets: {},
  upgradableAssets: {}
};
const moduleVersionsReducer = (state = initialState, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case FETCH_MODULE_VERSIONS_SUCCESS:
      {
        return payload;
      }
    default:
      return draft;
  }
});
export default moduleVersionsReducer;