import { stringify } from 'hub-http/helpers/params';
import { ContentObjectTypes } from '../constants/ContentObjectTypes';
import PortalIdParser from 'PortalIdParser';
import { getContentPropertyValue } from './getContentPropertyValue';
import { CONTENT_PLATFORM_PROPERTY_NAME } from '../constants/ContentPlatformPropertyName';
const getStringifiedQueryParams = query => query ? `?${stringify(query)}` : '';
export const getListingAppUrl = ({
  crmObject,
  contentObjectType,
  query
}) => {
  const portalId = PortalIdParser.get();
  let listingUrl = '';
  switch (contentObjectType) {
    case ContentObjectTypes.BLOG_POST:
      listingUrl = `/website/${portalId}/blog/posts`;
      break;
    case ContentObjectTypes.LANDING_PAGE:
      listingUrl = `/website/${portalId}/pages/landing`;
      break;
    case ContentObjectTypes.SITE_PAGE:
      listingUrl = `/website/${portalId}/pages/site`;
      break;
    case ContentObjectTypes.KNOWLEDGE_ARTICLE:
      listingUrl = `/knowledge/${portalId}/${crmObject ? `${getContentPropertyValue(crmObject, CONTENT_PLATFORM_PROPERTY_NAME.GROUP_ID)}/articles` : ''}`;
      break;
    case ContentObjectTypes.CASE_STUDY:
      listingUrl = `/studies/${portalId}/${crmObject ? `${getContentPropertyValue(crmObject, CONTENT_PLATFORM_PROPERTY_NAME.GROUP_ID)}/case-studies` : ''}`;
      break;
    default:
      break;
  }
  return `${listingUrl}${getStringifiedQueryParams(query)}`;
};