'use es6';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import UITag from './UITag';
import * as Colors from 'HubStyleTokens/colors';
import { TAG_ICON_SPACING_X } from 'HubStyleTokens/sizes';
const statusColors = {
  disabled: Colors.BATTLESHIP,
  success: Colors.OZ,
  warning: Colors.MARIGOLD,
  danger: Colors.CANDY_APPLE,
  info: Colors.CALYPSO
};
const getDotColor = (color, use) => {
  if (color != null) return color;
  const computedUse = UITag.STATUS_ALIAS_MAP[use] || use;
  return statusColors[computedUse] || Colors[computedUse.toUpperCase().replace('-', '_')] || Colors.BATTLESHIP;
};
const UIStatusDot = styled.span.withConfig({
  displayName: "UIStatusDot",
  componentId: "sc-76kzhd-0"
})(["background-color:", ";border:", ";border-radius:100%;display:inline-block;margin-right:", ";aspect-ratio:1 / 1;&&{height:10px;width:10px;}"], ({
  color,
  hollow,
  use
}) => !hollow && getDotColor(color, use), ({
  color,
  hollow,
  use
}) => hollow && `2px solid ${getDotColor(color, use)}`, TAG_ICON_SPACING_X);
UIStatusDot.propTypes = {
  color: PropTypes.string,
  hollow: PropTypes.bool.isRequired,
  use: UITag.propTypes.use
};
UIStatusDot.defaultProps = {
  hollow: false,
  use: 'info'
};
UIStatusDot.getDotColor = getDotColor; // expose for tests

export default UIStatusDot;