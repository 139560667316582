import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
export const upgradeForFormAutomationAudit = artifacts => {
  const forms = artifacts[GathererKeys.FORMS];
  let status;
  if (!forms.length) {
    status = CompletionStatuses.NOT_APPLICABLE;
  } else {
    status = CompletionStatuses.INCOMPLETE;
  }
  return {
    status
  };
};