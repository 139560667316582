import produce from 'immer';
import { UPDATE_AI_AB_TEST_FLOW_IS_ACTIVE, UPDATE_AI_AB_TEST_PAGE_BUILD, UPDATE_AI_AB_TEST_SUGGESTIONS_GEN, UPDATE_SELECTED_AI_AB_TEST_SUGGESTIONS, RESET_AI_AB_TEST_FLOW
// @ts-expect-error actionTypes not yet typed
} from '../actions/actionTypes';
export const AI_AB_TEST_FLOW_INITIAL_STATE = {
  isActive: false,
  suggestionsGen: undefined,
  pageBuild: undefined,
  selectedSuggestions: null
};
export const aiAbTestFlowReducer = produce((draft, {
  type,
  payload
}) => {
  switch (type) {
    case UPDATE_AI_AB_TEST_FLOW_IS_ACTIVE:
      draft.isActive = payload;
      break;
    case UPDATE_AI_AB_TEST_PAGE_BUILD:
      {
        const {
          status,
          data
        } = payload;
        if (data) {
          draft.pageBuild = data;
        }
        draft.pageBuild.status = status;
        break;
      }
    case UPDATE_AI_AB_TEST_SUGGESTIONS_GEN:
      {
        const {
          status,
          data
        } = payload;
        if (data) {
          draft.suggestionsGen = data;
        }
        draft.suggestionsGen.status = status;
        break;
      }
    case UPDATE_SELECTED_AI_AB_TEST_SUGGESTIONS:
      {
        const {
          selectedSuggestion
        } = payload;
        // This is an array, but for MVP, we're only allowing one selection at a time, so we're replacing it on each update
        draft.selectedSuggestions = [selectedSuggestion];
        break;
      }
    case RESET_AI_AB_TEST_FLOW:
      {
        draft.suggestionsGen = undefined;
        draft.pageBuild = undefined;
        draft.selectedSuggestions = null;
        break;
      }
    default:
      {
        return draft;
      }
  }
}, AI_AB_TEST_FLOW_INITIAL_STATE);