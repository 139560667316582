import { CollaborationSidebarStateProperties, TABS } from 'collaboration-sidebar';
import { getIsOnCommentingTab } from 'ContentEditorUI/redux/selectors/collaborationSidebarSelectors';
import { HIDE_COLLABORATION_SIDEBAR_COMMENT_POPOVER, SHOW_COLLABORATION_SIDEBAR_COMMENT_POPOVER, UPDATE_COLLABORATION_SIDEBAR_STATE } from './actionTypes';
import { getCollaborationSidebarCommentPopoverState } from 'ContentEditorUI/redux/selectors/modalSelectors';
const {
  IS_OPEN,
  TAB
} = CollaborationSidebarStateProperties;
export const updateCollaborationSidebarState = updates => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: updates
});
export const openCommentTab = () => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: {
    [IS_OPEN]: true,
    [TAB]: TABS.COMMENTS
  }
});
export const toggleCommentTab = () => (dispatch, getState) => {
  const state = getState();
  const isOnCommentingTab = getIsOnCommentingTab(state);
  dispatch({
    type: UPDATE_COLLABORATION_SIDEBAR_STATE,
    payload: isOnCommentingTab ? {
      [IS_OPEN]: false
    } : {
      [IS_OPEN]: true,
      [TAB]: TABS.COMMENTS
    }
  });
};
export const openApprovalsTab = () => ({
  type: UPDATE_COLLABORATION_SIDEBAR_STATE,
  payload: {
    [IS_OPEN]: true,
    [TAB]: TABS.APPROVALS
  }
});
export const openCollaborationSidebarCommentPopover = id => ({
  type: SHOW_COLLABORATION_SIDEBAR_COMMENT_POPOVER,
  payload: {
    id
  }
});
export const hideCollaborationSidebarCommentPopover = () => ({
  type: HIDE_COLLABORATION_SIDEBAR_COMMENT_POPOVER
});
const toggleCommentPopover = (dispatch, id, toggleOn) => {
  if (toggleOn) {
    dispatch(hideCollaborationSidebarCommentPopover());
    setTimeout(() => {
      dispatch(openCollaborationSidebarCommentPopover(id));
      // TODO: Look into alternatives that will let us close the popover
      // then open a new one sequentially. If using
      // ControlledEmbeddedCommentPopover as a controlled component, it
      // can't handle cases where a popover is simultaneously closed and
      // opened with a different id since closing one causes the `onOpenChange`
      // callback to also trigger that tells us to close the popover.
    }, 100);
  } else {
    dispatch(hideCollaborationSidebarCommentPopover());
  }
};
export const toggleCollaborationSidebarCommentPopover = ({
  id,
  isOpen
}) => (dispatch, getState) => {
  // If isOpen is provided, we open or close the popover based on its value.
  // Otherwise, we toggle on/off based on whether or not a popover is open.
  if (isOpen !== undefined) {
    toggleCommentPopover(dispatch, id, isOpen);
  } else {
    const state = getState();
    const {
      isOpen: isCurrentlyOpen
    } = getCollaborationSidebarCommentPopoverState(state);
    toggleCommentPopover(dispatch, id, !isCurrentlyOpen);
  }
};