/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-declarations */
import Raven from 'raven-js';
export default function logError(location, error, additionalProperties) {
  const {
    errorCode,
    errorMessage,
    message,
    responseJSON,
    status,
    data
  } = error;
  Raven.captureException(new Error(`ui-addon-upgrades-error (legacy NewRelic log): ${errorMessage}`), {
    extra: Object.assign({
      location,
      errorCode,
      errorMessage,
      message,
      responseJSON,
      status,
      data
    }, additionalProperties)
  });
}