export const LIST_MEMBERSHIP = 'LIST_MEMBERSHIP';
export const SSO_LOGIN = 'SSO_LOGIN';
export const MEMBERSHIP_LOGIN = 'MEMBERSHIP_LOGIN';
export const SSO_WITH_LIST_MEMBERSHIP = 'SSO_WITH_LIST_MEMBERSHIP';
export const PUBLIC = 'PUBLIC';
export const ACCESS_GROUP_MEMBERSHIP = 'ACCESS_GROUP_MEMBERSHIP';
// Note: Explicit PASSWORD type only implemented on FE, not BE yet
// BE only cares about password string and publicAccessRulesEnabled boolean right now
export const PASSWORD = 'PASSWORD';

/**
 * @deprecated import from ui-memberships-settings-lib instead
 * https://git.hubteam.com/HubSpot/memberships-settings-ui/tree/master/ui-memberships-settings-lib#constants
 */
export const PublicAccessRuleTypes = {
  LIST_MEMBERSHIP,
  SSO_LOGIN,
  MEMBERSHIP_LOGIN,
  SSO_WITH_LIST_MEMBERSHIP,
  PUBLIC,
  ACCESS_GROUP_MEMBERSHIP,
  PASSWORD
};