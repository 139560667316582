'use es6';

import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
const gatedFeaturesCache = {};
const getKeyOrThrow = (item, key) => {
  if (item.hasOwnProperty(key)) {
    if (typeof item[key] !== 'boolean') {
      console.warn(`Expected type of boolean for TinyMCE config key ${key}, instead received an item with type of ${typeof item[key]}. This may lead to some unintended side effects`);
    }
    return item[key];
  }
  throw new Error(`Computed TinyMCE config is missing a value for key ${key}. Make sure this key is ultimately set with a boolean value`);
};
export const makeGetMapStateToTinyMCEConfig = () => {
  let computedConfig = null;
  return () => {
    if (computedConfig !== null) {
      return computedConfig;
    }
    if (!EditorConfigSingleton.getIsOnScalableEditor()) {
      computedConfig = {};
      return computedConfig;
    }
    const mapStateToTinyMCEConfig = EditorConfigSingleton.get('mapStateToTinyMCEConfig');
    const state = window.hubspot.ContentEditorUI.store.getState();
    if (!mapStateToTinyMCEConfig) {
      throw new Error('Must set a TinyMCE config on the editor config using the mapStateToTinyMCEConfig key');
    }
    let configResult = null;
    if (typeof mapStateToTinyMCEConfig === 'function') {
      configResult = mapStateToTinyMCEConfig(state);
    } else if (typeof mapStateToTinyMCEConfig === 'object') {
      configResult = mapStateToTinyMCEConfig;
    } else {
      throw new Error(`Expected mapStateToTinyMCEConfig to be of type 'function' or 'object', instead received type of '${typeof mapStateToTinyMCEConfig}'`);
    }
    computedConfig = {
      usePersonalization: getKeyOrThrow(configResult, 'usePersonalization'),
      useSourceCode: getKeyOrThrow(configResult, 'useSourceCode'),
      useEmbed: getKeyOrThrow(configResult, 'useEmbed'),
      usePersonalizationAsUpgradePoint: getKeyOrThrow(configResult, 'usePersonalizationAsUpgradePoint')
    };
    return computedConfig;
  };
};
export const getHasPermissionsToSave = state => {
  if (!EditorConfigSingleton.getIsOnScalableEditor()) {
    return false;
  }
  const {
    getHasPermissionsToSaveEditor
  } = EditorConfigSingleton.get('features').collaborationSidebar;
  return getHasPermissionsToSaveEditor && getHasPermissionsToSaveEditor(state);
};
export const getIsUngatedForFeature = (featureFlag, gates, singleOrMultipleGates) => {
  const gatesToSearchFor = Array.isArray(singleOrMultipleGates) ? singleOrMultipleGates : [singleOrMultipleGates];
  const isInCache = gatedFeaturesCache.hasOwnProperty(featureFlag);
  const isUngatedForFeature = isInCache ? gatedFeaturesCache[featureFlag] : gatesToSearchFor.every(gateToSearchFor => gates.includes(gateToSearchFor));
  if (!isInCache) {
    gatedFeaturesCache[featureFlag] = isUngatedForFeature;
  }
  return isUngatedForFeature;
};