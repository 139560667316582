import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import produce from 'immer';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import withBasePublish, { withBasePublishAsJSObject } from 'ContentEditorUI/redux/reducers/contentModel/publish/withBasePublish';
const initialState = {
  publishImmediately: null
};
export const pagePublishReducerAsJSObject = withBasePublishAsJSObject((state = initialState, {
  type,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        let {
          publishImmediately
        } = content;
        if (!publishImmediately && content.publishDate && content.publishDate < I18n.moment.portalTz()) {
          publishImmediately = true;
        }
        draft.publishImmediately = publishImmediately;
        return draft;
      }
    default:
      {
        return draft;
      }
  }
}));
const pagePublishReducer = (state = ImmutableMap(initialState), {
  type,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        let {
          publishImmediately
        } = content;
        if (!publishImmediately && content.publishDate && content.publishDate < I18n.moment.portalTz()) {
          publishImmediately = true;
        }
        return state.set('publishImmediately', publishImmediately);
      }
    default:
      {
        return state;
      }
  }
};
export default withBasePublish(pagePublishReducer);