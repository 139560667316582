import { FETCH_REVISIONS_SUCCEEDED, FETCH_REVISIONS_FAILED, FETCH_SCHEDULED_VERSION_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const revisionsReducer = (state = {
  scheduledRevision: null,
  objects: [],
  total: 0,
  offset: 0,
  limit: 0,
  error: null
}, {
  type,
  response,
  error
}) => produce(state, draft => {
  switch (type) {
    case FETCH_REVISIONS_SUCCEEDED:
      {
        if (response.offset === 0) {
          return Object.assign(draft, response);
        }
        const newObjects = draft.objects.concat(response.objects);
        draft.objects = newObjects;
        draft.offset = response.offset;
        draft.error = null;
        return draft;
      }
    case FETCH_SCHEDULED_VERSION_SUCCEEDED:
      {
        const scheduledRevision = response.objects[0];
        if (scheduledRevision) {
          draft.scheduledRevision = {
            id: scheduledRevision.bufferId,
            object: Object.assign({}, scheduledRevision.object),
            updatedAt: scheduledRevision.object.updated,
            isScheduledUpdate: true
          };
          return draft;
        }
        return draft;
      }
    case FETCH_REVISIONS_FAILED:
      draft.error = error;
      return draft;
    default:
      return draft;
  }
});
export default revisionsReducer;