import produce from 'immer';
import { GET_CONTENT_ASSISTANT_SETTINGS } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = {
  contentAssistanceEnabled: false,
  blogBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  emailBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  smsBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  pageBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  caseStudyBrandVoice: {
    isSetUp: false,
    isEnabledForApp: false
  },
  blogBrandVoiceEnabled: false
};
const getVoiceDataForChannel = (brandVoiceSettings, channelType) => {
  const {
    channelOverrides,
    voiceData
  } = brandVoiceSettings || {};
  const {
    personality
  } = voiceData || {};
  let {
    tone
  } = voiceData || {};
  const overridesForChannel = channelOverrides && channelOverrides[channelType];
  if (overridesForChannel) {
    const toneOverrides = overridesForChannel[0] && overridesForChannel[0].tone;
    tone = toneOverrides && toneOverrides.length ? toneOverrides : tone;
  }
  return {
    personality,
    tone
  };
};
const BRAND_VOICE_CHANNEL_TYPES = {
  BLOG: 'BLOG',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  PAGES: 'PAGES',
  CASE_STUDIES: 'CASE_STUDIES'
};
export const contentAssistantReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case GET_CONTENT_ASSISTANT_SETTINGS:
      {
        draft.contentAssistanceEnabled = action.payload.contentAssistanceEnabled;
        draft.blogBrandVoiceEnabled = action.payload.blogBrandVoiceEnabled;
        const {
          brandVoiceSettings
        } = action.payload;
        if (brandVoiceSettings) {
          const {
            channelSettings
          } = brandVoiceSettings || {};
          // TODO: Update state to have a single `isSetUp` property since that's not specific to apps
          draft.blogBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes(BRAND_VOICE_CHANNEL_TYPES.BLOG),
            voiceData: getVoiceDataForChannel(brandVoiceSettings, BRAND_VOICE_CHANNEL_TYPES.BLOG),
            voiceChannel: BRAND_VOICE_CHANNEL_TYPES.BLOG
          };
          draft.emailBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes(BRAND_VOICE_CHANNEL_TYPES.EMAIL),
            voiceData: getVoiceDataForChannel(brandVoiceSettings, BRAND_VOICE_CHANNEL_TYPES.EMAIL),
            voiceChannel: BRAND_VOICE_CHANNEL_TYPES.EMAIL
          };
          draft.smsBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes(BRAND_VOICE_CHANNEL_TYPES.SMS),
            voiceData: getVoiceDataForChannel(brandVoiceSettings, BRAND_VOICE_CHANNEL_TYPES.SMS),
            voiceChannel: BRAND_VOICE_CHANNEL_TYPES.SMS
          };
          draft.pageBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes(BRAND_VOICE_CHANNEL_TYPES.PAGES),
            voiceData: getVoiceDataForChannel(brandVoiceSettings, BRAND_VOICE_CHANNEL_TYPES.PAGES),
            voiceChannel: BRAND_VOICE_CHANNEL_TYPES.PAGES
          };
          draft.caseStudyBrandVoice = {
            isSetUp: true,
            isEnabledForApp: channelSettings && channelSettings.includes(BRAND_VOICE_CHANNEL_TYPES.CASE_STUDIES),
            voiceData: getVoiceDataForChannel(brandVoiceSettings, BRAND_VOICE_CHANNEL_TYPES.CASE_STUDIES),
            voiceChannel: BRAND_VOICE_CHANNEL_TYPES.CASE_STUDIES
          };
        }
        return draft;
      }
    default:
      return draft;
  }
});
export default contentAssistantReducer;