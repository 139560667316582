'use es6';

import { Map as ImmutableMap } from 'immutable';
import { UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE, UPDATE_SUPERSTORE_USER_SETTING, UPDATE_SUPERSTORE_PER_CONTENT_SETTING } from 'ContentEditorUI/redux/actions/actionTypes';
import { USER_SEEN_STATE_SETTINGS_OBJECT_KEY, HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, HAS_SEEN_ONBOARDING_MODAL_LOCAL, HAS_CLICKED_ONBOARDING_MODAL_REMIND, HAS_SEEN_ANNOUNCEMENT_BANNER_OBJECT, HAS_SEEN_PATAGONIA_FEEDBACK_COUNT, HAS_RESPONDED_PATAGONIA_FEEDBACK, createEditorSpecificCsatKeys } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { getHasClickedOnboardingModalRemind, getHasSeenAnnouncementBannerObject, getUserSeenStateSettingsObject, getHasSeenPatagoniaFeedbackCountFromSuperStore, getHasSeenEditorCsatCountFromSuperstore } from 'ContentEditorUI/redux/selectors/localCachedSettingsSelectors';
import EditorConfigSingleton from '../../EditorConfigSingleton';
export const setSuperstoreUserSetting = (key, value) => {
  return {
    type: UPDATE_SUPERSTORE_USER_SETTING,
    key,
    value
  };
};
export const setSuperstorePerContentSetting = (key, value) => {
  return {
    type: UPDATE_SUPERSTORE_PER_CONTENT_SETTING,
    key,
    value
  };
};
export const setUserAttributeAlsoCachedInSuperstore = (key, value) => {
  return {
    type: UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE,
    key,
    value
  };
};
export const setUserAttributeAlsoCachedInSuperstoreAsJsonString = (key, value) => {
  return {
    type: UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE,
    key,
    value,
    stringifiedValue: JSON.stringify(value)
  };
};

// Specific actions to set specific user attributes (and sync to superstore via
// syncAndCacheUserSettingsMiddleware in pages and blog)

const setSeenStateUserSetting = newOnboardingSettings => {
  return setUserAttributeAlsoCachedInSuperstoreAsJsonString(USER_SEEN_STATE_SETTINGS_OBJECT_KEY, newOnboardingSettings);
};
export const setSeenStateSettingToNow = key => {
  return (dispatch, getState) => {
    let seenStateSettings = getUserSeenStateSettingsObject(getState()) || new ImmutableMap();
    seenStateSettings = seenStateSettings.set(key, new Date());
    dispatch(setSeenStateUserSetting(seenStateSettings));
  };
};
export const setHasSeenOnboardingModalLocal = () => {
  try {
    localStorage.setItem('hasSeenPatagoniaOnboarding', 'true');
  } catch (e) {
    console.error(e);
  }
  return setSuperstoreUserSetting(HAS_SEEN_ONBOARDING_MODAL_LOCAL, true);
};
export const updateHasSeenPatagoniaFeedbackCount = () => {
  return (dispatch, getState) => {
    let seenPatagoniaFeedbackCount = getHasSeenPatagoniaFeedbackCountFromSuperStore(getState()) || 0;
    seenPatagoniaFeedbackCount++;
    try {
      localStorage.setItem('hasSeenPatagoniaFeedbackCount', seenPatagoniaFeedbackCount);
    } catch (e) {
      console.error(e);
    }
    dispatch(setSuperstoreUserSetting(HAS_SEEN_PATAGONIA_FEEDBACK_COUNT, seenPatagoniaFeedbackCount));
  };
};
export const setHasRespondedPatagoniaFeedback = () => {
  try {
    localStorage.setItem('hasRespondedPatagoniaFeedback', 'true');
  } catch (e) {
    console.error(e);
  }
  return setSuperstoreUserSetting(HAS_RESPONDED_PATAGONIA_FEEDBACK, 'true');
};
export const setHasSubmittedEditorCsat = () => {
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : '';
  if (!appKey) {
    console.error('App key is empty when submitting the editor csat. This is probably an error');
  }
  const {
    hasSubmittedCsatKey
  } = createEditorSpecificCsatKeys(appKey !== null && appKey !== void 0 ? appKey : '');
  return setSuperstoreUserSetting(hasSubmittedCsatKey, Date.now());
};
export const setHasDeclinedEditorCsatToNow = () => {
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : '';
  if (!appKey) {
    console.error('App key is empty when submitting the editor csat. This is probably an error');
  }
  const {
    hasDeclinedEditorCsat
  } = createEditorSpecificCsatKeys(appKey !== null && appKey !== void 0 ? appKey : '');
  return setSuperstoreUserSetting(hasDeclinedEditorCsat, Date.now());
};
export const resetHasSeenEditorCsatCount = () => dispatch => {
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : '';
  if (!appKey) {
    console.error('App key is empty when submitting the editor csat. This is probably an error');
  }
  const {
    hasSeenCsatCountKey
  } = createEditorSpecificCsatKeys(appKey !== null && appKey !== void 0 ? appKey : '');
  dispatch(setSuperstoreUserSetting(hasSeenCsatCountKey, 0));
};
export const updateHasSeenEditorCsatCount = () => {
  return (dispatch, getState) => {
    let hasSeenEditorCsatCount = getHasSeenEditorCsatCountFromSuperstore(getState()) || 0;
    hasSeenEditorCsatCount++;
    const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : '';
    if (!appKey) {
      console.error('App key is empty when submitting the editor csat. This is probably an error');
    }
    const {
      hasSeenCsatCountKey
    } = createEditorSpecificCsatKeys(appKey !== null && appKey !== void 0 ? appKey : '');
    dispatch(setSuperstoreUserSetting(hasSeenCsatCountKey, hasSeenEditorCsatCount));
  };
};
export const setHasSeenScopesHaveChangedModalToNow = () => {
  return setSuperstorePerContentSetting(HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, new Date());
};
export const setHasClickedOnboardingModalRemind = (consumerName, enable = true) => {
  return (dispatch, getState) => {
    let shouldRemindOnboardingModalTime = getHasClickedOnboardingModalRemind(getState()) || new ImmutableMap();
    shouldRemindOnboardingModalTime = shouldRemindOnboardingModalTime.set(consumerName, enable ? Date.now() : null);
    dispatch(setSuperstoreUserSetting(HAS_CLICKED_ONBOARDING_MODAL_REMIND, shouldRemindOnboardingModalTime));
  };
};
export const setHasSeenAnnouncementBanner = ({
  bannerKey,
  consumerName
}) => {
  return (dispatch, getState) => {
    let announcementBanner = getHasSeenAnnouncementBannerObject(getState()) || new ImmutableMap();
    announcementBanner = announcementBanner.mergeDeep({
      [consumerName]: {
        [bannerKey]: Date.now()
      }
    });
    dispatch(setSuperstoreUserSetting(HAS_SEEN_ANNOUNCEMENT_BANNER_OBJECT, announcementBanner));
  };
};