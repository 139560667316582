'use es6';

import http from 'hub-http/clients/apiClient';
import { fetchPage, fetchPaginated, fetchFirstPage, DEFAULT_LIMIT } from 'ContentEditorUI/api/ContentPaginator';
import { trackAppLifecycleTimestamp, CUSTOM_MODULE_SCHEMA_REQUEST_STARTED, CUSTOM_MODULE_SCHEMA_REQUEST_FINISHED, GLOBAL_MODULE_SCHEMA_REQUEST_STARTED, GLOBAL_MODULE_SCHEMA_REQUEST_FINISHED } from 'ContentEditorUI/utils/lifecycleData';
import getEarlyRequesterResultPromise from '../earlyRequester/getEarlyRequesterResultPromise';
const GLOBAL_MODULES_URL = 'content/api/v4/widgets/list-summaries';
const CUSTOM_MODULES_URL = 'designmanager/v1/modules/combined?property=!source';
const EMAIL_CUSTOM_WIDGETS_URL = 'designmanager/v1/modules/email';
const USED_MODULE_SCHEMAS_URL = 'content-editor/v1/used-module-schemas';
export function fetchGlobalModules() {
  trackAppLifecycleTimestamp(GLOBAL_MODULE_SCHEMA_REQUEST_STARTED);
  const earlyRequest = getEarlyRequesterResultPromise(`globalModules`);
  const earlyRequestWithRetry = earlyRequest ? earlyRequest.catch(() => http.get(GLOBAL_MODULES_URL)) : null;
  const requestPromise = earlyRequestWithRetry || http.get(GLOBAL_MODULES_URL);
  return requestPromise.then(result => {
    trackAppLifecycleTimestamp(GLOBAL_MODULE_SCHEMA_REQUEST_FINISHED);
    return result;
  });
}
export function fetchCustomModules(contentId) {
  trackAppLifecycleTimestamp(CUSTOM_MODULE_SCHEMA_REQUEST_STARTED);
  const contentIdQuery = contentId ? {
    contentId
  } : {};
  const earlyRequest = getEarlyRequesterResultPromise(`customModules`);
  const earlyRequestWithRetry = earlyRequest ? earlyRequest.catch(() => {
    return fetchFirstPage(CUSTOM_MODULES_URL, DEFAULT_LIMIT, contentIdQuery);
  }) : null;
  let requestPromise;
  if (earlyRequestWithRetry) {
    requestPromise = earlyRequestWithRetry.then(results => {
      return fetchPage(CUSTOM_MODULES_URL, results, contentIdQuery);
    });
  } else {
    requestPromise = fetchPaginated(CUSTOM_MODULES_URL, DEFAULT_LIMIT, contentIdQuery);
  }
  return requestPromise.then(result => {
    trackAppLifecycleTimestamp(CUSTOM_MODULE_SCHEMA_REQUEST_FINISHED);
    return result;
  });
}
export function fetchEmailCustomModules() {
  return http.get(EMAIL_CUSTOM_WIDGETS_URL);
}
export function fetchUsedModuleSchemasWithTranslations(id, language) {
  return http.get(`${USED_MODULE_SCHEMAS_URL}/${id}?renderLanguage=${language}`);
}
export function batchFetchCustomModules(ids) {
  return http.get('designmanager/v1/modules/by-module-ids', {
    query: {
      ids
    }
  });
}