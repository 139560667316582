import AudienceAccessTypes from '../constants/AudienceAccessTypes';
import { PublicAccessRuleTypes } from '../constants/PublicAccessRuleTypes';

/**
 * @deprecated import from ui-memberships-settings-lib instead
 * https://git.hubteam.com/HubSpot/memberships-settings-ui/tree/master/ui-memberships-settings-lib#constants
 */

export const LIST_MEMBERSHIP_ACCESS_TYPES = [AudienceAccessTypes.SPECIFIC_CONTACTS, AudienceAccessTypes.SSO_WITH_SPECIFIC_CONTACTS];
export const getAudienceAccessType = ({
  password,
  publicAccessRulesEnabled,
  publicAccessRules = []
}) => {
  if (publicAccessRulesEnabled) {
    if (publicAccessRules && publicAccessRules.length) {
      const accessType = publicAccessRules[0].type;
      if (accessType === PublicAccessRuleTypes.SSO_LOGIN) {
        return AudienceAccessTypes.SSO;
      }
      if (accessType === PublicAccessRuleTypes.ACCESS_GROUP_MEMBERSHIP) {
        return AudienceAccessTypes.ACCESS_GROUP_MEMBERSHIP;
      }
    }
    return AudienceAccessTypes.SPECIFIC_CONTACTS;
  } else if (password) {
    return AudienceAccessTypes.PASSWORD;
  }
  return AudienceAccessTypes.ALL;
};
export const getAudienceAccessTypeForDisplay = (content, membershipSsoEnabled = false) => {
  const accessType = getAudienceAccessType(content);
  if (accessType === AudienceAccessTypes.SPECIFIC_CONTACTS && membershipSsoEnabled) {
    return AudienceAccessTypes.SSO_WITH_SPECIFIC_CONTACTS;
  }
  if (accessType === AudienceAccessTypes.ACCESS_GROUP_MEMBERSHIP && membershipSsoEnabled) {
    return AudienceAccessTypes.SSO_WITH_ACCESS_GROUPS;
  }
  return accessType;
};

// We will eventually have to support individual contacts as a rule type
const makeParseAudienceAccessRuleIds = ruleTypes => publicAccessRules => {
  if (!publicAccessRules) {
    return [];
  }
  const result = [];
  publicAccessRules.reduce((acc, rule) => {
    const {
      ids,
      type
    } = rule;
    if (ids && ruleTypes.includes(type)) {
      ids.forEach(id => {
        if (!acc.includes(id)) {
          acc.push(id);
        }
      });
    }
    return acc;
  }, result);
  return result;
};
export const parseAudienceAccessLists = makeParseAudienceAccessRuleIds([PublicAccessRuleTypes.LIST_MEMBERSHIP, PublicAccessRuleTypes.SSO_LOGIN]);
export const getChangeAudienceAccessPayload = (accessType, password, lists) => {
  const payload = {};
  if (accessType === AudienceAccessTypes.PASSWORD) {
    payload.password = password;
    payload.publicAccessRulesEnabled = false;
    payload.publicAccessRules = [];
  } else if (LIST_MEMBERSHIP_ACCESS_TYPES.includes(accessType) && lists) {
    payload.password = null;
    payload.publicAccessRulesEnabled = true;
    payload.publicAccessRules = [{
      type: PublicAccessRuleTypes.LIST_MEMBERSHIP,
      ids: lists
    }];
  } else if (accessType === AudienceAccessTypes.SSO) {
    payload.password = null;
    payload.publicAccessRulesEnabled = true;
    payload.publicAccessRules = [{
      type: PublicAccessRuleTypes.SSO_LOGIN,
      ids: lists
    }];
  } else {
    payload.password = null;
    payload.publicAccessRulesEnabled = false;
    payload.publicAccessRules = [];
  }
  return payload;
};
export const getMembershipSsoEnabledForDomain = (domain, portalSettings) => {
  const membershipSsoEnabled = portalSettings['bodyOverrideByDomain'] && portalSettings['bodyOverrideByDomain'][domain] && portalSettings['bodyOverrideByDomain'][domain]['membershipSsoEnabled'];
  const membershipJwtEnabled = portalSettings['bodyOverrideByDomain'] && portalSettings['bodyOverrideByDomain'][domain] && portalSettings['bodyOverrideByDomain'][domain]['membershipJwtEnabled'];
  return membershipSsoEnabled || membershipJwtEnabled;
};