import { createNewModuleFromSchema } from 'ContentUtils/helpers/ModuleCreationHelpers';
const getUUID = () => {
  // Not supported by all browsers, but most do.
  if ('randomUUID' in crypto) {
    return crypto.randomUUID();
  }
  // code sourced from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  /* eslint-disable no-bitwise */
  // @ts-expect-error abuses JS dynamism
  return ([1e7] + -1e3 + -4e3 + 8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  /* eslint-enable no-bitwise */
};
const makeUniqueModuleName = () => `widget_${getUUID()}`;
const parseDoc = doc => {
  if (!doc) {
    return new Document();
  }
  if (typeof doc === 'string' || doc instanceof String) {
    const parser = new DOMParser();
    return parser.parseFromString(doc, 'text/html');
  }
  return doc;
};
export const makeModuleInstance = (parentName, schema) => {
  const name = makeUniqueModuleName();
  const moduleInstance = createNewModuleFromSchema(schema, {},
  // TODO createNewModuleFromSchema() expects module_id to be a number, we've been passing a string
  // https://git.hubteam.com/HubSpot/ContentUILib/blob/master/ContentUtils/static-1.51424/js/helpers/ModuleCreationHelpers.ts#L9
  // @ts-expect-error  TS2345: Argument of type 'string' is not assignable to parameter of type 'number'.
  name, name);
  moduleInstance.parent_name = parentName;
  moduleInstance.body = Object.assign({}, moduleInstance.body, {
    module_id: schema.moduleId,
    schema_version: schema.schemaVersion,
    tag: 'module',
    no_wrapper: false
  });
  return moduleInstance;
};
export const makeRenderedModulePlaceholder = name => {
  const placeholder = document.createElement('div');
  placeholder.id = `hs_cos_wrapper_${name}`;
  return placeholder;
};
export const makeHublModuleElement = moduleInfo => {
  const {
    name
  } = moduleInfo;
  const wrapper = document.createElement('div');
  wrapper.dataset.name = name;
  wrapper.classList.add('mceNonEditable');
  wrapper.classList.add('hs-module');
  wrapper.setAttribute('contenteditable', 'false');
  return wrapper;
};
export const getHublModuleElementInfo = domElement => {
  if (!domElement.classList.contains('hs-module')) {
    return undefined;
  }
  const name = domElement.dataset.name;
  if (!name) {
    return undefined;
  }
  return {
    name
  };
};
export const makeHublModuleElementManager = doc => {
  const dom = parseDoc(doc);
  const getDOM = () => dom;
  const get = name => {
    const hublModuleElement = dom.querySelector(`.hs-module[data-name=${name}]`);
    if (!hublModuleElement) {
      return null;
    }
    const moduleInfo = getHublModuleElementInfo(hublModuleElement);
    return {
      element: hublModuleElement,
      info: moduleInfo
    };
  };
  const update = updatedModuleInfo => {
    const module = get(updatedModuleInfo.name);
    if (!module) {
      return;
    }
    module.element.replaceWith(makeHublModuleElement(updatedModuleInfo));
  };
  const remove = name => {
    const module = get(name);
    if (!module) {
      return;
    }
    module.element.remove();
  };
  const getAll = () => {
    const hublModuleElements = [...dom.querySelectorAll('.hs-module')];
    return hublModuleElements.map(hublModuleElement => {
      const moduleInfo = getHublModuleElementInfo(hublModuleElement);
      return {
        element: hublModuleElement,
        info: moduleInfo
      };
    });
  };
  const clone = module => {
    const {
      element
    } = get(module.name) || {};
    if (!element) {
      return null;
    }
    const moduleInfo = Object.assign({}, module, {
      name: makeUniqueModuleName()
    });
    const wrapper = makeHublModuleElement(moduleInfo);
    const renderedModulePlaceholder = makeRenderedModulePlaceholder(moduleInfo.name);
    wrapper.appendChild(renderedModulePlaceholder);
    if (element.parentNode) {
      element.parentNode.insertBefore(wrapper, element.nextSibling);
    }
    return moduleInfo;
  };
  return {
    get,
    getAll,
    update,
    remove,
    getDOM,
    clone
  };
};