export const isCmv2ModuleSchema = moduleSchema => moduleSchema.schemaVersion > 1;
export const isCmv2Module = (module, moduleSchema = {}) => {
  const type = module.type;
  if (type === 'module') {
    return true;
  }
  if (type === 'custom_widget') {
    return isCmv2ModuleSchema(moduleSchema);
  }
  return false;
};
export const isV1GlobalModule = moduleSchema => moduleSchema.content_widget_type === 'global_widget' && !isCmv2ModuleSchema(moduleSchema);
export const moduleHasAnyJs = schema => {
  return !!schema.js;
};
export const moduleHasAnyJsOrCss = schema => {
  return moduleHasAnyJs(schema) || schema.css;
};
export const isJsRenderedModule = schema => !!schema.isJsModule;
const getModuleDisplayNameforLanguage = (translations, language) => {
  if (translations && translations[language] && translations[language].name) {
    return translations[language].name.message || null;
  }
  return null;
};
const getBaseLanguageCode = language => {
  if (language && language.includes('-')) {
    return language.substring(0, language.indexOf('-'));
  }
  return language;
};
export const getModuleDisplayName = (module, language, schema = {}) => {
  const defaultLanguage = schema.masterLanguage || 'en';
  const {
    messages: translations
  } = schema;
  let shouldLookAtTranslations = false;
  if (language && translations) {
    if (language === defaultLanguage) {
      // If there's an exact match between the language and default language, don't bother looking at translations
      shouldLookAtTranslations = false;
    } else if (getBaseLanguageCode(language) === getBaseLanguageCode(defaultLanguage)) {
      // If the language/default language base codes match, only look at translations if there's an exact match for the language in there
      shouldLookAtTranslations = !!translations[language];
    } else {
      shouldLookAtTranslations = true;
    }
  }
  if (shouldLookAtTranslations) {
    const defaultLanguageTranslation = getModuleDisplayNameforLanguage(translations, defaultLanguage);

    // We can only utilize the translations when the module's template-set label
    // matches the name from the default translation OR there is no template-set label
    if (!module.label || defaultLanguageTranslation && defaultLanguageTranslation === module.label) {
      let translatedDisplayName = getModuleDisplayNameforLanguage(translations, language);
      if (translatedDisplayName) {
        return translatedDisplayName;
      } else if (language.includes('-')) {
        const languageOnly = getBaseLanguageCode(language);
        translatedDisplayName = getModuleDisplayNameforLanguage(translations, languageOnly);
        if (translatedDisplayName) {
          return translatedDisplayName;
        }
      }
    }
  }
  // Otherwise, use this order of precedence:
  // 1. template-set label for module
  // 2. displayName from module schema
  // 3. name from module schema
  return module.label || schema.displayName || schema.name;
};