import { ADD_PAGES, FETCH_PAGE_SUCCESS } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
export const pagesReducer = (state = [], {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case ADD_PAGES:
      {
        const newPages = payload.filter(payloadPage => {
          return !draft.find(page => page.id === payloadPage.id);
        });
        return draft.concat(newPages);
      }
    case FETCH_PAGE_SUCCESS:
      {
        const alreadyExists = draft.find(page => page.id === payload.id);
        if (!alreadyExists) {
          draft.push(payload);
        }
        return draft;
      }
    default:
      return draft;
  }
});
export default pagesReducer;