import Raven from 'raven-js';
import I18n from 'I18n';
export const DND_AREA_ID = 'dnd_area';
export const parseFlexAreaDataForLayoutTree = (flexArea, widgetsData) => {
  const mainFlexArea = flexArea.main;
  let columns = {};
  const sections = [];
  const sectionsMetadata = [];
  if (mainFlexArea !== null && mainFlexArea !== void 0 && mainFlexArea.sections) {
    mainFlexArea.sections.forEach((section, sectionIndex) => {
      sectionsMetadata.push({
        cssClass: 'dnd-section',
        styles: section.style,
        forceFullWidthSection: false //TODO: review if required
      });
      columns = {};
      const columnsMetadata = [];
      section.columns.forEach((col, columnIndex) => {
        const moduleIdsInsideColumn = col.widgets;
        let modulesInColumn = {};
        const modulesForColumn = [];
        moduleIdsInsideColumn.forEach((module, moduleIndex) => {
          const schemaForModule = widgetsData[module];
          if (!schemaForModule) {
            Raven.captureException(new Error('Module is missing in content.widgets'), {
              extra: {
                module,
                contentSchemaWidgets: widgetsData
              }
            });
          } else {
            modulesInColumn = {
              [moduleIndex]: {
                cell: false,
                cells: [],
                cssClass: '',
                cssId: '',
                cssIdStr: '',
                cssStyle: '',
                editable: false,
                help: null,
                id: module,
                isContainer: false,
                isContentOverridden: false,
                isInContainer: false,
                label: schemaForModule.label || I18n.text('components.sidebar.moduleDefaultLabel'),
                name: module,
                order: schemaForModule.order,
                params: {
                  css_class: 'dnd-module',
                  module_id: schemaForModule.module_id,
                  schema_version: 2,
                  parent_widget_container: DND_AREA_ID,
                  sectionIndex,
                  columnIndex,
                  moduleIndex
                },
                root: false,
                row: false,
                rowMetaData: [],
                rows: [],
                sectionName: null,
                styles: null,
                type: 'custom_widget',
                //TODO: update with actual one
                w: 12,
                widgets: [],
                x: moduleIndex
              }
            };
            modulesForColumn.push(Object.assign({}, modulesInColumn));
          }
        });
        columnsMetadata.push({
          cssClass: 'dnd-row'
        });
        columns = Object.assign({}, columns, {
          [columnIndex]: {
            cell: false,
            cells: [],
            cssClass: '',
            cssId: '',
            cssIdStr: '',
            cssStyle: '',
            editable: false,
            help: null,
            id: col.id,
            isContainer: false,
            isContentOverridden: false,
            isInContainer: false,
            label: null,
            language_overrides_deprecated: {},
            name: col.id,
            order: 0,
            params: {
              css_class: 'dnd-column'
            },
            root: false,
            row: false,
            rowMetaData: columnsMetadata,
            rows: modulesForColumn,
            sectionName: null,
            styles: {
              verticalAlignment: 'MIDDLE'
            },
            type: 'cell',
            w: col.width,
            widgets: [],
            x: columnIndex
          }
        });
      });
      sections.push(Object.assign({}, columns));
    });
  }
  const baseTreeData = {
    cell: false,
    cells: [],
    cssClass: '',
    cssId: '',
    cssIdStr: '',
    cssStyle: '',
    editable: false,
    help: null,
    id: DND_AREA_ID,
    isContainer: false,
    isContentOverridden: false,
    isInContainer: false,
    label: 'Main section',
    name: DND_AREA_ID,
    order: 0,
    params: {},
    root: false,
    row: false,
    rowMetaData: sectionsMetadata,
    rows: sections,
    sectionName: null,
    styles: null,
    type: 'cell',
    w: 12,
    widgets: [],
    x: 0
  };
  return baseTreeData;
};