'use es6';

import { Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import { createContentModelSelector, getContentModelHtmlTitle as baseGetContentModelHtmlTitle } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { parseAudienceAccessLists } from 'ContentUtils/helpers/AudienceAccessHelpers';
import { getIsBlogListingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContentGroup } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { getAbMasterId, getIsABVariant } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { PublicAccessRuleTypes } from 'ui-memberships-settings-lib/constants/PublicAccessRuleTypes';
export const getContentModelContentTypeCategoryId = createContentModelSelector('contentTypeCategoryId');
export const getContentModelDefaultCustomCssPath = createContentModelSelector('defaultCustomCssPath');
export const getContentModelEnableDomainStylesheets = createContentModelSelector('enableDomainStylesheets');
export const getContentModelEnableLayoutStylesheets = createContentModelSelector('enableLayoutStylesheets');
export const getContentModelFooterHtml = createContentModelSelector('footerHtml');
export const getContentScheduledUpdateDate = createContentModelSelector('scheduledUpdateDate');
export const getContentModelHeadHtml = createContentModelSelector('headHtml');
export const getContentModelIncludeDefaultCustomCss = createContentModelSelector('includeDefaultCustomCss');
export const getContentModelPageStylesheets = createContentModelSelector('pageStylesheets');
export const getContentModelPassword = createContentModelSelector('password');
export const getContentModelRequirePassword = createContentModelSelector('requirePassword');
export const getContentModelPublicAccessRules = createContentModelSelector('publicAccessRules');
export const getContentModelPublicAccessRulesEnabled = createContentModelSelector('publicAccessRulesEnabled');
export const getIsPublicContent = createSelector([getContentModelPassword, getContentModelPublicAccessRulesEnabled], (password, publicAccessRulesEnabled) => {
  return !password && !publicAccessRulesEnabled;
});
export const getContentModelContentIsPublic = createSelector([getContentModelRequirePassword, getContentModelPassword, getContentModelPublicAccessRules], (contentModelRequirePassword, contentModelPassword, publicAccessRules) => {
  const emptyPublicAccessRules = !publicAccessRules || publicAccessRules.length === 0;
  if (emptyPublicAccessRules) {
    return !(contentModelRequirePassword && contentModelPassword);
  }
  return publicAccessRules[0].type === PublicAccessRuleTypes.PUBLIC;
});
export const getContentModelAudienceAccessLists = createSelector([getContentModelPublicAccessRules], rules => new ImmutableSet(parseAudienceAccessLists(rules)));
export const getNumRegisteredContactsForContent = createContentModelSelector('registeredContacts');
export const getNumUnregisteredContactsForContent = createContentModelSelector('unregisteredContacts');
export const getTotalContactsWithAccess = createSelector([getNumRegisteredContactsForContent, getNumUnregisteredContactsForContent], (registeredContacts, unregisteredContacts) => registeredContacts + unregisteredContacts);
export const getNumEmailsNotSentForContent = createContentModelSelector('emailsNotSent');
export const getNumEmailsSentForContent = createContentModelSelector('emailsSent');
export const getPageSubCategory = createContentModelSelector('subcategory');
export const getDynamicPageSourceType = createContentModelSelector('dynamicPageDataSourceType');
export const getContentModelDynamicPageSourceValue = createContentModelSelector('dynamicPageDataSourceId');
export const getContentModelDynamicPageUrl = createContentModelSelector('propertyForDynamicPageCanonicalUrl');
export const getContentModelDynamicPageImage = createContentModelSelector('propertyForDynamicPageFeaturedImage');
export const getContentModelDynamicPageMeta = createContentModelSelector('propertyForDynamicPageMetaDescription');
export const getContentModelDynamicPageTitle = createContentModelSelector('propertyForDynamicPageTitle');
export const getContentModelDynamicPageSlug = createContentModelSelector('propertyForDynamicPageSlug');
const getContentModelDynamicPageProperties = createContentModelSelector('propertiesToDisplayInDynamicPage');
export const getContentModelDynamicPagePropertiesArray = createSelector([getContentModelDynamicPageProperties], properties => properties.toArray());
export const getContentModelHtmlTitle = createSelector([baseGetContentModelHtmlTitle, getIsBlogListingPage, getContentGroup], (contentModelHtmlTitle, isBlogListingPage, contentGroup) => {
  if (isBlogListingPage) {
    return contentGroup.htmlTitle;
  }
  return contentModelHtmlTitle;
});
export const getIsDynamicPage = createSelector([getDynamicPageSourceType, getContentModelDynamicPageSourceValue], (dynamicPageSourceType, dynamicPageSourceValue) => !!dynamicPageSourceType && !!dynamicPageSourceValue);
export const getIsAudienceAccessPage = createSelector([getContentModelPublicAccessRulesEnabled, getContentModelPublicAccessRules], (pagePublicAccessRulesEnabled, pagePublicAccessRules) => pagePublicAccessRulesEnabled || pagePublicAccessRules && pagePublicAccessRules.length > 0);
export const getIsPrivatePage = createSelector([getContentModelPassword, getIsAudienceAccessPage], (pagePassword, isAudienceAccessPage) => pagePassword || isAudienceAccessPage);
export const getApprovalContentId = createSelector([getIsABVariant, getAbMasterId, getContentModelId], (isABVariant, abMasterId, contentModelId) => isABVariant ? abMasterId : contentModelId);