'use es6';

import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
const url = 'content/api/v4/pages';
const searchUrl = 'content/api/v4/contents';
export function fetch(id) {
  return http.get(`${url}/${id}`, {
    timeout: 15000,
    query: {
      portalId: PortalIdParser.get()
    }
  });
}
export function search(input) {
  return http.get(searchUrl, {
    query: {
      property: ['name', 'url', 'subcategory', 'currentState', 'language', 'id'],
      categoryId: 1,
      state__in: ['PUBLISHED', 'PUBLISHED_OR_SCHEDULED', 'PUBLISHED_AB'],
      abStatus__neq: ['ab_variant', 'mab_variant'],
      name__icontains: input,
      limit: 30
    }
  });
}