/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { FETCH_PAGE_LIMIT_FAILED, FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_PAGE_LIMIT_STARTED, FETCH_MLANG_PAGES_LIMIT_STARTED, FETCH_MLANG_PAGES_LIMIT_FAILED, FETCH_MLANG_PAGES_LIMIT_SUCCEEDED } from '../actions/actionTypes';
import { fetchMlangPagesLimit as fetchMlangPagesLimitApi } from '../../api/PageLimitApi';
import { getIsLandingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { fetchSharedPageLimit } from '../../api/PageLimitApi';
import { getIsUngatedForIndividualPageLimits } from '../selectors/authSelectors';

// REQUEST FAILURES ---------------------->

const fetchPageLimitFailed = error => {
  return {
    type: FETCH_PAGE_LIMIT_FAILED,
    payload: error
  };
};
const fetchMlangPagesLimitFailed = error => {
  return {
    type: FETCH_MLANG_PAGES_LIMIT_FAILED,
    payload: error
  };
};

// REQUEST SUCCESSES --------------------->

const fetchPageLimitSucceeded = limit => {
  return {
    type: FETCH_PAGE_LIMIT_SUCCEEDED,
    payload: limit
  };
};
const fetchMlangPagesLimitSucceeded = limit => {
  return {
    type: FETCH_MLANG_PAGES_LIMIT_SUCCEEDED,
    payload: limit
  };
};

// REQUESTS ------------------------------>

export const fetchPageLimit = () => (dispatch, getState) => {
  const isLandingPage = getIsLandingPage(getState());
  const isUngatedForIndividualPageLimits = getIsUngatedForIndividualPageLimits(getState());
  const contentType = isLandingPage ? 'landing-pages' : 'site-pages';
  dispatch({
    type: FETCH_PAGE_LIMIT_STARTED
  });
  const success = resp => {
    if (resp) {
      const limitName = isLandingPage ? 'cms-landing-pages' : 'cms-site-pages';
      const sharedPageLimitData = resp.find(limitsData => limitsData.limitName === 'cms-pages');
      const isApplyingSharedPageLimit = !isUngatedForIndividualPageLimits && !!sharedPageLimitData;
      const limitDataForContentType = isApplyingSharedPageLimit ? sharedPageLimitData : resp.find(limitsData => limitsData.limitName === limitName);
      dispatch(fetchPageLimitSucceeded({
        pageLimit: limitDataForContentType ? limitDataForContentType.limit : undefined,
        quantityUsed: limitDataForContentType.quantityUsed,
        isApplyingSharedPageLimit
      }));
      dispatch(fetchPageLimitSucceeded(resp));
    } else {
      dispatch(fetchPageLimitFailed(resp));
    }
  };
  const error = resp => dispatch(fetchPageLimitFailed(resp));
  fetchSharedPageLimit(contentType).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const fetchMlangPagesLimit = (contentId, isSitePage, isUngatedForIndividualPageLimits) => dispatch => {
  dispatch({
    type: FETCH_MLANG_PAGES_LIMIT_STARTED
  });
  const success = resp => {
    if (resp) {
      dispatch(fetchMlangPagesLimitSucceeded(resp));
    } else {
      dispatch(fetchMlangPagesLimitFailed(resp));
    }
  };
  const error = resp => dispatch(fetchMlangPagesLimitFailed(resp));
  fetchMlangPagesLimitApi(contentId, isSitePage, isUngatedForIndividualPageLimits).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};