export const ContentObjectTypes = {
  LANDING_PAGE: 'LANDING_PAGE',
  SITE_PAGE: 'SITE_PAGE',
  BLOG_POST: 'BLOG_POST',
  KNOWLEDGE_ARTICLE: 'KNOWLEDGE_ARTICLE',
  PODCAST_EPISODE: 'PODCAST_EPISODE',
  CASE_STUDY: 'CASE_STUDY',
  BLOG_LISTING_PAGE: 'BLOG_LISTING_PAGE'
};
export const ContentGroupObjectTypes = {
  KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
  PODCAST_SHOW: 'PODCAST_SHOW',
  CASE_STUDY_GROUP: 'CASE_STUDY_GROUP',
  BLOG: 'BLOG'
};