'use es6';

import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { selectThemeVariantName } from 'ContentEditorUI/redux/selectors/themeSelectors';
export const getStandardFetchCssRequestOptions = state => {
  const contentId = getContentModelId(state);
  const themeVariant = selectThemeVariantName(state);
  return {
    url: `cos-rendering/v1/internal/render/${contentId}/edit/css-assets`,
    query: themeVariant ? {
      themeVariant
    } : {}
  };
};