export const InstanceTypes = {
  CASE_STUDY: 'CASE_STUDY'
};
export const ColumnTypes = {
  FOREIGN_ID: 'FOREIGN_ID',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  URL: 'URL',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  RICHTEXT: 'RICHTEXT',
  VIDEO: 'VIDEO',
  DATETIME: 'DATETIME',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  MULTISELECT: 'MULTISELECT',
  SELECT: 'SELECT',
  LOCATION: 'LOCATION'
};
export const InternalFields = {
  hs_name: 'hs_name',
  hs_path: 'hs_path',
  hs_child_table_id: 'hs_child_table_id'
};
export const RequestStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
};