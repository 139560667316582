import produce from 'immer';
import { SET_SETTING_OPTIMIZATION_TYPE, SET_XRAY_PAGE_TITLE, SET_XRAY_META_DESC, LOCATION_CHANGE, SET_XRAY_INPAGE_GATHERER_ARTIFACTS } from 'ContentEditorUI/redux/actions/actionTypes';

// @ts-expect-error No types yet
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
const initialStateAsJSObject = {
  settingOptimizationType: '',
  pageTitle: '',
  metaDesc: '',
  inpageGathererArtifacts: {}
};
export const optimizationsReducer = (state = initialStateAsJSObject, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case SET_SETTING_OPTIMIZATION_TYPE:
      draft.settingOptimizationType = payload.optimizationType;
      return draft;
    case LOCATION_CHANGE:
      {
        const {
          pathname
        } = payload;
        const paneName = getPaneNameFromPath(pathname);
        if (paneName !== 'settings') {
          draft.settingOptimizationType = '';
          return draft;
        }
        return draft;
      }
    case SET_XRAY_PAGE_TITLE:
      {
        draft.pageTitle = payload.pageTitle;
        return draft;
      }
    case SET_XRAY_META_DESC:
      {
        draft.metaDesc = payload.metaDesc;
        return draft;
      }
    case SET_XRAY_INPAGE_GATHERER_ARTIFACTS:
      {
        draft.inpageGathererArtifacts = payload.artifacts;
        return draft;
      }
    default:
      return draft;
  }
});
export default optimizationsReducer;