import { EDIT_SIDEBAR_ACTIVE_TAB_UPDATED, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_CLEARED, SELECTED_ITEM_REVERTED_TO_PREVIOUS, CLOSED_PANEL, SELECTED_FIELD_UPDATED } from 'ContentEditorUI/redux/actions/actionTypes';
import { EDIT_SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { SET_APPLY_CHANGES_BUTTON_OPEN } from '../actions/actionTypes';
import produce from 'immer';
const DEFAULT_EDIT_TAB = EDIT_SIDEBAR_PANELS.edit;
const initialState = {
  activeTab: DEFAULT_EDIT_TAB,
  previousTab: DEFAULT_EDIT_TAB,
  applyChangesButtonOpen: false,
  selectedField: null
};
const editSidebarReducer = (state = initialState, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case EDIT_SIDEBAR_ACTIVE_TAB_UPDATED:
      {
        const {
          activeTab
        } = payload;
        return Object.assign(draft, {
          activeTab,
          previousTab: draft.activeTab,
          selectedField: null
        });
      }
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          activeTab
        } = payload;
        return Object.assign(draft, {
          activeTab: activeTab || DEFAULT_EDIT_TAB,
          previousTab: draft.activeTab,
          selectedField: null
        });
      }
    case SELECTED_ITEM_CLEARED:
      return Object.assign(draft, {
        activeTab: DEFAULT_EDIT_TAB,
        previousTab: draft.activeTab,
        selectedField: null
      });
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      return Object.assign(draft, {
        activeTab: draft.previousTab,
        previousTab: DEFAULT_EDIT_TAB
      });
    case CLOSED_PANEL:
      return Object.assign(draft, {
        activeTab: '',
        previousTab: ''
      });
    case SET_APPLY_CHANGES_BUTTON_OPEN:
      draft.applyChangesButtonOpen = payload.value;
      return draft;
    case SELECTED_FIELD_UPDATED:
      draft.selectedField = payload.selectedField;
      return draft;
    default:
      return draft;
  }
});
export default editSidebarReducer;