'use es6';

import { OPENED_PANEL, CLOSED_PANEL, OPENED_PREVIOUS_PANEL, OPENED_PATAGONIA_SIDEBAR, CLOSED_PATAGONIA_SIDEBAR, PATAGONIA_SIDEBAR_ANIMATION_FINISHED, CLOSE_ADD_SECTION_PANEL, SET_PATAGONIA_ACTIVE_PANEL } from 'ContentEditorUI/redux/actions/actionTypes';
import { getIsAnythingSelected } from '../selectors/selectionSelectors';
import { SIDEBAR_PANELS } from '../../lib/sidebar/constants';
import { clearSelectedItem } from './selectionActions';
//once we have other panels we want to use we can add them here

export const closePanel = activePanel => ({
  type: CLOSED_PANEL,
  name: activePanel
});
const closeExistingPanelIfNeeded = (dispatch, getState) => {
  const currentPanel = getState().activePanel;
  if (currentPanel) {
    dispatch(closePanel(currentPanel));
  }
};
export const openPanel = (panelName, options = {
  scrollToModuleInList: null
}) => {
  return (dispatch, getState) => {
    closeExistingPanelIfNeeded(dispatch, getState);
    dispatch({
      type: OPENED_PANEL,
      name: panelName,
      payload: {
        scrollToModuleInList: options.scrollToModuleInList
      }
    });
  };
};
export const openPreviousPanel = () => {
  return (dispatch, getState) => {
    closeExistingPanelIfNeeded(dispatch, getState);
    dispatch({
      type: OPENED_PREVIOUS_PANEL
    });
  };
};
export const openPatagoniaSidebar = () => ({
  type: OPENED_PATAGONIA_SIDEBAR
});
export const closePatagoniaSidebar = () => ({
  type: CLOSED_PATAGONIA_SIDEBAR
});
export const patagoniaSidebarAnimationFinished = () => ({
  type: PATAGONIA_SIDEBAR_ANIMATION_FINISHED
});
export const closeAddSectionPanel = () => ({
  type: CLOSE_ADD_SECTION_PANEL
});
export const setActivePatagoniaPanel = panelType => (dispatch, getState) => {
  // Prevent going back to the edit panel when nothing is selected
  // This can happen when clicking on one of the sidebar panel buttons when
  // a module is focused
  if (getIsAnythingSelected(getState()) && panelType !== SIDEBAR_PANELS.edit) {
    dispatch(clearSelectedItem());
  }
  dispatch({
    type: SET_PATAGONIA_ACTIVE_PANEL,
    payload: panelType
  });
};