import { OPEN_MODAL, CLOSE_MODAL, OPEN_POPOUT_EDITOR, CLOSE_POPOUT_EDITOR, EDIT_SIDEBAR_ACTIVE_TAB_UPDATED, OPENED_PREVIOUS_PANEL, INPAGE_UI_STATE_CLEARED, SAVED_MODULE, SELECTED_ITEM_CLEARED, TOGGLE_PUBLISH_MODAL_OPEN, TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN, TOGGLE_APPLY_CHANGES_MODAL_OPEN, CONTENT_SAVE_REQUEST_FAILED, DISMISS_SCOPES_HAVE_CHANGED_MODAL, SHOW_MATCHING_APP_MODAL, HIDE_MATCHING_APP_MODAL, UPDATE_SELECTED_UPGRADABLE_MODULE_PQL, UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM, TOGGLE_RUN_TEST_MODAL, SET_TEST_TYPE, LOCATION_CHANGE, TOGGLE_POST_PUBLISH_DOMAIN_SETUP_MODAL, HIDE_COLLABORATION_SIDEBAR_COMMENT_POPOVER, SHOW_COLLABORATION_SIDEBAR_COMMENT_POPOVER } from 'ContentEditorUI/redux/actions/actionTypes';
import { CLOSE_EDIT_TEMPLATE_MODAL, OPEN_EDIT_TEMPLATE_MODAL } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
// @ts-expect-error Not typed
import { isLocked } from 'ContentEditorUI/utils/errorUtils';
import produce from 'immer';
export const INITIAL_STATE = {
  openModal: null,
  modalMetadata: null,
  publishModalOpen: false,
  publishOptionsModalOpen: false,
  applyChangesModalOpen: false,
  openPopOutEditorKey: null,
  editTemplateModalIsOpen: false,
  scopesHaveChangedModalWasDismissed: false,
  showMatchingAppModal: false,
  matchingAppOpenLocation: 'addmodulesidebar',
  selectedUpgradableModule: null,
  patagoniaSettingsModalActiveNavItem: '',
  runTestModalOpen: false,
  openTestType: '',
  postPublishDomainSetupModalOpen: false,
  collaborationSidebarCommentPopover: {
    id: '',
    isOpen: false
  }
};
export const modalReducer = (state = INITIAL_STATE, action) => produce(state, draft => {
  const type = action.type;
  switch (type) {
    case OPEN_MODAL:
      {
        draft.openModal = action.modalName;
        draft.modalMetadata = action.metadata;
        return draft;
      }
    case CLOSE_MODAL:
      {
        draft.openModal = null;
        return draft;
      }
    case OPEN_POPOUT_EDITOR:
      {
        draft.openPopOutEditorKey = action.fieldKeyArray;
        return draft;
      }
    // Make sure to clear pop out editor state when the sidebar close/back link is clicked
    case CLOSE_POPOUT_EDITOR:
    case EDIT_SIDEBAR_ACTIVE_TAB_UPDATED: // Clicked style sidebar tab
    case OPENED_PREVIOUS_PANEL: // Baymax sidebar back button
    case INPAGE_UI_STATE_CLEARED: // Non-Baymax sidebar 'X' button
    case SAVED_MODULE: // Non-Baymax "Save and go back" sidebar button
    case SELECTED_ITEM_CLEARED: // Apply button
    case LOCATION_CHANGE:
      {
        // Changing to the Baymax preview modal (and more)
        draft.openPopOutEditorKey = null;
        return draft;
      }
    case TOGGLE_PUBLISH_MODAL_OPEN:
      {
        draft.publishModalOpen = action.open;
        return draft;
      }
    case TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN:
      {
        draft.publishOptionsModalOpen = action.open;
        return draft;
      }
    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        draft.applyChangesModalOpen = action.open;
        return draft;
      }
    case OPEN_EDIT_TEMPLATE_MODAL:
      {
        draft.editTemplateModalIsOpen = true;
        return draft;
      }
    case CLOSE_EDIT_TEMPLATE_MODAL:
      {
        draft.editTemplateModalIsOpen = false;
        return draft;
      }
    case CONTENT_SAVE_REQUEST_FAILED:
      {
        const {
          error: {
            response
          }
        } = action;
        if (isLocked(response)) {
          draft.openModal = MODAL_TYPES.LOCKED_MODAL;
          return draft;
        }
        return draft;
      }
    case DISMISS_SCOPES_HAVE_CHANGED_MODAL:
      {
        draft.scopesHaveChangedModalWasDismissed = true;
        return draft;
      }
    case SHOW_MATCHING_APP_MODAL:
      {
        const {
          location
        } = action.payload;
        draft.showMatchingAppModal = true;
        draft.matchingAppOpenLocation = location;
        return draft;
      }
    case HIDE_MATCHING_APP_MODAL:
      {
        draft.showMatchingAppModal = false;
        return draft;
      }
    case UPDATE_SELECTED_UPGRADABLE_MODULE_PQL:
      {
        const {
          selectedPqlId
        } = action.payload;
        draft.selectedUpgradableModule = selectedPqlId;
        return draft;
      }
    case UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM:
      {
        draft.patagoniaSettingsModalActiveNavItem = action.payload;
        return draft;
      }
    case TOGGLE_RUN_TEST_MODAL:
      {
        draft.runTestModalOpen = action.payload;
        return draft;
      }
    case SET_TEST_TYPE:
      {
        draft.openTestType = action.payload;
        return draft;
      }
    case TOGGLE_POST_PUBLISH_DOMAIN_SETUP_MODAL:
      {
        draft.postPublishDomainSetupModalOpen = action.payload;
        return draft;
      }
    case HIDE_COLLABORATION_SIDEBAR_COMMENT_POPOVER:
      {
        draft.collaborationSidebarCommentPopover.id = '';
        draft.collaborationSidebarCommentPopover.isOpen = false;
        return draft;
      }
    case SHOW_COLLABORATION_SIDEBAR_COMMENT_POPOVER:
      {
        draft.collaborationSidebarCommentPopover.id = action.payload.id;
        draft.collaborationSidebarCommentPopover.isOpen = true;
        return draft;
      }
    default:
      return draft;
  }
});
export default modalReducer;