import { fetchContentAssistanceSettings, fetchBrandVoiceSettings } from 'ContentEditorUI/api/ContentAssistantApi';
import { GET_CONTENT_ASSISTANT_SETTINGS } from 'ContentEditorUI/redux/actions/actionTypes';
import { getHasAccessToBrandVoice } from 'ContentEditorUI/redux/selectors/contentAssistantSelectors';
import { getIsUngatedForUnifiedAISettings } from '../selectors/authSelectors';
import rpcClient from 'ContentEditorUI/api/RpcClient';
import { getAccessControlsRpc } from 'ContentEditorUI/chirp/ai/access/controls/rpc/AiAccessControlsChirpService';
export const getContentAssistantSettings = () => async (dispatch, getState) => {
  let contentAssistanceSettings;
  let brandVoiceSettings;
  let unifiedAiSettings;
  try {
    const state = getState();
    const contentAssistanceSettingsPromise = fetchContentAssistanceSettings();
    // TODO: Just use the unified AI settings call once Content Assistant Settings
    // is deprecated
    const unifiedAiSettingsPromise = getIsUngatedForUnifiedAISettings(state) ? rpcClient.call(getAccessControlsRpc, {}) : Promise.resolve(undefined);
    [contentAssistanceSettings, unifiedAiSettings] = await Promise.all([contentAssistanceSettingsPromise, unifiedAiSettingsPromise]);
    if (unifiedAiSettings) {
      contentAssistanceSettings.contentAssistanceEnabled = unifiedAiSettings.copilotEnabled;
    }
    if (getHasAccessToBrandVoice(state)) {
      brandVoiceSettings = await fetchBrandVoiceSettings();
    }
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: Object.assign({}, contentAssistanceSettings, {
        brandVoiceSettings
      })
    });
  } catch (error) {
    // Fallback to default settings when request fails
    const defaultSettings = {
      contentAssistanceEnabled: false,
      blogBrandVoiceEnabled: false
    };
    dispatch({
      type: GET_CONTENT_ASSISTANT_SETTINGS,
      payload: contentAssistanceSettings || defaultSettings
    });
  }
};