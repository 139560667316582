import { CompletionStatuses } from '../../../constants';
import { GathererKeys } from '../../../gatherers/allGatherers';
export const addFormFollowUpEmailAudit = artifacts => {
  const forms = artifacts[GathererKeys.FORMS];
  const formWithoutFollowUpEmail = forms.find(form => form.followUpEmailId === undefined);
  let formModuleId = undefined;
  let status;
  if (!forms.length) {
    status = CompletionStatuses.NOT_APPLICABLE;
  } else if (formWithoutFollowUpEmail) {
    status = CompletionStatuses.INCOMPLETE;
    formModuleId = formWithoutFollowUpEmail.formModuleId;
  } else {
    status = CompletionStatuses.COMPLETE;
  }
  return {
    status,
    formModuleId
  };
};