import { FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';

// This reducer is for the few values pulled off of the content part of the
// server response that don't fit into a specific category themselves, and
// aren't being saved so don't belong on the contentModel.
//
const initialState = {
  ab: false,
  categoryId: 0,
  crmObjectId: 0,
  mab: false,
  isInstanceLayoutPage: false,
  securityState: 'NONE',
  translatedContent: {},
  updated: 0,
  updatedById: 0
};
const contentReadOnlyDataReducer = (state = initialState, {
  type,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return Object.assign(draft, {
        // Defaults to the "unmapped" category ID if a category ID does not exist
        categoryId: response.content.categoryId || 0,
        updated: response.content.updated,
        updatedById: response.content.updatedById,
        translatedContent: response.content.translatedContent,
        absoluteUrl: response.content.absoluteUrl,
        securityState: response.content.securityState,
        contentGroupId: response.content.contentGroupId,
        ab: response.content.ab,
        mab: response.content.mab,
        crmObjectId: response.content.crmObjectId,
        isInstanceLayoutPage: response.content.isInstanceLayoutPage
      });
    default:
      return draft;
  }
});
export default contentReadOnlyDataReducer;