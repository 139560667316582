import { getUserInfo } from '../api/getUserInfo';
import { isAdmin as _testableIsAdmin } from './isAdmin';
import logError from '../reliability/logError';
export const getIsAdmin = adminType => {
  return getUserInfo().then(({
    user
  }) => _testableIsAdmin(user, adminType)).catch(err => {
    logError('getIsAdmin', err);
    return false;
  });
};

// export the function for backwards compatibility
export const isAdmin = _testableIsAdmin;