'use es6';

import _, { any } from 'underscore';
import { createSelector } from 'reselect';
import States from 'ContentUtils/constants/States';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { PublicAccessRuleTypes } from 'ContentUtils/constants/PublicAccessRuleTypes';
import { getContextActiveDomain } from 'ContentEditorUI/redux/selectors/contentSchemaSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { AiFlags } from 'ContentEditorUI/types/AiFlag';
import EditorConfigSingleton from '../../EditorConfigSingleton';
export const getContentModel = basicSelector(state => state.contentModel);
export const createContentModelSelector = field => createSelector([getContentModel], contentModel => contentModel.get(field));
export const getContentModelCampaignValue = createContentModelSelector('campaign');
export const getContentModelCampaignName = createContentModelSelector('campaignName');
export const getContentModelCampaignUtm = createContentModelSelector('campaignUtm');
export const getContentModelDomain = createContentModelSelector('domain');
export const getContentModelHtmlTitle = createContentModelSelector('htmlTitle');
export const getContentModelId = createContentModelSelector('id');
export const getContentModelIsPublished = createContentModelSelector('isPublished');
export const getContentModelMetaDescription = createContentModelSelector('metaDescription');
export const getContentModelName = createContentModelSelector('name');
export const getContentModelSlug = createContentModelSelector('slug');
export const getContentModelCanonicalUrl = createContentModelSelector('linkRelCanonicalUrl');
export const getContentModelPageExpiryDate = createContentModelSelector('pageExpiryDate');
export const getContentModelPageExpiryEnabled = createContentModelSelector('pageExpiryEnabled');
export const getContentModelPageExpiryRedirectId = createContentModelSelector('pageExpiryRedirectId');
export const getContentModelPageExpiryRedirectUrl = createContentModelSelector('pageExpiryRedirectUrl');
export const getPreviewKey = createContentModelSelector('previewKey');
export const getContentState = createContentModelSelector('state');
export const getActiveDomainName = createSelector([getContentModelDomain, getContextActiveDomain], (contentModelDomain, activeDomain) => {
  return contentModelDomain || activeDomain.domain;
});
export const getActiveApexDomainName = createSelector([getContextActiveDomain], activeDomain => activeDomain.apexDomain);
export const getContentModelCampaign = createSelector([getContentModelCampaignValue, getContentModelCampaignName], (campaign, campaignName) => {
  return {
    campaign,
    campaignName
  };
});
export const getHasContentModelCampaign = createSelector([getContentModelCampaign], contentModelCampaign => {
  return contentModelCampaign && (contentModelCampaign.campaign || contentModelCampaign.campaignUtm);
});
export const getHasContentModel = createSelector([getContentModelId], contentModelId => contentModelId != null);
export const getAiFeatures = createContentModelSelector('aiFeatures');
// Returns aiFeatures as a Partial<Record<AiFlag, 'generatedByAi' | 'generatedWithAi'>>
export const getAiFeaturesAsRecord = createSelector(getAiFeatures, aiFeatures => {
  if (!aiFeatures) return {};
  const aiFeaturesAsRecord = {};
  Object.keys(AiFlags).forEach(flag => {
    if (aiFeatures.get('generatedByAi').includes(flag)) {
      aiFeaturesAsRecord[flag] = 'generatedByAi';
    } else if (aiFeatures.get('generatedWithAi').includes(flag)) {
      aiFeaturesAsRecord[flag] = 'generatedWithAi';
    }
  });
  return aiFeaturesAsRecord;
});
export const getHasUsedSlashOrHighlightCommands = createSelector([getAiFeaturesAsRecord], aiFeatures => !!aiFeatures[AiFlags.MAIN_CONTENT]);

// This is a substring search helper for the state selector below
const matchesState = (check, currentState) => {
  const allStates = _.values(States);
  const roughMatches = _(allStates).filter(state => state.lastIndexOf(check) > -1);
  return _(roughMatches).contains(currentState);
};
export const stateSelector = check => {
  return createSelector([getContentState], state => {
    if (check instanceof Array) {
      return any(check.map(item => matchesState(item, state)));
    }
    return matchesState(check, state);
  });
};
export const getContentAbTestPercentage = createContentModelSelector('abTestPercentage');
export const getContentAbSuccessMetric = createContentModelSelector('abSuccessMetric');
export const getContentAbHoursToWait = createContentModelSelector('abHoursToWait');
export const getContentAbSampleSizeDefault = createContentModelSelector('abSampleSizeDefault');
export const getContentAbSamplingDefault = createContentModelSelector('abSamplingDefault');
export const getSubcategory = createContentModelSelector('subcategory');
export const subcategorySelector = check => {
  return createSelector([getSubcategory], subcategory => {
    if (check instanceof Array) {
      return check.indexOf(subcategory) > -1;
    }
    return subcategory === check;
  });
};
export const getTemplatePath = createContentModelSelector('templatePath');

// LANGUAGE SELECTORS
export const getLanguage = createContentModelSelector('language');

// PUBLISH SELECTORS
export const getPublishDate = createContentModelSelector('publishDate');
export const getOriginalPublishDate = createContentModelSelector('originalPublishDate');
export const getPublishImmediatelyValue = createContentModelSelector('publishImmediately');
export const getShouldPublishImmediately = createSelector([getContentState, getPublishImmediatelyValue], (contentState, publishImmediatelyValue) => {
  const isDraft = StateStatusGroups.DRAFT.includes(contentState);
  const publishImmediately = publishImmediatelyValue;
  if (typeof publishImmediately !== 'boolean' && isDraft) {
    return true;
  }
  return publishImmediately;
});

// FEATURED IMAGE SELECTORS
export const getContentModelFeaturedImage = createContentModelSelector('featuredImage');
export const getContentModelFeaturedImageAltText = createContentModelSelector('featuredImageAltText');
export const getContentModelUseFeaturedImage = createContentModelSelector('useFeaturedImage');
export const getTranslatedFromId = createContentModelSelector('translatedFromId');
export const getPostBody = createContentModelSelector('translatedFromId');

// Dynamic page selectors
export const getDynamicPageDataSourceId = createContentModelSelector('dynamicPageDataSourceId');
export const getContentScheduledUpdateDate = createContentModelSelector('scheduledUpdateDate');

// Memberships content access selectors
export const getContentModelPublicAccessRules = createContentModelSelector('publicAccessRules');
export const getContentModelPublicAccessRulesToJS = createSelector([getContentModelPublicAccessRules], immutablePublicAccessRules => immutablePublicAccessRules ? immutablePublicAccessRules.toJS() : []);
export const getIsPublic = createSelector([getContentModelPublicAccessRules], publicAccessRules => publicAccessRules.some(publicAccessRule => publicAccessRule.type === PublicAccessRuleTypes.PUBLIC));
export const getPublicAccessRulesWallPreviewEnabled = createSelector([getContentModelPublicAccessRules], publicAccessRules => !!publicAccessRules && publicAccessRules.some(publicAccessRule => publicAccessRule.wallPreviewEnabled));
export const getContentModelPublicAccessRulesEnabled = createContentModelSelector('publicAccessRulesEnabled');

// Mirrors BE BlogPostDataWallWrapperTag.showDataWall conditions
// https://git.hubteam.com/HubSpot/cos-renderer/blob/9c135a0af2c3771d63435f4d7fed4a2fb0652a57/ContentHubL/src/main/java/com/hubspot/content/hubl/tag/BlogPostDataWallWrapperTag.java#L131
export const getShowDataWall = createSelector([getContentModelPublicAccessRulesEnabled, getIsPublic, getPublicAccessRulesWallPreviewEnabled], (publicAccessRulesEnabled, isPublic, publicAccessRulesWallPreviewEnabled) => (publicAccessRulesEnabled || !isPublic) && publicAccessRulesWallPreviewEnabled);
export const getEditorConfigContentName = state => {
  if (EditorConfigSingleton.getIsOnScalableEditor() && EditorConfigSingleton.getIn(['redux', 'selectors']).getContentName) {
    return EditorConfigSingleton.getIn(['redux', 'selectors']).getContentName(state);
  }
  return null;
};
export const getContentName = createSelector([getEditorConfigContentName, getContentModelName], (contentNameFromEditorConfig, contentModelName) => {
  if (contentNameFromEditorConfig) {
    return contentNameFromEditorConfig;
  }
  return contentModelName;
});