'use es6';

import emptyFunction from 'react-utils/emptyFunction';
import { REQUEST_GLOBAL_MODULES, FETCH_GLOBAL_MODULES_SUCCESS, FETCH_GLOBAL_MODULES_FAIL, REQUEST_CUSTOM_MODULES, FETCH_CUSTOM_MODULES_SUCCESS, FETCH_CUSTOM_MODULES_FAIL, FETCH_MODULE_SCHEMAS_SUCCEEDED, FETCH_MODULE_SCHEMAS_FAILED, BATCH_FETCH_MODULE_SCHEMAS_STARTED, BATCH_FETCH_MODULE_SCHEMAS_SUCCEEDED, BATCH_FETCH_MODULE_SCHEMAS_FAILED, REHYDRATE_MODULE_SCHEMAS_SUCCEEDED, REHYDRATE_MODULE_SCHEMAS_FAILED, FETCH_MODULE_SCHEMAS_STARTED } from 'ContentEditorUI/redux/actions/actionTypes';
import * as ModuleSchemasApi from 'ContentEditorUI/api/ModuleSchemasApi';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentTypeName } from 'ContentEditorUI/redux/selectors/contentTypeSelector';
export const requestGlobalModules = () => ({
  type: REQUEST_GLOBAL_MODULES
});
export const receiveGlobalModules = response => ({
  type: FETCH_GLOBAL_MODULES_SUCCESS,
  response
});
export const receiveGlobalModulesFailure = error => ({
  type: FETCH_GLOBAL_MODULES_FAIL,
  error
});
export const requestCustomModules = () => ({
  type: REQUEST_CUSTOM_MODULES
});
export const receiveCustomModules = response => ({
  type: FETCH_CUSTOM_MODULES_SUCCESS,
  response
});
export const receiveCustomModulesFailure = error => ({
  type: FETCH_CUSTOM_MODULES_FAIL,
  error
});
export const fetchModuleSchemasSucceeded = response => ({
  type: FETCH_MODULE_SCHEMAS_SUCCEEDED,
  response
});
export const fetchModuleSchemasFailed = error => ({
  type: FETCH_MODULE_SCHEMAS_FAILED,
  error
});
export const rehydrateModuleSchemasSucceeded = response => ({
  type: REHYDRATE_MODULE_SCHEMAS_SUCCEEDED,
  response
});
export const batchFetchModuleSchemasStarted = moduleIds => ({
  type: BATCH_FETCH_MODULE_SCHEMAS_STARTED,
  moduleIds
});
export const batchFetchModuleSchemasSucceeded = response => ({
  type: BATCH_FETCH_MODULE_SCHEMAS_SUCCEEDED,
  response
});
export const batchFetchModuleSchemasFailed = () => ({
  type: BATCH_FETCH_MODULE_SCHEMAS_FAILED
});
export const rehydrateModuleSchemasFailed = error => ({
  type: REHYDRATE_MODULE_SCHEMAS_FAILED,
  error
});

// Note, this is only v1 globals (v2 globals will come in the main "combined" modules request)
export const fetchGlobalModules = () => dispatch => {
  dispatch(requestGlobalModules());
  const success = resp => dispatch(receiveGlobalModules(resp));
  const error = resp => dispatch(receiveGlobalModulesFailure(resp));
  return ModuleSchemasApi.fetchGlobalModules().then(success, error);
};
export const fetchCustomModules = ({
  contentId
}) => dispatch => {
  dispatch(requestCustomModules());
  const success = resp => dispatch(receiveCustomModules(resp));
  const error = resp => dispatch(receiveCustomModulesFailure(resp));

  // We used to cache module schemas locally here.
  // See this PR for how that worked:
  // https://git.hubteam.com/HubSpot/ContentEditorUI/pull/15555

  return ModuleSchemasApi.fetchCustomModules(contentId).then(success, error);
};
export const batchFetchCustomModuleSchemas = ({
  moduleIds,
  onSuccess = emptyFunction.thatReturnsArgument
}) => {
  return dispatch => {
    dispatch(batchFetchModuleSchemasStarted(moduleIds));
    ModuleSchemasApi.batchFetchCustomModules(moduleIds).then(onSuccess).then(customModules => {
      dispatch(batchFetchModuleSchemasSucceeded({
        customModules
      }));
    }).catch(() => {
      dispatch(batchFetchModuleSchemasFailed());
    });
  };
};
export const rehydrateModuleSchemasWithLanguage = language => (dispatch, getState) => {
  const success = resp => dispatch(rehydrateModuleSchemasSucceeded(resp));
  const error = resp => dispatch(rehydrateModuleSchemasFailed(resp));
  return ModuleSchemasApi.fetchUsedModuleSchemasWithTranslations(getContentModelId(getState()), language).then(success, error);
};
export const fetchModuleSchemas = (contentSchemaPromise, contentId) => (dispatch, getState) => {
  const state = getState();
  if (!state.appStatus.moduleSchemasFetched) {
    dispatch({
      type: FETCH_MODULE_SCHEMAS_STARTED
    });
    const error = resp => {
      return dispatch(fetchModuleSchemasFailed(resp));
    };
    const success = requests => {
      const [globalModules, customModules] = requests;
      const categoryName = getContentTypeName(getState());

      // Need to check for errors again, because we already disapatch the failure action for each
      // of the sub-requests, which turns it into a resolved promise again
      for (const request of requests) {
        if (request.error) {
          return error(request);
        }
      }
      return dispatch(fetchModuleSchemasSucceeded({
        categoryName,
        globalModules: globalModules.response,
        customModules: customModules.response
      }));
    };
    const fetchSpread = [dispatch(fetchGlobalModules()), dispatch(fetchCustomModules({
      contentId
    }))];
    if (contentSchemaPromise) fetchSpread.push(contentSchemaPromise);
    Promise.all(fetchSpread).then(success, error).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return undefined;
  }
  return null;
};

// A shim for scalable editor "editor config" requests to utilize existing complex module fetching logic
export const standardFetchCustomModules = () => dispatch => {
  // parseEditorUrl comes from earlyRequester.js, may need to rethink how that works
  const {
    contentId
  } = window.parseEditorUrl();
  return dispatch(fetchCustomModules({
    contentId
  }));
};

// A shim for sclaable editor
export const standardFetchGlobalV1Modules = () => dispatch => {
  return dispatch(fetchGlobalModules());
};