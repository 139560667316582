import { useDispatch, useSelector } from 'react-redux';
import { CONTENT_SAVE_REQUEST_FAILED } from '../../../redux/actions/actionTypes';
import { contentPublishFailed, updateOptimisticLockingForRequest, contentPublishSucceeded, requestedContentPublish
// @ts-expect-error Untyped module
} from '../../../redux/actions/appActions';
// @ts-expect-error Untyped module
import { logSentryWithRecentReduxHistory } from '../../../redux/actions/errorActions';
import { EditorMetricsTracker } from '../../../utils/metricsTracker';
import { getIsFormValid } from '../../../redux/selectors/formStatusSelectors';
import { useCallback, useMemo } from 'react';
import http from 'hub-http/clients/apiClient';
import { convertCMPObjectToInstanceObject } from '../utils/objectConversion';
const IGNORE_ERROR_CODE = [401, 403];
const CMPPublishUnpublishAPICallback = ({
  isUnpublish,
  contentObjectType,
  objectId
}) => {
  return http.postWithResponse(`content-management/publication/v1/${contentObjectType}/${objectId}/${isUnpublish ? 'unpublish' : 'publish'}`, {
    data: {}
  });
};
export const useCMPPublishActionHandlers = ({
  endTourIfAny,
  saveAction,
  onPublishSuccess,
  onPublishError,
  trackPublishEvent,
  onUnpublishSuccess,
  onUnpublishError,
  contentObjectType,
  objectId,
  shouldConvertCMPObjectToInstanceObject
}) => {
  const dispatch = useDispatch();
  const isFormValid = useSelector(getIsFormValid);
  const dispatchUpdateOptimisticLocking = useCallback((xhr, hasSaved) => {
    dispatch(updateOptimisticLockingForRequest({
      hasSaved,
      xLastModifiedTimestampHeader: xhr.getResponseHeader('x-last-modified-timestamp')
    }));
  }, [dispatch]);
  const actionHandler = useCallback(async ({
    isUnpublish = false
  } = {}) => {
    if (endTourIfAny) {
      endTourIfAny();
    }
    if (!isFormValid) {
      return;
    }
    dispatch(requestedContentPublish());
    try {
      const {
        type
      } = await dispatch(saveAction({
        isSaveBeforePublish: true
      }));
      if (type === CONTENT_SAVE_REQUEST_FAILED) {
        dispatch(contentPublishFailed());
        // return early if save failed to prevent publishing with unsaved changes
        return;
      }
      const {
        data,
        xhr
      } = await CMPPublishUnpublishAPICallback({
        isUnpublish,
        contentObjectType,
        objectId
      });
      dispatchUpdateOptimisticLocking(xhr, true);
      const successData = shouldConvertCMPObjectToInstanceObject ? convertCMPObjectToInstanceObject(data, contentObjectType) : data;
      if (isUnpublish) {
        onUnpublishSuccess(successData);
      } else {
        onPublishSuccess(successData);
      }
      trackPublishEvent(true);
      dispatch(contentPublishSucceeded());
    } catch (errorResponse) {
      if (!IGNORE_ERROR_CODE.includes(errorResponse.status)) {
        EditorMetricsTracker.counter('publish-errors').increment();
        dispatch(logSentryWithRecentReduxHistory('Publish errors', {
          errorResponse: errorResponse.responseJSON,
          errorStatus: errorResponse.status
        }, 'publishError'));
      }
      if (isUnpublish) {
        onUnpublishError(errorResponse);
      } else {
        onPublishError(errorResponse);
      }
      trackPublishEvent(false);
      dispatchUpdateOptimisticLocking(errorResponse.xhr, false);
      dispatch(contentPublishFailed());
    }
  }, [contentObjectType, dispatch, dispatchUpdateOptimisticLocking, endTourIfAny, isFormValid, objectId, onPublishError, onPublishSuccess, onUnpublishError, onUnpublishSuccess, saveAction, shouldConvertCMPObjectToInstanceObject, trackPublishEvent]);
  return useMemo(() => ({
    // TODO: add schedule action handler as it needs a separate API call
    publish: () => actionHandler(),
    unpublish: () => actionHandler({
      isUnpublish: true
    })
  }), [actionHandler]);
};
const usePublishActionHandlers = ({
  endTourIfAny,
  publishUnpublishAPICallback,
  saveAction,
  onPublishSuccess,
  onPublishError,
  trackPublishEvent,
  onUnpublishSuccess,
  onUnpublishError
}) => {
  const dispatch = useDispatch();
  const isFormValid = useSelector(getIsFormValid);
  const actionHandler = useCallback(({
    isUnpublish = false
  } = {}) => {
    if (endTourIfAny) {
      endTourIfAny();
    }
    if (!isUnpublish && !isFormValid) {
      return;
    }
    dispatch(requestedContentPublish());
    dispatch(saveAction({
      isSaveBeforePublish: true
    })).then(({
      type
    }) => {
      if (type === CONTENT_SAVE_REQUEST_FAILED) {
        dispatch(contentPublishFailed());
        return;
      }
      publishUnpublishAPICallback(isUnpublish).then(({
        data,
        xhr
      }) => {
        dispatch(updateOptimisticLockingForRequest({
          hasSaved: true,
          xLastModifiedTimestampHeader: xhr.getResponseHeader('x-last-modified-timestamp')
        }));
        if (isUnpublish) {
          onUnpublishSuccess(data);
        } else {
          onPublishSuccess(data);
        }
        trackPublishEvent(true);
        dispatch(contentPublishSucceeded());
      }).catch((errorResponse = {}) => {
        if (!IGNORE_ERROR_CODE.includes(errorResponse.status)) {
          EditorMetricsTracker.counter('publish-errors').increment();
          dispatch(logSentryWithRecentReduxHistory('Publish errors', {
            errorResponse: errorResponse.responseJSON,
            errorStatus: errorResponse.status
          }, 'publishError'));
        }
        if (isUnpublish) {
          onUnpublishError(errorResponse);
        } else {
          onPublishError(errorResponse);
        }
        trackPublishEvent(false);
        dispatch(updateOptimisticLockingForRequest({
          hasSaved: false,
          xLastModifiedTimestampHeader: errorResponse.xhr.getResponseHeader('x-last-modified-timestamp')
        }));
        dispatch(contentPublishFailed());
      });
    }).catch(() => {
      dispatch(contentPublishFailed());
    });
  }, [endTourIfAny, isFormValid, dispatch, saveAction, publishUnpublishAPICallback, onUnpublishSuccess, onPublishSuccess, trackPublishEvent, onUnpublishError, onPublishError]);
  return useMemo(() => ({
    publish: () => actionHandler(),
    unpublish: () => actionHandler({
      isUnpublish: true
    })
  }), [actionHandler]);
};
export default usePublishActionHandlers;