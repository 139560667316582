import I18n from 'I18n';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import { InternalFields } from '../constants/types';
const STRUCTURED_CONTENT_DATA_TOKEN_PREFIX = 'dynamic_page_hubdb_row';
/**
 * Maps the HubDBColumns to CmV2 fields with the proper settings on a field type basis
 * @returns ModuleFields
 */
export function hubDBFieldtoCmvField(fields, rowId, columns, moduleValues, fieldOverrides) {
  return fields.map(field => {
    const correspondingColumn = columns.find(val => val.id.toString() === field.id);
    if (field.id && !InternalFields[field.id]) {
      field = Object.assign({}, field, {
        help_text: correspondingColumn && correspondingColumn['description']
      });
    }
    if (field.type === FieldTypes.IMAGE) {
      return Object.assign({
        showAlt: false,
        responsive: false,
        forceHideResponsive: false,
        resizable: true
      }, field);
    }
    if (field.type === FieldTypes.VIDEO) {
      return Object.assign({}, field);
    }
    if (field.type === FieldTypes.HUBDB_TABLE) {
      const tableChoices = fieldOverrides && fieldOverrides.foreignTableOptions || [];
      if (!tableChoices.some(choice => choice[0] === 0)) {
        tableChoices.unshift([0, I18n.text('StructuredContentLib.instanceEditing.childTableEmpty')]);
      }
      return Object.assign({}, field, {
        choices: tableChoices
      });
    }
    if (field.type === FieldTypes.HUBDB_ROW) {
      return Object.assign({}, field, {
        rowId,
        optionType: 'foreignid',
        immutableValue: moduleValues[field.name],
        foreignIds: correspondingColumn && correspondingColumn.foreignIds || []
      });
    } else {
      return field;
    }
  });
}

// Given a row value & field type, transform it into a cmV2 value
export function getModuleValueFromInstanceFieldType(instance, field) {
  const fieldId = field.name;
  const instanceScpValue = instance.values[fieldId];
  let value;
  switch (field.type) {
    case FieldTypes.IMAGE:
      {
        value = Object.assign({}, instanceScpValue, {
          src: instanceScpValue ? instanceScpValue.url : '',
          alt: instanceScpValue ? instanceScpValue.altText : '',
          type: 'image'
        });
        break;
      }
    case FieldTypes.VIDEO:
      {
        value = {
          player_id: instanceScpValue,
          player_type: 'hsvideo2'
        };
        break;
      }
    case FieldTypes.URL:
      {
        value = {
          href: instanceScpValue
        };
        break;
      }
    case FieldTypes.LOCATION:
      {
        if (value) {
          value = instanceScpValue;
        }
        break;
      }
    case FieldTypes.HUBDB_TABLE:
      {
        value = instance.childTableId;
        break;
      }
    case FieldTypes.CHOICE:
      {
        if (Array.isArray(instanceScpValue)) {
          value = instanceScpValue.map(option_value => option_value.id.toString());
        } else {
          value = instanceScpValue === null || instanceScpValue === void 0 ? void 0 : instanceScpValue.id.toString();
        }
        break;
      }
    case FieldTypes.HUBDB_ROW:
      {
        if (instanceScpValue.length > 0) {
          value = instanceScpValue;
        }
        break;
      }
    case FieldTypes.GROUP:
      {
        value = instance.values[fieldId].map(group => {
          const convertedGroup = Object.assign({}, group);
          // For each property, convert the SCP value to a module value
          Object.keys(group).forEach(propertyName => {
            var _field$children;
            const childField = field === null || field === void 0 || (_field$children = field.children) === null || _field$children === void 0 ? void 0 : _field$children.find(fld => fld.name === propertyName);
            if (!childField) {
              throw new Error('Child field not found in group field');
            }
            convertedGroup[propertyName] = getModuleValueFromInstanceFieldType({
              values: group
            }, childField);
          });
          return convertedGroup;
        });
        break;
      }
    default:
      {
        value = instance.values[fieldId];
      }
  }
  return value;
}

/**
 * Maps hubdb value to a CmV2 value since the BE api structure is different.
 * @returns Map of columns to their corresponding Cmv2 value for a given row.
 */
export function mapInstanceValuesToModuleValues(instance, tableRowSchema) {
  const moduleValues = {};
  tableRowSchema.fields.forEach(field => {
    const fieldId = field.name;
    if (instance && Object.prototype.hasOwnProperty.call(instance.values, fieldId)) {
      const value = getModuleValueFromInstanceFieldType(instance, field);
      moduleValues[fieldId] = value;
    }
  });
  return moduleValues;
}
export function mapModuleValueToSCPValue(value, field) {
  switch (field.type) {
    case FieldTypes.URL:
      {
        return value.href;
      }
    case FieldTypes.VIDEO:
      {
        return value.player_id;
      }
    case FieldTypes.IMAGE:
      {
        if (!value.src) {
          return null;
        }
        return {
          url: value.src,
          height: value.height,
          width: value.width,
          altText: value.alt,
          type: 'image'
        };
      }
    case FieldTypes.FILE:
      {
        if (!value) {
          return null;
        }
        return {
          url: value.url,
          id: value.id,
          type: 'file'
        };
      }
    case FieldTypes.BOOLEAN:
      {
        return value ? 1 : 0;
      }
    case FieldTypes.HUBDB_ROW:
      {
        return value.toJS();
      }
    case FieldTypes.LOCATION:
      {
        return Object.assign({}, value, {
          type: FieldTypes.LOCATION
        });
      }
    case FieldTypes.CHOICE:
      {
        if (field.multiple) {
          return value.map((option_id, order) => {
            if (!field.choices) {
              return null;
            }
            const selectedChoice = field.choices.find(choice => choice[0] === option_id.toString());
            return selectedChoice ? {
              id: option_id,
              order,
              name: selectedChoice[1],
              type: 'option'
            } : null;
          });
        } else {
          if (!field.choices) {
            return null;
          }
          const selectedChoice = field.choices.find(choice => choice[0] === value.toString());
          return selectedChoice ? {
            id: value,
            name: selectedChoice[1],
            type: 'option'
          } : null;
        }
      }
    case FieldTypes.GROUP:
      {
        return value.map(group => {
          const convertedGroup = Object.assign({}, group);
          // For each property, convert the module value to an SCP value
          Object.keys(group).forEach(propertyName => {
            var _field$children2;
            const childField = field === null || field === void 0 || (_field$children2 = field.children) === null || _field$children2 === void 0 ? void 0 : _field$children2.find(fld => fld.name === propertyName);
            if (!childField) {
              throw new Error('Child field not found in group field');
            }
            convertedGroup[propertyName] = mapModuleValueToSCPValue(group[propertyName], childField);
          });
          return convertedGroup;
        });
      }
    default:
      return value;
  }
}

/**
 * Gets the data token paths that reference the content object paths.
 *
 */
export function extractScpPropertiesFromModule(module, tableSchema) {
  // @ts-expect-error not the real module attribute
  const moduleDataTokens = module.data_tokens;
  const dataFields = [];
  if (moduleDataTokens) {
    Object.values(moduleDataTokens).forEach(value => {
      const dataTokenPath = value.split('.');
      if (dataTokenPath[0] === STRUCTURED_CONTENT_DATA_TOKEN_PREFIX) {
        const propertyName = dataTokenPath[dataTokenPath.length - 1];
        const existsInTable = tableSchema.fields.some(field => field.name === propertyName);
        if (existsInTable) {
          dataFields.push(propertyName);
        }
      }
    });
  }
  return dataFields;
}

/**
 * Given a list of property names filter them from the module definition spec.
 */
export function buildScpPropertiesModuleSpec(moduleSchema, propertyNames) {
  const schema = Object.assign({}, moduleSchema);
  if (schema.fields) {
    const fields = schema.fields.filter(field => propertyNames.includes(field.name));
    schema.fields = [...fields];
  }
  return schema;
}

/**
 * Given a module and a content type schema, determines if the module is required by checking if it contains a fields with the isRequired property set to true.
 */
export function getIsModuleRequired(moduleDataTokensMap, tableSchema) {
  if (moduleDataTokensMap) {
    // @ts-expect-error ignored Iterator type
    for (const dataToken of moduleDataTokensMap.values()) {
      const dataTokenPath = dataToken.split('.');
      if (dataTokenPath[0] === STRUCTURED_CONTENT_DATA_TOKEN_PREFIX) {
        const propertyName = dataTokenPath[dataTokenPath.length - 1];
        const existsInTable = tableSchema.fields.some(field => field.name === propertyName && field.required);
        if (existsInTable) {
          return true;
        }
      }
    }
  }
  return false;
}