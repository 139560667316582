'use es6';

import * as ActionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { parse } from 'hub-http/helpers/params';
import enviro from 'enviro';
const replacePlaceholders = (state, key, content) => {
  // If we didn't have the preview key on app load (when the iframe is _not_ loaded early)
  // then make sure we insert it into the pregenerated URL when the combined-edit-data
  // endpoint is fetched
  const oldUrl = state[key];
  if (oldUrl && (oldUrl.includes('PREVIEW_DOMAIN_PLACEHOLDER') || oldUrl.includes('PREVIEW_KEY_PLACEHOLDER') || oldUrl.includes('PREVIEW_SCRIPT_PATH_PLACEHOLDER'))) {
    let newUrl = oldUrl;
    if (newUrl.includes('PREVIEW_DOMAIN_PLACEHOLDER')) {
      newUrl = newUrl.replace('PREVIEW_DOMAIN_PLACEHOLDER', state.previewDomain);
    }
    if (content && content.previewKey && newUrl.includes('PREVIEW_KEY_PLACEHOLDER')) {
      newUrl = newUrl.replace('PREVIEW_KEY_PLACEHOLDER', content.previewKey);
    }
    if (content && content.emailTemplateMode && newUrl.includes('PREVIEW_SCRIPT_PATH_PLACEHOLDER')) {
      const scriptPath = content.emailTemplateMode === 'DESIGN_MANAGER' ? 'bundles/app-custom.js' : 'bundles/app-dnd.js';
      newUrl = newUrl.replace('PREVIEW_SCRIPT_PATH_PLACEHOLDER', scriptPath);
    }
    state = Object.assign({}, state);
    state[key] = newUrl;
    return state;
  } else {
    return state;
  }
};
const iframesReducer = (state = {}, {
  type,
  response,
  payload
}) => {
  switch (type) {
    case ActionTypes.FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content,
          preview_domain: previewDomain
        } = response;
        const hublet = enviro.getHublet();
        state = Object.assign({}, state, {
          previewDomain,
          hublet
        });
        state = replacePlaceholders(state, 'previewIframeUrl', content);
        state = replacePlaceholders(state, 'sidebarIframeUrl', content);
        if (state.previewIframeUrl) {
          const queryParamsString = state.previewIframeUrl.split('?')[1];
          state.defaultPreviewQueryParams = parse(queryParamsString);
        }
        return state;
      }
    case ActionTypes.FETCH_TEMPLATE_SCHEMA_SUCCEEDED:
      {
        const {
          template_preview_data: {
            preview_key: previewKey,
            preview_domain: previewDomain
          }
        } = response;
        const contentShim = {
          emailTemplateMode: 'DRAG_AND_DROP',
          previewDomain,
          previewKey
        };
        const hublet = enviro.getHublet();
        state = Object.assign({}, state, {
          previewDomain,
          hublet
        });
        state = replacePlaceholders(state, 'previewIframeUrl', contentShim);
        state = replacePlaceholders(state, 'sidebarIframeUrl', contentShim);
        if (state.previewIframeUrl) {
          const queryParamsString = state.previewIframeUrl.split('?')[1];
          state.defaultPreviewQueryParams = parse(queryParamsString);
        }
        return state;
      }
    case ActionTypes.SWITCH_TO_SMART_CONTENT_PREVIEW:
      {
        state = Object.assign({}, state);
        state.smartRulesPreviewParams = payload.params;
        return state;
      }
    case ActionTypes.SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case ActionTypes.SELECTED_ITEM_UPDATED:
    case ActionTypes.CLEAR_SMART_VIEW:
    case ActionTypes.SELECTED_ITEM_CLEARED:
      {
        state = Object.assign({}, state);
        state.smartRulesPreviewParams = {};
        return state;
      }
    case ActionTypes.UPDATE_PREVIEW_URL:
      {
        state = Object.assign({}, state);
        state.previewIframeUrl = payload;
        const queryParamsString = state['previewIframeUrl'].split('?')[1];
        state.defaultPreviewQueryParams = parse(queryParamsString);
        return state;
      }
    default:
      return state;
  }
};
export default iframesReducer;