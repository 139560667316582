export const REFRESH_REDUCER_AFTER_RECEIVING_GATES = 'REFRESH_REDUCER_AFTER_RECEIVING_GATES';
class ReducerDifferManager {
  constructor() {
    this.cache = {};
    this.gates = [];
  }
  setGates(gates) {
    this.gates = gates;
  }
  setStore(store) {
    this.store = store;
  }
  clear() {
    this.gates = [];
    this.cache = {};
  }
  getIsUngatedFor(gate) {
    if (Object.hasOwn(this.cache, gate)) {
      return this.cache[gate];
    }
    const isUngated = this.gates.includes(gate);
    this.cache[gate] = isUngated;
    return isUngated;
  }
  refreshReducerStateAfterReceivingGates(reducerName) {
    if (!this.store) {
      console.error('Attempting to refresh the reducer state after receiving gates without first setting the store, aborting');
      return;
    }
    this.store.dispatch({
      type: REFRESH_REDUCER_AFTER_RECEIVING_GATES,
      reducerName
    });
  }
}
const ReducerDifferManagerInstance = new ReducerDifferManager();
export default ReducerDifferManagerInstance;