export const DEFAULT = 'default';
export const HELP_TEXT = 'help_text';
export const LABEL = 'label';
export const NAME = 'name';
export const CHOICES = 'choices';
export const DISPLAY = 'display';
export const VALIDATION_REGEX = 'validation_regex';
export const MIN = 'min';
export const MAX = 'max';
export const STEP = 'step';
export const REQUIRED = 'required';
export const PICKER = 'picker';
export const CHILDREN = 'children';
export const OCCURRENCE = 'occurrence';
export const VISIBILITY = 'visibility';
export const LOCKED = 'locked';
export const PLACEHOLDER = 'placeholder';
export const RESIZABLE = 'resizable';
export const TAG_VALUE = 'tag_value';
export const INLINE_HELP_TEXT = 'inline_help_text';
export const INTERNAL = 'internal';
export const USE_LARGE_LABEL = 'use_large_label';
export const LINKS = 'links';
export const ICON = 'icon';