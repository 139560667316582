var _getLocalKeyFor, _getLocalKeyFor2;
// @ts-expect-error not typed
import EditorConfigSingleton from '../EditorConfigSingleton';
// @ts-expect-error not typed
import { getUserSuperstoreInstance } from '../lib/superstore';
// @ts-expect-error not typed
import { createEditorSpecificCsatKeys } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import I18n from 'I18n';
import { once } from 'underscore';
const APP_START_TIME_STAMP = 'APP_START_TIME_STAMP';
const IS_FEEDBACK_IN_SESSION = 'IS_FEEDBACK_IN_SESSION';
const HAS_DISMISSED_FEEDBACK = 'HAS_DISMISSED_FEEDBACK';
const SHOW_TIME = 'hsCEUICsatShowTime';
const HIDE_TIME = 'hsCEUICsatHideTime';
const SAMPLING_RATE = 0.2;
export const DECLINED_THRESHOLD_IN_DAYS = 60;
export const SUBMISSION_THRESHOLD_IN_DAYS = 90;
export const ACCOUNT_AGE_THRESHOLD_IN_DAYS = 30;
function getLocalKeyFor(key) {
  try {
    const val = window.localStorage.getItem(key);
    return val ? JSON.parse(val) : null;
  } catch (e) {
    console.error(e);
  }
  return null;
}
const SHOW_BADGE_TIME = (_getLocalKeyFor = getLocalKeyFor(SHOW_TIME)) !== null && _getLocalKeyFor !== void 0 ? _getLocalKeyFor : 3 * 60 * 1000;
const HIDE_BADGE_TIME = (_getLocalKeyFor2 = getLocalKeyFor(HIDE_TIME)) !== null && _getLocalKeyFor2 !== void 0 ? _getLocalKeyFor2 : 12 * 60 * 1000;
if (!window.hubspot) {
  window.hubspot = {};
}

// @ts-expect-error ignoring this for now, this is just temporary for the PR review
window.hubspot.csatUtils = {
  setCsatShowTime: val => {
    try {
      if (val) {
        window.localStorage.setItem(SHOW_TIME, JSON.stringify(val * 1000));
      } else {
        window.localStorage.setItem(SHOW_TIME, JSON.stringify(5 * 1000));
      }
    } catch (e) {
      console.error(e);
    }
  },
  setCsatHideTime: val => {
    try {
      if (val) {
        window.localStorage.setItem(HIDE_TIME, JSON.stringify(val * 1000));
      } else {
        window.localStorage.setItem(HIDE_TIME, JSON.stringify(5 * 60 * 1000));
      }
    } catch (e) {
      console.error(e);
    }
  },
  resetCsatState: async () => {
    const superstore = await getUserSuperstoreInstance();
    const appKey = EditorConfigSingleton.get('appKey');
    const {
      hasSubmittedCsatKey,
      hasSeenCsatCountKey,
      hasDeclinedEditorCsat
    } = createEditorSpecificCsatKeys(appKey);
    await superstore.delete(hasSubmittedCsatKey);
    await superstore.delete(hasSeenCsatCountKey);
    await superstore.delete(hasDeclinedEditorCsat);
    window.sessionStorage.clear();
  },
  stopTestingCsat: () => {
    try {
      window.localStorage.removeItem(SHOW_TIME);
      window.localStorage.removeItem(HIDE_TIME);
    } catch (e) {
      console.error(e);
    }
  }
};
export const setAppStartTime = () => {
  const currentTimeStamp = Date.now();
  try {
    window.sessionStorage.setItem(APP_START_TIME_STAMP, JSON.stringify(currentTimeStamp));
  } catch (error) {
    console.log(error);
  }
  return currentTimeStamp;
};
export const getAppStartTime = () => {
  try {
    const appStartTime = window.sessionStorage.getItem(APP_START_TIME_STAMP);
    return appStartTime ? parseInt(JSON.parse(appStartTime), 10) : undefined;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
const getTimeFromAppStart = () => {
  const appStartTime = getAppStartTime();
  if (!appStartTime) {
    return 0;
  }
  const currentTime = Date.now();
  const timeDiff = currentTime - appStartTime;
  return timeDiff;
};
export const getShowFeedbackTime = () => {
  const timeDiffFromAppStart = getTimeFromAppStart();
  return SHOW_BADGE_TIME - timeDiffFromAppStart;
};
export const getHideFeedbackTime = () => {
  const timeDiffFromAppStart = getTimeFromAppStart();
  return HIDE_BADGE_TIME - timeDiffFromAppStart;
};
export const setInFeedbackSession = isInSession => {
  try {
    window.sessionStorage.setItem(IS_FEEDBACK_IN_SESSION, JSON.stringify(isInSession));
    return isInSession;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getInFeedbackSession = () => {
  try {
    const isInFeedbackSession = window.sessionStorage.getItem(IS_FEEDBACK_IN_SESSION) || '';
    return isInFeedbackSession ? Boolean(JSON.parse(isInFeedbackSession)) : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const setHasDismissedFeedback = hasDismissed => {
  try {
    window.sessionStorage.setItem(HAS_DISMISSED_FEEDBACK, JSON.stringify(hasDismissed));
    return hasDismissed;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getHasDismissedFeedback = () => {
  try {
    const hasDismissedFeedback = window.sessionStorage.getItem(HAS_DISMISSED_FEEDBACK) || '';
    return hasDismissedFeedback ? Boolean(JSON.parse(hasDismissedFeedback)) : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export function getIsUserAccountOlderThanThreshold(user) {
  const userCreationThresholdDate = I18n.moment(user.createdAt).add(ACCOUNT_AGE_THRESHOLD_IN_DAYS, 'days');
  return I18n.moment().isAfter(userCreationThresholdDate);
}
export function getIsSubmissionDateOlderThanThreshold(date) {
  const submissionThresholdDate = I18n.moment(date).add(SUBMISSION_THRESHOLD_IN_DAYS, 'days');
  return I18n.moment().isAfter(submissionThresholdDate);
}
export function getIsDeclinedDateOlderThanThreshold(date) {
  const submissionThresholdDate = I18n.moment(date).add(DECLINED_THRESHOLD_IN_DAYS, 'days');
  return I18n.moment().isAfter(submissionThresholdDate);
}
const getIsPartOfRandomSamplingThisVisit = once(() => Math.random() <= SAMPLING_RATE || getLocalKeyFor(SHOW_TIME) != null);
export function getIsUserElligibleForFeedback(user, submissionDate, declinedDate) {
  const isUserAccountOlderThanThreshold = getIsUserAccountOlderThanThreshold(user);
  const isSampledForThisVisit = getIsPartOfRandomSamplingThisVisit();
  const isSubmissionDateOlderThanThreshold = !submissionDate || getIsSubmissionDateOlderThanThreshold(submissionDate);
  const isDeclinedDateOlderThanThreshold = !declinedDate || getIsDeclinedDateOlderThanThreshold(declinedDate);
  return isUserAccountOlderThanThreshold && isSampledForThisVisit && isSubmissionDateOlderThanThreshold && isDeclinedDateOlderThanThreshold;
}