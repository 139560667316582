import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_TEMPLATE_INFO_SUCCESS, FETCH_TEMPLATE_PREVIEW_URL_SUCCESS, FETCH_ADDABLE_SECTIONS_SUCCESS, DELETE_SECTION_SUCCESS, FETCH_MARKETER_SECTIONS_SUCCESS, SAVE_SECTION_SUCCESS, CUSTOM_LAYOUT_SECTION_ADDED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS, FETCH_EMBEDS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import produce from 'immer';
import set from '../../types/set';
export const isTemplateInfoReceived = templateInfoState => {
  return templateInfoState.templateInfoReceived;
};
export const templateReducer = (state = {
  templateType: 0,
  customSections: null,
  marketerSectionsCount: -1,
  marketerSectionsLimit: -1,
  previousInsertedSectionTree: null,
  embeds: [],
  templateInfoReceived: false
}, {
  type,
  payload,
  response
}) => produce(state, draft => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        draft.templateType = response.template_type;
        return draft;
      }
    case FETCH_TEMPLATE_INFO_SUCCESS:
      return Object.assign(draft, Object.assign({}, payload, {
        templateInfoReceived: true
      }));
    case FETCH_TEMPLATE_PREVIEW_URL_SUCCESS:
      if (draft.userMeta) {
        return set(draft, ['userMeta', 'thumbnailPath'], payload.webUrl);
      }
      draft.userMeta = {
        thumbnailPath: payload.webUrl
      };
      return draft;
    case FETCH_ADDABLE_SECTIONS_SUCCESS:
      {
        draft.marketerSectionsCount = payload.marketerSectionsCount;
        draft.marketerSectionsLimit = payload.marketerSectionsLimit;
        draft.customSections = payload.allSections;
        return draft;
      }
    case DELETE_SECTION_SUCCESS:
      {
        const sectionToDelete = payload;
        const newSectionList = draft.customSections && draft.customSections.filter(section => section.id !== sectionToDelete);
        const currentMarketerSectionsCount = draft.marketerSectionsCount;
        draft.customSections = newSectionList;
        draft.marketerSectionsCount = currentMarketerSectionsCount - 1;
        return draft;
      }
    case FETCH_MARKETER_SECTIONS_SUCCESS:
      return Object.assign(draft, {
        marketerSectionsCount: payload.total,
        marketerSectionsLimit: payload.themeLimit
      });
    case SAVE_SECTION_SUCCESS:
      {
        const currentMarketerSectionsCount = draft.marketerSectionsCount;
        const marketerSectionsLimit = draft.marketerSectionsLimit;
        if (currentMarketerSectionsCount + 1 === marketerSectionsLimit) {
          UsageTracker.trackEditorInteraction({
            action: 'Sections action',
            event: 'hit-section-limit'
          });
        }
        let newSectionList = [payload];
        if (draft.customSections && draft.customSections.length) {
          newSectionList = [payload, ...draft.customSections];
        }
        draft.customSections = newSectionList;
        draft.marketerSectionsCount = currentMarketerSectionsCount + 1;
        return draft;
      }
    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        draft.previousInsertedSectionTree = null;
        return draft;
      }
    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const previousInsertedSectionTree = payload.previousTree;
        draft.previousInsertedSectionTree = previousInsertedSectionTree;
        return draft;
      }
    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        draft.previousInsertedSectionTree = null;
        return draft;
      }
    case FETCH_EMBEDS_SUCCEEDED:
      {
        draft.embeds = payload;
        return draft;
      }
    default:
      return draft;
  }
});
export default templateReducer;