import { consoleIfNotProd } from 'chatspot-core/loggers/consoleIfNotProd';
const TEXT_GENERATION = 'text_generate';
const CONTENT_REWRITE = 'content_rewrite';
const START_DELIMITER = '<<';
const END_DELIMITER = '>>';
export function getHighlightedTextContext(editorContextWithDelimiter = '', selectedText = '') {
  const hasDelimiters = editorContextWithDelimiter.includes(START_DELIMITER) && editorContextWithDelimiter.includes(END_DELIMITER);
  if (!hasDelimiters) {
    return {
      editorText: editorContextWithDelimiter,
      precedingText: undefined,
      followingText: undefined,
      selectedText
    };
  }
  const precedingText = editorContextWithDelimiter.split(START_DELIMITER)[0];
  const followingText = editorContextWithDelimiter.split(END_DELIMITER)[1];
  const selectedTextBasedOffOfDelimiters = editorContextWithDelimiter.replace(precedingText, '').replace(followingText, '').replace(START_DELIMITER, '').replace(END_DELIMITER, '');
  const editorText = editorContextWithDelimiter.replace(START_DELIMITER, '').replace(END_DELIMITER, '');
  if (selectedText && selectedTextBasedOffOfDelimiters !== selectedText) {
    consoleIfNotProd.error(`Selected text does not match the text between the delimiters.  selectedText -> '${selectedText}' Text between delimiters: selectedTextBasedOffOfDelimiters -> '${selectedTextBasedOffOfDelimiters}'. Please update the means of passing along context.`);
  }
  return {
    editorText,
    precedingText,
    followingText,
    selectedText: selectedTextBasedOffOfDelimiters
  };
}

// Current hacky solution
export const getContentAssistantContextAsStringForCopilot = rawResponse => {
  if (!rawResponse) {
    return '';
  }
  const {
    editorContext,
    selectedText
  } = rawResponse;
  const textArray = [];
  if (editorContext) {
    textArray.push(`EDITOR TEXT: ${editorContext}`);
  }
  if (selectedText) {
    textArray.push(`SELECTED TEXT: ${selectedText}`);
    // Otherwise we're dealing with a cursor position
  }
  if (editorContext && !selectedText) {
    textArray.push(`<<>> : is the cursor position of EDITOR TEXT`);
  }
  return textArray.join('\n');
};
const getInitialPromptData = ({
  intent,
  parameters,
  messageText
}) => {
  // Allows consumer to overrides
  const {
    promptPrefix = '',
    promptThread = '',
    path: customPath = []
  } = parameters || {};
  const commandName = messageText.toLocaleLowerCase();
  if (customPath.length && promptThread && promptPrefix) {
    return {
      commandName,
      path: customPath,
      promptPrefix,
      promptThread
    };
  }
  switch (intent) {
    // If apps consuming copilot collab customize which intent they use, those cases
    // need to be added here as well
    case TEXT_GENERATION:
      return {
        commandName,
        // Path to update on the action object
        path: ['data', 'prompt'],
        // The message to prompt the user what to write
        promptPrefix,
        // The message to prompt the user what to write
        promptThread
      };
    // This is the ... more options from selection command.
    case CONTENT_REWRITE:
      return {
        commandName,
        path: ['data', 'operation'],
        promptPrefix,
        promptThread
      };
    default:
      consoleIfNotProd.error(`No prompt data found for intent: ${intent}. Try adding it to the switch statement.`);
      return {};
  }
};
export const getCopilotActionFromContentAssistantData = ({
  // messageText is legacy means of passing in the promptThread
  // Moving forward, put everything on the command object!
  messageText = '',
  contentAssistantData
}) => {
  if (!contentAssistantData) {
    return null;
  }
  const {
    commandId: __commandId,
    parameters,
    editorContext,
    selectedText: deprecatedSelectedText,
    precedingText: deprecatedPrecedingText,
    followingText: deprecatedFollowingText,
    brandVoiceOptions,
    contentLanguage
  } = contentAssistantData;
  const {
    editorText,
    precedingText,
    followingText,
    selectedText
  } = getHighlightedTextContext(editorContext, deprecatedSelectedText);
  const {
    voiceChannel,
    voiceSubChannel
  } = brandVoiceOptions || {};

  // Overrides by consumer
  const {
    intent: customIntent,
    dataProps: customDataProps = {},
    promptThread,
    operation: customOperation,
    promptForMoreInfo
  } = parameters || {};
  if (customIntent && ![CONTENT_REWRITE, TEXT_GENERATION].includes(customIntent)) {
    const customData = {
      title: messageText || promptThread,
      data: Object.assign({
        intent: customIntent,
        editorText,
        text: deprecatedSelectedText || selectedText,
        precedingText: deprecatedPrecedingText || precedingText,
        followingText: deprecatedFollowingText || followingText,
        // FE uses brandVoiceOptions, BE uses top-level voiceChannel/voiceSubChannel to apply BV
        brandVoiceOptions,
        voiceChannel,
        voiceSubChannel,
        contentLanguage
      }, customDataProps)
    };
    if (promptForMoreInfo) {
      customData.promptData = getInitialPromptData({
        intent: customIntent,
        messageText,
        parameters
      });
    }
    return customData;
  }
  if (selectedText) {
    const rewriteData = {
      title: messageText || promptThread,
      data: {
        intent: customIntent !== null && customIntent !== void 0 ? customIntent : CONTENT_REWRITE,
        text: deprecatedSelectedText || selectedText,
        operation: customOperation || messageText,
        editorText,
        precedingText: deprecatedPrecedingText || precedingText,
        followingText: deprecatedFollowingText || followingText,
        brandVoiceOptions,
        voiceChannel,
        voiceSubChannel,
        contentLanguage
      }
    };
    if (promptForMoreInfo) {
      rewriteData.promptData = getInitialPromptData({
        intent: customIntent !== null && customIntent !== void 0 ? customIntent : CONTENT_REWRITE,
        messageText,
        parameters
      });
    }
    return rewriteData;
  }
  return {
    // Set by user when they respond to copilot
    title: '',
    data: {
      intent: customIntent !== null && customIntent !== void 0 ? customIntent : TEXT_GENERATION,
      // Set by user when they respond to copilot
      prompt: '',
      editorText,
      precedingText: deprecatedPrecedingText || precedingText,
      followingText: deprecatedFollowingText || followingText,
      brandVoiceOptions,
      voiceChannel,
      voiceSubChannel,
      contentLanguage
    },
    promptData: getInitialPromptData({
      intent: customIntent !== null && customIntent !== void 0 ? customIntent : TEXT_GENERATION,
      messageText,
      parameters
    })
  };
};