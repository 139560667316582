'use es6';

import { USER_SEEN_STATE_SETTINGS_OBJECT_KEY, USER_LAST_PREVIEW_DEVICE, USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED, HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, IS_DISTRACTION_FREE_OPEN, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS, HAS_SEEN_ONBOARDING_MODAL_LOCAL, HAS_CLICKED_ONBOARDING_MODAL_REMIND, HAS_SEEN_ANNOUNCEMENT_BANNER_OBJECT, HAS_SEEN_PATAGONIA_FEEDBACK_COUNT, HAS_RESPONDED_PATAGONIA_FEEDBACK } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { IS_FOCUS_MODE_ON, HAS_SEEN_BLOG_PLG_ONBOARDING_TOUR, createEditorSpecificCsatKeys } from '../../lib/userAndLocalCacheSettings/constants';
import EditorConfigSingleton from '../../EditorConfigSingleton';
const getPerUserLocalSettings = state => state.locallyCachedSettings && state.locallyCachedSettings.get('perUser');
const getPerContentLocalSettings = state => state.locallyCachedSettings && state.locallyCachedSettings.get('perContent');

// Ever needed?
// export const getHadErrorLoadingSettings = !!state.locallyCachedSettings.errorLoadingSettings;

const maybeThrowMissingKeyWarning = (key, ALLOWED_KEYS) => {
  if (!ALLOWED_KEYS.includes(key)) {
    console.warn(`${key} is not a known attribute of ${ALLOWED_KEYS}.
If you want it to persist, add to the appropriate array.
      `);
  }
};
export const makeGetUserLocalSetting = key => {
  maybeThrowMissingKeyWarning(key, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE);
  return basicSelectorWithStats(state => getPerUserLocalSettings(state) && getPerUserLocalSettings(state).get(key));
};
export const makeGetPerContentLocalSetting = key => {
  maybeThrowMissingKeyWarning(key, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS);
  return basicSelectorWithStats(state => getPerContentLocalSettings(state) && getPerContentLocalSettings(state).get(key));
};

// Selectors based on shared editor user attributes that are also locally cached in superstore
export const getUserSeenStateSettingsObject = makeGetUserLocalSetting(USER_SEEN_STATE_SETTINGS_OBJECT_KEY);
export const getUserLastPreviewDevice = makeGetUserLocalSetting(USER_LAST_PREVIEW_DEVICE);
export const getBreakpointStylingTooltipDismissed = makeGetUserLocalSetting(USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED);
export const getShouldStartFreeLPOnboardingTour = () => false;
export const getHasSeenScopesHaveChangedModal = makeGetPerContentLocalSetting(HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY);
export const getHasSeenOnboardingModalFromSuperStore = makeGetUserLocalSetting(HAS_SEEN_ONBOARDING_MODAL_LOCAL);
export const getHasSeenBlogPlgOnboardingTourFromSuperStore = makeGetUserLocalSetting(HAS_SEEN_BLOG_PLG_ONBOARDING_TOUR);
export const getHasSeenPatagoniaFeedbackCountFromSuperStore = makeGetUserLocalSetting(HAS_SEEN_PATAGONIA_FEEDBACK_COUNT);
export const getHasRespondedPatagoniaFeedbackFromSuperStore = makeGetUserLocalSetting(HAS_RESPONDED_PATAGONIA_FEEDBACK);
export const getIsDistractionFreeOpen = makeGetPerContentLocalSetting(IS_DISTRACTION_FREE_OPEN);
export const getIsFocusModeOn = makeGetUserLocalSetting(IS_FOCUS_MODE_ON);
export const getHasClickedOnboardingModalRemind = makeGetUserLocalSetting(HAS_CLICKED_ONBOARDING_MODAL_REMIND);
export const getShouldRemindOnboardingModal = (state, consumerName) => {
  const hasClickedOnboardingModalRemind = getHasClickedOnboardingModalRemind(state);
  if (!hasClickedOnboardingModalRemind || !hasClickedOnboardingModalRemind.get(consumerName)) {
    return false;
  }
  const nextWeekFromRemindClick = new Date(hasClickedOnboardingModalRemind.get(consumerName));
  nextWeekFromRemindClick.setDate(nextWeekFromRemindClick.getDate() + 7);
  return nextWeekFromRemindClick < Date.now();
};
export const getShouldShowOnboardingModal = (state, consumerName) => {
  const seleniumIsRunning = window.SELENIUM_IS_RUNNING;
  const hasSeenOnboardingModalFromSuperStore = getHasSeenOnboardingModalFromSuperStore(state);
  const shouldRemindOnboardingModal = getShouldRemindOnboardingModal(state, consumerName);
  return (
    // If selenium is not running and
    !seleniumIsRunning && (
    // user has not seen the onboarding modal from first seeing patagonia or
    !hasSeenOnboardingModalFromSuperStore ||
    // the remind duration to show the modal again has passed
    shouldRemindOnboardingModal)
  );
};
export const getHasSeenAnnouncementBannerObject = makeGetUserLocalSetting(HAS_SEEN_ANNOUNCEMENT_BANNER_OBJECT);
export const getHasSeenAnnouncementBanner = (state, {
  bannerKey,
  consumerName
}) => {
  const hasSeenAnnouncementBannerObject = getHasSeenAnnouncementBannerObject(state);
  const hasSeenAnnouncementBanner = hasSeenAnnouncementBannerObject && hasSeenAnnouncementBannerObject.getIn([consumerName, bannerKey]);

  // Checking if we're dealing with an integer since instead of a timestamp we
  // used to set true to mark a banner as having been seen. We want a check
  // like this anyway since undefined is a possible value as well for locally
  // cached setting selectors.
  return Number.isInteger(hasSeenAnnouncementBanner) ? hasSeenAnnouncementBanner : false;
};
export const getHasSeenEditorCsatCountFromSuperstore = basicSelectorWithStats(state => {
  const perUserLocalSettings = getPerUserLocalSettings(state);
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : undefined;
  if (!appKey || !perUserLocalSettings) {
    return 0;
  }
  const {
    hasSeenCsatCountKey
  } = createEditorSpecificCsatKeys(appKey);
  return perUserLocalSettings.get(hasSeenCsatCountKey);
});
export const getEditorCsatSubmissionDateFromSuperstore = basicSelectorWithStats(state => {
  const perUserLocalSettings = getPerUserLocalSettings(state);
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : undefined;
  if (!appKey || !perUserLocalSettings) {
    return undefined;
  }
  const {
    hasSubmittedCsatKey
  } = createEditorSpecificCsatKeys(appKey);
  return perUserLocalSettings.get(hasSubmittedCsatKey);
});
export const getEditorCsatDeclinedDateFromSuperstore = basicSelectorWithStats(state => {
  const perUserLocalSettings = getPerUserLocalSettings(state);
  const appKey = EditorConfigSingleton.getIsOnScalableEditor() ? EditorConfigSingleton.get('appKey') : undefined;
  if (!appKey || !perUserLocalSettings) {
    return undefined;
  }
  const {
    hasDeclinedEditorCsat
  } = createEditorSpecificCsatKeys(appKey);
  return perUserLocalSettings.get(hasDeclinedEditorCsat);
});