'use es6';

import { Map as ImmutableMap, List } from 'immutable';
import { INPAGE_UI_STATE_CLEARED, MOVED_MODULE, RESET_HIGHLIGHTED_WIDGET_ID, UPDATE_COMPUTED_STYLES_FOR_SELECTORS, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, UPDATE_HIGHLIGHTED_WIDGET_ID, UPDATE_SCROLL_TO_MODULE_ID, UPDATE_INPAGE_IS_DRAGGING_MODULE, UPDATE_INPAGE_MODE, PREVIEW_VIEWPORT_DIMENSIONS_UPDATED, PREVIEW_WRAPPER_SCROLL_UPDATED, INPAGE_PREVIEW_UNEXPECTEDLY_UNLOADED, TRACKED_FAILED_INPAGE_ASSETS, TRACKED_INPAGE_JS_LOADING_MULTIPLE_TIMES, DOM_READY_BLOCKING_RESOURCE_DETECTED, SET_IFRAME_LOADING, SET_IFRAME_NEEDS_REFRESH, PREVIEW_SCROLL_POSITION_CHANGED, CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL, CUSTOM_LAYOUT_SECTION_ADDED, CLEAR_SCROLL_TO_NODE_POSITION_INFO, UPDATE_NOPE_ZONE_SELECTOR, SCROLL_TO_LAYOUT_FRAGMENT } from 'ContentEditorUI/redux/actions/actionTypes';
import { InpageModes } from 'ContentEditorUI/lib/app/constants';
const initialState = ImmutableMap({
  highlightedWidgetId: null,
  // Set when hovering over module list items to show where each module is on the page
  highlightedWidgetContainerId: null,
  // set when hovering over module list items so we can show where each container is on the page
  computedStylesBySelector: new ImmutableMap(),
  // Any computed styles needed for layout styles (fetched from iframe)
  mouseCoordsAndInfoFromLastBlur: null,
  inpageMode: InpageModes.DEFAULT,
  isDraggingModule: false,
  scrollToModuleId: null,
  // The id of the module to scroll the editor to
  scrollToNodePositionInfo: null,
  // The id of the node position to scroll the editor to
  previewViewportDimensions: new ImmutableMap(),
  // height and width of the "viewport" element that is the preview iframe's parent
  previewWrapperScroll: new ImmutableMap(),
  // scroll left of the preview wrapper  element
  inpagePreviewUnexpectedlyUnloaded: false,
  failedInpageAssets: new List(),
  domReadyBlockingResource: null,
  iframeNeedsRefresh: false,
  inpageJsLoadedMultipleTimes: false,
  previewScrollPosition: new ImmutableMap({
    left: 0,
    top: 0
  }),
  useScrollIfModuleOutOfBoundsAtAll: false,
  nopeZoneSelector: null
});
const inpageReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case INPAGE_UI_STATE_CLEARED:
      return state.merge({
        highlightedWidgetId: payload.highlightedWidgetId,
        inpageMode: payload.inpageMode || InpageModes.DEFAULT
      });
    case MOVED_MODULE:
      return state.set('highlightedWidgetId', null);
    case RESET_HIGHLIGHTED_WIDGET_ID:
      return state.merge({
        highlightedWidgetId: null,
        scrollToModuleId: null
      });
    case UPDATE_COMPUTED_STYLES_FOR_SELECTORS:
      {
        const {
          computedStylesBySelector
        } = payload;
        return state.mergeIn(['computedStylesBySelector'], computedStylesBySelector);
      }
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          shouldScrollToModule
        } = payload;
        const newState = {
          inpageMode: InpageModes.EDIT,
          highlightedWidgetId: null
        };
        if (shouldScrollToModule) {
          newState.scrollToModuleId = id;
        }
        return state.merge(newState);
      }
    case UPDATE_HIGHLIGHTED_WIDGET_ID:
      {
        const {
          highlightedWidgetId,
          highlightedWidgetContainerId
        } = payload;
        const newState = {
          highlightedWidgetId,
          highlightedWidgetContainerId
        };
        return state.merge(newState);
      }
    case CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL:
      {
        return state.set('useScrollIfModuleOutOfBoundsAtAll', false);
      }
    case UPDATE_SCROLL_TO_MODULE_ID:
      return state.set('scrollToModuleId', payload);
    case SCROLL_TO_LAYOUT_FRAGMENT:
      {
        const {
          nodePositionId,
          layoutSectionId
        } = payload;
        return state.set('scrollToNodePositionInfo', {
          nodePositionId,
          layoutSectionId
        });
      }
    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        const {
          rowIdToMove,
          layoutSectionId
        } = payload;
        return state.set('scrollToNodePositionInfo', {
          nodePositionId: rowIdToMove,
          layoutSectionId
        });
      }
    case CLEAR_SCROLL_TO_NODE_POSITION_INFO:
      {
        return state.set('scrollToNodePositionInfo', null);
      }
    case UPDATE_INPAGE_IS_DRAGGING_MODULE:
      return state.merge({
        isDraggingModule: payload.isDraggingModule
      });
    case UPDATE_INPAGE_MODE:
      return state.set('inpageMode', payload);
    case PREVIEW_VIEWPORT_DIMENSIONS_UPDATED:
      {
        const {
          width,
          height
        } = payload;
        return state.set('previewViewportDimensions', new ImmutableMap({
          width,
          height
        }));
      }
    case PREVIEW_WRAPPER_SCROLL_UPDATED:
      {
        const {
          scrollLeft
        } = payload;
        return state.set('previewWrapperScroll', new ImmutableMap({
          scrollLeft
        }));
      }
    case INPAGE_PREVIEW_UNEXPECTEDLY_UNLOADED:
      {
        return state.set('inpagePreviewUnexpectedlyUnloaded', true);
      }
    case TRACKED_FAILED_INPAGE_ASSETS:
      {
        return state.set('failedInpageAssets', state.get('failedInpageAssets').concat(payload));
      }
    case TRACKED_INPAGE_JS_LOADING_MULTIPLE_TIMES:
      {
        return state.set('inpageJsLoadedMultipleTimes', true);
      }
    case DOM_READY_BLOCKING_RESOURCE_DETECTED:
      {
        return state.set('domReadyBlockingResource', payload);
      }
    case SET_IFRAME_LOADING:
      {
        // Reset failed inpage assets every time the iframe is reloaded
        return state.merge({
          failedInpageAssets: new List(),
          iframeNeedsRefresh: false,
          useScrollIfModuleOutOfBoundsAtAll: payload.scrollIfAnyBoundsOutOfView
        });
      }
    case SET_IFRAME_NEEDS_REFRESH:
      {
        return state.set('iframeNeedsRefresh', true);
      }
    case PREVIEW_SCROLL_POSITION_CHANGED:
      {
        const {
          left,
          top
        } = payload;
        return state.set('previewScrollPosition', new ImmutableMap({
          left,
          top
        }));
      }
    case UPDATE_NOPE_ZONE_SELECTOR:
      {
        return state.set('nopeZoneSelector', payload);
      }
    default:
      return state;
  }
};
export default inpageReducer;