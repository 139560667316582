import enviro from 'enviro';
import get from 'hs-lodash/get';
import set from 'ContentEditorUI/types/set';
export default function mergeIn(obj, keys, val) {
  const existingVal = get(obj, keys);
  if (!existingVal) {
    if (!enviro.deployed()) {
      console.warn(`Object missing in mergeIn for key path ${keys}, we will fall back to the partial object provided which may not be type safe`);
      // Commenting this out for now in preparation for https://hubspot.slack.com/archives/CS2B3MTC4/p1720633662825399
      // The moduleReducer triggers a lot of logs here. Commenting out for now gives us time to properly
      // fix the issue in the moduleReducer
      // Raven.captureMessage(
      //   'Object missing in mergeIn for given key path, falling back to the partial object provided which may not be type safe',
      //   {
      //     extra: {
      //       keyPath: keys,
      //     },
      //   }
      // );
    }
    return set(obj, keys, val);
  }
  if (typeof existingVal !== 'object') {
    throw new Error(`Cannot merge on property that is not an object at key path ${keys}`);
  }
  // We do an assign on and mutate the value at the provided key path, replicating ImmutableJS's mergeIn
  Object.assign(existingVal, val);
  return obj;
}