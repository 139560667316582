'use es6';

import { fromJS } from 'immutable';
import { getAllValuesFromSuperstoreAsMap } from 'ContentEditorUI/lib/superstore';
import { ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE } from './constants';
export const ensureEarlyRequestedAttributesMatch = attributesFromRegularSource => {
  const earlySourceString = window.hubspot.SHARED_EDITOR_USER_ATTRIBUTES_QUICK_FETCHED.keys.join(',');
  const regularSourceString = attributesFromRegularSource.join(',');
  if (earlySourceString !== regularSourceString) {
    throw new Error('SHARED_EDITOR_USER_ATTRIBUTES_QUICK_FETCHED does not match ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE');
  }
};
export const prepareIntitialSettingsStateFromSuperstores = (userSettingsSuperstorePromise, perContentSettingsSuperstorePromise, appSpecificUserAttributeKeys = []) => {
  // First resolve store promises to stores
  return Promise.all([userSettingsSuperstorePromise, perContentSettingsSuperstorePromise]).then(([userSettingsSuperstore, perContentSettingsSuperstore]) => {
    // Then get all of the key-value pairs we want from each store
    return Promise.all([getAllValuesFromSuperstoreAsMap(userSettingsSuperstore, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE.concat(appSpecificUserAttributeKeys)), getAllValuesFromSuperstoreAsMap(perContentSettingsSuperstore, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS)]).then(([mapOfUserSettings, mapOfPerContentSettings]) => {
      // And then finally convert those to maps in the shape the locallyCachedSettingsReducer expects
      return fromJS({
        perUser: mapOfUserSettings,
        perContent: mapOfPerContentSettings
      });
    }).catch(e => {
      console.error('Error in preparing initial state from superstore: ', e);
    });
  }).catch(error => {
    console.error('Error loading initial setting from superstore (but continuing to load app without them)', error);

    // Deal with errors here instead of making Application.js need to worry about this promise failing
    // and blocking the app from loading
    return fromJS({
      errorLoadingSettings: error.message,
      perUser: {},
      perContent: {}
    });
  });
};
export const parseUserAttributes = attributes => {
  const parsedAttributes = {};
  ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE.forEach(key => {
    if (attributes.hasOwnProperty(key)) {
      throw new Error(`Did not know how to parse ${key} in parseUserAttributes`);
    }
  });
  return parsedAttributes;
};