import { HS_PLUGINS } from '../constants/hsSettings';
const getDefaultErrorMessageForPickerComponent = pickerComponent => `\`${pickerComponent}\` component is required`;
export const getPickerComponent = (editor, pickerComponent, errorMessage) => {
  const errorMessageToUse = errorMessage || getDefaultErrorMessageForPickerComponent(pickerComponent);
  if (editor.settings[HS_PLUGINS] && editor.settings[HS_PLUGINS].pickerComponents) {
    const component = editor.settings[HS_PLUGINS].pickerComponents[pickerComponent];
    if (!component) {
      throw new Error(errorMessageToUse);
    }
    return component;
  }
  throw new Error(errorMessageToUse);
};
export const isUngated = (editor, gateName) => {
  const gates = editor.settings.gates;
  return Array.isArray(gates) && gates.includes(gateName);
};
export const hasScope = (scopeName, scopes) => Array.isArray(scopes) && scopes.includes(scopeName);