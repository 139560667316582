import styled, { css, keyframes } from 'styled-components';
import { GYPSUM, KOALA, OLAF, HEFFALUMP, SLINKY } from 'HubStyleTokens/colors';
import { getSize, UseOption } from './skeletonBaseUtils';
const DEFAULT_USE = UseOption.DEFAULT;

// animation constants
const DURATION = '1s';
const TIMING_FUNC = 'linear';
const DELAY = '0s';
const ITERATION = 'infinite';
const FILL_MODE = 'forwards';
const POSITION_X = 700;
const SHIMMER_ANIMATION = keyframes(["0%{background-position:-", "px 0}100%{background-position:", "px 0}"], POSITION_X, POSITION_X);
const COLOUR_MAPPINGS = {
  [UseOption.DEFAULT]: {
    primary: GYPSUM,
    secondary: KOALA
  },
  [UseOption.SHADE]: {
    primary: KOALA,
    secondary: GYPSUM
  },
  [UseOption.DARK]: {
    primary: HEFFALUMP,
    secondary: SLINKY
  }
};
const BLANK_BACKGROUND = `
  background: ${OLAF};
`;
const makeGradientBackground = ({
  primary,
  secondary
}) => `
  background: ${primary};
  background: linear-gradient(to right, ${primary}, ${secondary} 20%, ${primary} 40%);
  background-size: 1200px;
`;
const animation = css(["animation:", " ", " ", " ", " ", " ", ";"], SHIMMER_ANIMATION, DURATION, TIMING_FUNC, DELAY, ITERATION, FILL_MODE);
const getBackground = ({
  use
}) => use === UseOption.BLANK ? BLANK_BACKGROUND : makeGradientBackground(COLOUR_MAPPINGS[use]);
const SkeletonBase = styled.div.withConfig({
  displayName: "SkeletonBase",
  componentId: "sc-1eo6u2a-0"
})(["margin:", ";padding:", ";", ";", ";"], margin => getSize(margin), padding => getSize(padding), ({
  use = DEFAULT_USE
}) => getBackground({
  use
}), ({
  use = DEFAULT_USE
}) => use !== UseOption.BLANK && animation);
export default SkeletonBase;