function gql() {
  throw new Error('Unexpected `gql` call. The function must be used as a tagged template.');
}
function noop() {}

export default gql;
export {
  gql,
  noop as disableExperimentalFragmentVariables,
  noop as disableFragmentWarnings,
  noop as enableExperimentalFragmentVariables,
  noop as resetCaches,
};
