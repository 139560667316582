import { SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_REVERTED_TO_PREVIOUS, INPAGE_UI_STATE_CLEARED, SELECTED_ITEM_CLEARED, TOGGLE_APPLY_CHANGES_MODAL_OPEN } from 'ContentEditorUI/redux/actions/actionTypes';
import produce, { current } from 'immer';
const ALL_NULL_VALUES = {
  selectedId: null,
  selectedContainerId: null,
  selectionInProgress: null
};
const initialState = {
  current: ALL_NULL_VALUES,
  // Keep previous selection state for easy reverting to previous selection
  previous: ALL_NULL_VALUES
};
const setCurrentSelectionAndSavePrevious = (draft, newValues) => {
  const currentSelection = current(draft.current);
  draft.previous = currentSelection;
  Object.assign(draft.current, Object.assign({}, ALL_NULL_VALUES, newValues));
  return draft;
};
const clearSelection = draft => {
  return setCurrentSelectionAndSavePrevious(draft, Object.assign({}, ALL_NULL_VALUES));
};
const revertToPreviousSelection = draft => {
  const previousSelection = current(draft.previous);
  draft.current = previousSelection;
  Object.assign(draft.previous, Object.assign({}, ALL_NULL_VALUES));
  return draft;
};
export const selectionReducer = (state = initialState, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          containerId
        } = payload;
        return setCurrentSelectionAndSavePrevious(draft, {
          selectedId: id,
          selectedContainerId: containerId
        });
      }
    case INPAGE_UI_STATE_CLEARED:
      {
        const {
          focusedWidgetId
        } = payload;
        if (focusedWidgetId) {
          return setCurrentSelectionAndSavePrevious(draft, {
            selectedId: focusedWidgetId
          });
        } else {
          return clearSelection(draft);
        }
      }
    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        const selectionInProgresOpts = payload ? payload.selectionInProgress : null;
        draft.selectionInProgress = selectionInProgresOpts;
        return draft;
      }
    case SELECTED_ITEM_CLEARED:
      {
        return clearSelection(draft);
      }
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      {
        return revertToPreviousSelection(draft);
      }
    default:
      return draft;
  }
});
export default selectionReducer;