export const THUMBNAIL_FOLDER_NAME = 'custom-video-thumbnails';
export const SHUTTERSTOCK_FOLDER_NAME = 'Stock images';
export const CANVA_FOLDER_NAME = 'Canva images';
export const IMPORTED_IMAGE_FOLDER_NAME = 'Imported images';
export const IMPORTED_BLOG_MEDIA_FOLDER_NAME = 'Imported_Blog_Media';
export const IMPORTED_KB_FOLDER_NAME = 'Knowledge Base Import';
export const IMPORTED_SITEPAGES_FOLDER_NAME = 'Imported sitepage images';
export const CONTENT_EXPORT_FOLDER_NAME = 'content-export';
export const VIDEO_DATA_FOLDER_NAME = `hs_video_meta`;
export const AI_GENERATED_MEDIA_FOLDER_NAME = 'AI-Generated Media';
export const CANVA_FOLDER_PATH = `/${CANVA_FOLDER_NAME}`;
export const SHUTTERSTOCK_FOLDER_PATH = `/${SHUTTERSTOCK_FOLDER_NAME}`;
export const IMPORTED_IMAGE_FOLDER_PATH = `/${IMPORTED_IMAGE_FOLDER_NAME}`;
export const THUMBNAIL_FOLDER_PATH = `/${THUMBNAIL_FOLDER_NAME}`;
export const IMPORTED_BLOG_MEDIA_FOLDER_PATH = `/${IMPORTED_BLOG_MEDIA_FOLDER_NAME}`;
export const IMPORTED_KB_FOLDER_PATH = `/${IMPORTED_KB_FOLDER_NAME}`;
export const IMPORTED_SITEPAGES_FOLDER_PATH = `/${IMPORTED_SITEPAGES_FOLDER_NAME}`;
export const CONTENT_EXPORT_FOLDER_PATH = `/${CONTENT_EXPORT_FOLDER_NAME}`;
export const VIDEO_DATA_FOLDER_PATH = `/${VIDEO_DATA_FOLDER_NAME}`;
export const AI_GENERATED_MEDIA_FOLDER_PATH = `/${AI_GENERATED_MEDIA_FOLDER_NAME}`;

// Consumed by `getIsSystemFolder` to prohibit certain actions, such as partitioning,
// on specific folders used by HubSpot to store files. You only need to define
// and add the top-most system folder path.
export const SYSTEM_FOLDER_PATHS = [THUMBNAIL_FOLDER_PATH, IMPORTED_IMAGE_FOLDER_PATH, SHUTTERSTOCK_FOLDER_PATH, CANVA_FOLDER_PATH, IMPORTED_BLOG_MEDIA_FOLDER_PATH, IMPORTED_KB_FOLDER_PATH, IMPORTED_SITEPAGES_FOLDER_PATH, CONTENT_EXPORT_FOLDER_PATH, AI_GENERATED_MEDIA_FOLDER_PATH];