'use es6';

import { JS_OBJECT, PRIMITIVE_JS_VALUE, IMMUTABLE_MAP, IMMUTABLE_SET, IMMUTABLE_MAP_OF_LAYOUT_TREES, ALLOWLISTED_AUTH_OBJECT, NestedImmutableMap, NestedJsObject } from './types';

/**
 * Config describing which slices of app state to sync with the inpage app
 * and any transformations or special handling that should be applied
 */
export default {
  appStatus: JS_OBJECT,
  auth: ALLOWLISTED_AUTH_OBJECT,
  brandSettings: JS_OBJECT,
  categorizations: JS_OBJECT,
  contentAssistant: JS_OBJECT,
  contentAssets: JS_OBJECT,
  contentModel: NestedImmutableMap({
    themeSettingsValues: JS_OBJECT,
    id: PRIMITIVE_JS_VALUE,
    htmlTitle: PRIMITIVE_JS_VALUE,
    metaDescription: PRIMITIVE_JS_VALUE,
    langage: PRIMITIVE_JS_VALUE
  }),
  styleBreakpoints: NestedJsObject({
    activeBreakpointName: PRIMITIVE_JS_VALUE
  }),
  contentReadOnlyData: JS_OBJECT,
  editSidebar: JS_OBJECT,
  iframes: JS_OBJECT,
  inpageData: NestedImmutableMap({
    highlightedWidgetId: PRIMITIVE_JS_VALUE,
    highlightedWidgetContainerId: PRIMITIVE_JS_VALUE,
    iframeNeedsRefresh: PRIMITIVE_JS_VALUE,
    mouseCoordsAndInfoFromLastBlur: JS_OBJECT,
    html: PRIMITIVE_JS_VALUE,
    htmlBody: PRIMITIVE_JS_VALUE,
    inpageMode: PRIMITIVE_JS_VALUE,
    isDraggingModule: PRIMITIVE_JS_VALUE,
    scrollToModuleId: PRIMITIVE_JS_VALUE,
    scrollToNodePositionInfo: JS_OBJECT,
    computedStylesBySelector: IMMUTABLE_MAP,
    previewViewportDimensions: IMMUTABLE_MAP,
    previewWrapperScroll: IMMUTABLE_MAP,
    useScrollIfModuleOutOfBoundsAtAll: PRIMITIVE_JS_VALUE,
    nopeZoneSelector: PRIMITIVE_JS_VALUE
  }),
  inpageOptimizationState: JS_OBJECT,
  structuredContent: NestedJsObject({
    instanceEditing: NestedJsObject({
      undoCount: PRIMITIVE_JS_VALUE,
      redoCount: PRIMITIVE_JS_VALUE,
      present: NestedJsObject({
        instanceEditingModeActive: PRIMITIVE_JS_VALUE,
        dataAuthoringModeActive: PRIMITIVE_JS_VALUE,
        currentHubDbRowId: PRIMITIVE_JS_VALUE,
        currentInstance: JS_OBJECT,
        tableSchema: JS_OBJECT,
        tableRows: JS_OBJECT,
        currentRow: JS_OBJECT,
        hubDbTable: JS_OBJECT,
        inlineEditingMetaData: JS_OBJECT,
        moduleMetaData: JS_OBJECT
      })
    })
  }),
  header: NestedJsObject({
    activeItem: PRIMITIVE_JS_VALUE,
    shouldClear: PRIMITIVE_JS_VALUE
  }),
  languages: JS_OBJECT,
  locallyCachedSettings: IMMUTABLE_MAP,
  contentTypeLimit: JS_OBJECT,
  modals: JS_OBJECT,
  moduleSchemas: JS_OBJECT,
  modules: {
    immutable: true,
    allowlist: ['present', 'redoCount', 'undoCount'],
    nestedConfig: {
      redoCount: PRIMITIVE_JS_VALUE,
      undoCount: PRIMITIVE_JS_VALUE,
      present: NestedImmutableMap({
        uneditableWidgets: IMMUTABLE_MAP,
        schemaLayoutSectionTrees: IMMUTABLE_MAP_OF_LAYOUT_TREES,
        layoutSectionTrees: IMMUTABLE_MAP_OF_LAYOUT_TREES,
        layoutSectionWidgets: IMMUTABLE_MAP,
        schemaWidgetContainers: IMMUTABLE_MAP,
        widgetContainers: IMMUTABLE_MAP,
        schemaWidgets: IMMUTABLE_MAP,
        fakeModules: IMMUTABLE_MAP,
        widgets: IMMUTABLE_MAP,
        contentCss: IMMUTABLE_MAP,
        moduleMetaData: IMMUTABLE_MAP,
        clonedModulesPendingDomOperations: IMMUTABLE_SET,
        mapOfClonedToOldStaticSectionName: IMMUTABLE_MAP,
        themePageOverrides: JS_OBJECT,
        widgetsInRichText: IMMUTABLE_MAP,
        embedInfo: IMMUTABLE_MAP
      })
    }
  },
  optins: JS_OBJECT,
  onboardingTourState: JS_OBJECT,
  requestStates: JS_OBJECT,
  saveData: JS_OBJECT,
  selection: JS_OBJECT,
  sidebarState: {
    immutable: false,
    exportBeforeMessageSent: valueToExport => {
      return Object.assign({}, valueToExport, {
        // Reinitialize closedTreeNodes and dndAreasThatShowAllRows as native
        // Sets since Immer produced Set values are not serializable in Safari.
        // See https://git.hubteam.com/HubSpot/ContentEditorUI/pull/18404 for
        // more context.
        closedTreeNodes: new Set(valueToExport.closedTreeNodes),
        dndAreasThatShowAllRows: new Set(valueToExport.dndAreasThatShowAllRows)
      });
    }
  },
  smartView: JS_OBJECT,
  templateInfo: {
    immutable: false,
    allowlist: ['templateInfoReceived', 'templateType', 'customSections', 'marketerSectionsCount', 'marketerSectionsLimit', 'embeds'],
    nestedConfig: {
      customSections: PRIMITIVE_JS_VALUE,
      marketerSectionsCount: PRIMITIVE_JS_VALUE,
      marketerSectionsLimit: PRIMITIVE_JS_VALUE,
      embeds: PRIMITIVE_JS_VALUE
    }
  },
  testFlags: JS_OBJECT,
  theme: NestedJsObject({
    customFonts: PRIMITIVE_JS_VALUE,
    hasTheme: PRIMITIVE_JS_VALUE,
    hasThemeSettings: PRIMITIVE_JS_VALUE,
    theme: JS_OBJECT,
    overriddenThemeSettings: JS_OBJECT,
    themeSettings: JS_OBJECT
  }),
  collaborationSidebar: JS_OBJECT
};