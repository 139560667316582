import { Alignments } from 'rich-text-lib/constants/alignments';
export const LINE_HEIGHT_FORMAT = 'line_height';
export const BLOCK_FONT_SIZE_FORMAT = 'block_font_size';
const INHERITED_BOLD_FORMAT = 'inherited_bold';
const INHERITED_ITALIC_FORMAT = 'inherited_italic';
const INHERITED_UNDERLINE_FORMAT = 'inherited_underline';
const createOverrideFormatCommands = format => ({
  on: `${format}_enabled`,
  off: `${format}_disabled`
});
export const FORMAT_COMMAND_TO_INHERITED_COMMAND = {
  bold: createOverrideFormatCommands(INHERITED_BOLD_FORMAT),
  italic: createOverrideFormatCommands(INHERITED_ITALIC_FORMAT),
  underline: createOverrideFormatCommands(INHERITED_UNDERLINE_FORMAT)
};
export const FONT_SIZE_BLOCKS = ['p', 'blockquote', 'ul', 'ol', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'];
export const FORMATTING_SETTINGS = 'hsformatting';
export const hsformattingConfig = {
  WEBFONT_FORMATS: 'webfont_formats',
  FONT_FORMATS: 'font_formats_v2',
  FONT_SIZE_FORMATS: 'fontsize_formats_v2',
  LINE_HEIGHTS: 'line_heights',
  BLOCK_FORMATS: 'block_formats',
  APPLY_LINE_HEIGHT_PERCENTAGE: 'apply_lineheight_percentage',
  OVERRIDE_INHERITED_STYLES_CONFIG: 'override_inherited_styles_config',
  KEYBOARD_ESCAPE_FORMATS: 'keyboard_escape_formats',
  HIDE_FONT_FAMILY: 'hide_font_family',
  HIDE_FONT_SIZE: 'hide_font_size',
  USE_PARSED_FONTS: 'use_parsed_fonts',
  USE_BRANDING_FONTS: 'use_branding_fonts',
  ENABLE_PARSED_FONTS: 'enable_parsed_fonts',
  USE_FONT_SIZE_TYPEABLE: 'use_font_size_typeable'
};
export const BLOCK_FORMATS = [{
  title: 'Heading 1',
  format: 'h1'
}, {
  title: 'Heading 2',
  format: 'h2'
}, {
  title: 'Heading 3',
  format: 'h3'
}, {
  title: 'Heading 4',
  format: 'h4'
}, {
  title: 'Heading 5',
  format: 'h5'
}, {
  title: 'Heading 6',
  format: 'h6'
}, {
  title: 'Paragraph',
  format: 'p'
}, {
  title: 'Blockquote',
  format: 'blockquote'
}, {
  title: 'Div',
  format: 'div'
}, {
  title: 'Pre',
  format: 'pre'
}];
export const BLOCK_FORMAT_NODES = BLOCK_FORMATS.map(b => b.format);
export const i18nNameSpace = 'TinymcePlugins.hsformatting';
export const AlignmentCommandMap = {
  [Alignments.LEFT]: 'JustifyLeft',
  [Alignments.CENTER]: 'JustifyCenter',
  [Alignments.RIGHT]: 'JustifyRight',
  [Alignments.JUSTIFY]: 'JustifyFull',
  [Alignments.NONE]: 'JustifyNone'
};
export const UnsupportedFonts = ['terminal'];