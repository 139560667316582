export class ChirpInternalError extends Error {}
export class ChirpError extends Error {}
export function getAvroGateway(auth) {
  return `/chirp-frontend-${auth}/v1/gateway`;
}
export function parseResultUnion(rawResponse) {
  const response = rawResponse;
  if (response.type === 'data') {
    return response.data;
  } else if (response.type === 'rpcError') {
    throw new ChirpError('CHIRP RPC failed with a known error', {
      cause: response.rpcError
    });
  } else {
    throw new ChirpInternalError('CHIRP RPC failed with a CHIRP internal error', {
      cause: response.internalError
    });
  }
}