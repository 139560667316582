import { UPLOAD_FEATURED_IMAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import produce from 'immer';
const initialState = new Set([]);
export const uploadedFeaturedImagesReducer = (state = initialState, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case UPLOAD_FEATURED_IMAGE:
      return draft.add(payload);
    default:
      return state;
  }
});
export default uploadedFeaturedImagesReducer;