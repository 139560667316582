import produce from 'immer';
import { UPDATED_TEST_FLAGS } from 'ContentEditorUI/redux/actions/actionTypes';
export const testFlagsReducer = (state = {}, {
  type,
  payload
}) => produce(state, draft => {
  switch (type) {
    case UPDATED_TEST_FLAGS:
      return payload;
    default:
      return draft;
  }
});
export default testFlagsReducer;