import { handleTextReplacement } from 'tinymce-plugins/hscopilotcollaboration/utils';
import { canUpdateSelection } from 'tinymce-plugins/hscopilotcollaboration/utils';
export default function handleActionFromCopilot(action, customHandleTextReplacement) {
  var _window$tinymce;
  // @ts-expect-error window.tinymce global
  const activeEditor = (_window$tinymce = window.tinymce) === null || _window$tinymce === void 0 ? void 0 : _window$tinymce.activeEditor;
  switch (action.payload.actionType) {
    case 'insert':
      {
        const handledByCustomHandler = customHandleTextReplacement && customHandleTextReplacement(activeEditor, action.payload.html) !== false;
        if (activeEditor && !handledByCustomHandler && canUpdateSelection(activeEditor)) {
          handleTextReplacement(activeEditor, action.payload.html);
        }
        break;
      }
    default:
      console.error('Unknown action type', action.payload.actionType);
  }
}