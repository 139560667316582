import http from 'hub-http/clients/apiClient';
const url = 'users/v2/app/hub-users';

// copied from { HubUser } from 'messaging-types-lib/hubUser';

export function fetch() {
  return http.get(url, {
    query: {
      active: true,
      activePermissions: true,
      visible: true
    }
  });
}
export function fetchUser() {
  return http.get(`users/v1/app/user`);
}