'use es6';

import { createSelector } from 'reselect';
import { getAdvancedModuleWithoutAccessError, getPaymentsFieldWithoutAccessError, getPersonalizationTokensWithoutAccessError } from 'ContentEditorUI/redux/selectors/downgradeSelectors';
import { getDataValidationErrors } from 'ContentEditorUI/redux/selectors/moduleErrorSelectors';
import { getPublishValidationErrors } from './pagePublishSelectors';
import { getPageSettingsErrors, getPageSettingsWarnings } from './pageSettingsSelectors';
import { getIsPublishOptionsModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { getErrorLabels } from 'ContentEditorUI/utils/validationUtils';
import { omit } from 'underscore';
const PAGE_EXPIRY_FIELD_ERRORS = ['pageUnpublishDate', 'pageUnpublishRedirectUrl'];
const getPageErrorsAndWarnings = createSelector([getDataValidationErrors, getPublishValidationErrors, getPageSettingsErrors, getAdvancedModuleWithoutAccessError, getPaymentsFieldWithoutAccessError, getPersonalizationTokensWithoutAccessError, getPageSettingsWarnings, getIsPublishOptionsModalOpen], (moduleDataValidationErrors, publishErrors, settingsErrors, advancedModuleWithoutAccessErrors, paymentsFieldWithoutAccessErrors, personalizationTokensWithoutAccessError, settingsWarnings, isPublishOptionsModalOpen) => {
  // pageUnpublishDate and pageUnpublishRedirectUrl are errors related to the page expiry field
  // in Patagonia, they only exist within the modal, so we don't want them affecting the non-modal button state
  // this filters out the errors from the header publish button when the modal is open
  const updatedSettingsErrors = isPublishOptionsModalOpen ? omit(settingsErrors, PAGE_EXPIRY_FIELD_ERRORS) : settingsErrors;
  return {
    errors: Object.assign({}, publishErrors, updatedSettingsErrors, moduleDataValidationErrors, advancedModuleWithoutAccessErrors, paymentsFieldWithoutAccessErrors, personalizationTokensWithoutAccessError),
    warnings: Object.assign({}, settingsWarnings)
  };
});
export const getPageErrorLabels = createSelector(getPageErrorsAndWarnings, ({
  errors
}) => getErrorLabels(errors));
export const getPageWarningLabels = createSelector(getPageErrorsAndWarnings, ({
  warnings
}) => getErrorLabels(warnings));