'use es6';

import http from 'hub-http/clients/apiClient';
const BASE_PATH = 'filemanager/api/v2';
function fetchImageThumbnailUrls(imageUrls, size = 'medium') {
  return http.get(`${BASE_PATH}/files/existing-thumbnails`, {
    query: {
      size,
      urls: imageUrls
    }
  });
}
function fetchFileByUrl(url) {
  return http.get(`${BASE_PATH}/files/resolve`, {
    query: {
      url,
      allowRedirects: true
    }
  });
}
export default {
  fetchImageThumbnailUrls,
  fetchFileByUrl
};