import { buildHttpRpcPath } from './internal';
import { generatePersistedQueries, parseChirpGraphQLResponse } from './chirp';
export { defaultDismissErrorWithoutHandling } from './internal';
import { defaultDismissErrorWithoutHandling } from '.';
import { getAvroGateway, parseResultUnion } from './chirp-avro';
export { formatChirpMSWInternalErrorResponse, formatChirpMSWUserErrorResponse, formatChirpMSWSuccessResponse, formatChirpAvroMSWSuccessResponse, formatChirpAvroMSWUserErrorResponse, formatChirpAvroMSWInternalErrorResponse } from './chirp';

/**
 * An Object with type annotations containing all the network and type
 * information needed to model an http request as an RPC. See
 * `createHttpRpcClientV1` for more details.
 */

/**
 * The path parameters, query parameters, and request body for an RPC request
 * are passed as object properties to the RPC client function. This object is
 * relatively simple, but its type is highly overloaded depending on which
 * pieces of data are required for a given RPC. The complexity of this type is
 * mostly to do with determining whether each of the three properties on this
 * type should be required given the RPC being executed. Most of the time
 * you can think of this type as:
 *
 * ```
 * interface RpcInputs<RequestBody, PathParameters, QueryParameters> {
 *   data?: RequestBody,
 *   pathParameters?: PathParameters,
 *   queryParameters?: QueryParameters
 * }
 * ```
 */

/** @deprecated prefer createRpcClientV2 */
export function createRpcClientV1(options) {
  const httpRpcClient = createHttpRpcClientV1(options);
  const chirpRpcClient = createChirpRpcClientV1(options);
  function executeRemoteProcedure(details, inputs) {
    if (details.method) {
      return httpRpcClient.call(details, inputs);
    } else {
      return chirpRpcClient.call(details, inputs);
    }
  }
  return {
    call: executeRemoteProcedure
  };
}

/**
 * This client factory takes a `hub-http` client and returns a client that
 * executes network requests as RPCs.
 *
 * An RPC (Remote Procedure Call) is a network request where the details of the
 * network have been abstracted away. In other words, RPCs are calls to typed
 * async functions that make network requests under the hood. The goal is to
 * minimize time spent thinking about network details (HTTP verbs, url
 * sanitization, CHIRP query names, etc.).
 *
 * This factory is suffixed "V2" because it is guaranteed to never change in a
 * breaking way. If features incompatible with this client factory are added
 * in the future, this client factory will remain available and a new "V3" will
 * be exposed as a sibling for new consumers.
 *
 * ```
 * // kudos service contains generated code
 * import { getKudos } from 'kudos-service/client';
 * import { createRpcClientV1 } from 'rpc-client-utils';
 *
 * const rpcClient = createRpcClientV1({ hubHttpClient: hubHttpClient });
 *
 * const kudos = await rpcClient.call(getKudos, {
 *   // depending on the RPC, data and pathParameters may also be passed
 *   queryParameters: {
 *     hubspotter: 'jdoe'
 *   }
 * });
 * ```
 */
export function createRpcClientV2(options) {
  const httpRpcClient = createHttpRpcClientV2(options);
  const chirpRpcClient = createChirpRpcClientV2(options);
  const chirpAvroRpcClient = createChirpAvroRpcClientV1(options);
  function executeRemoteProcedure(details, inputs) {
    if (details.method) {
      return httpRpcClient.call(details, inputs);
    } else if ('gateway' in details) {
      return chirpRpcClient.call(details, inputs);
    } else {
      return chirpAvroRpcClient.call(details, inputs);
    }
  }
  return {
    call: executeRemoteProcedure
  };
}

/** @deprecated prefer createHttpRpcClientV2 */
export function createHttpRpcClientV1({
  hubHttpClient,
  dismissErrorWithoutHandling = error => defaultDismissErrorWithoutHandling(error)
}) {
  function executeRemoteProcedure(details, {
    pathParameters,
    queryParameters,
    data
  }) {
    const path = buildHttpRpcPath(details, pathParameters);
    const syncStackError = new Error(`HTTP RPC failed for ${details.method}-/${path}`);
    let options = undefined;
    if (data) {
      options = options || {};
      options.data = data;
    }
    if (queryParameters) {
      options = options || {};
      options.query = queryParameters;
    }
    const apiPromise = hubHttpClient[details.method](path, options);
    return apiPromise.catch(error => {
      dismissErrorWithoutHandling(syncStackError);
      throw error;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}
export function createHttpRpcClientV2({
  hubHttpClient
}) {
  function executeRemoteProcedure(details, {
    pathParameters,
    queryParameters,
    data
  }) {
    const path = buildHttpRpcPath(details, pathParameters);
    const syncStackError = new Error(`HTTP RPC failed for ${details.method}-/${path}`);
    let options = undefined;
    if (data) {
      options = options || {};
      options.data = data;
    }
    if (queryParameters) {
      options = options || {};
      options.query = queryParameters;
    }
    const apiPromise = hubHttpClient[details.method](path, options);
    return apiPromise.catch(error => {
      syncStackError.cause = error;
      throw syncStackError;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}

/** @deprecated prefer createChirpRpcClientV2 */
export function createChirpRpcClientV1({
  hubHttpClient,
  dismissErrorWithoutHandling = error => defaultDismissErrorWithoutHandling(error)
}) {
  function executeRemoteProcedure(details, {
    request
  }) {
    const syncStackError = new Error(`CHIRP RPC failed for ${details.name}`);
    const {
      gateway,
      name,
      query
    } = details;
    const {
      chirpGraphQLGatewayEndpoint,
      data
    } = generatePersistedQueries({
      chirpGraphQLGatewayEndpoint: gateway,
      operationName: name,
      query,
      variables: {
        request
      }
    });
    return hubHttpClient.post(chirpGraphQLGatewayEndpoint, {
      data
    }).then(response => parseChirpGraphQLResponse(response)).catch(error => {
      dismissErrorWithoutHandling(syncStackError);
      throw error;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}
export function createChirpRpcClientV2({
  hubHttpClient
}) {
  function executeRemoteProcedure(details, {
    request
  }) {
    const syncStackError = new Error(`CHIRP RPC failed for ${details.name}`);
    const {
      gateway,
      name,
      query
    } = details;
    const {
      chirpGraphQLGatewayEndpoint,
      data
    } = generatePersistedQueries({
      chirpGraphQLGatewayEndpoint: gateway,
      operationName: name,
      query,
      variables: {
        request
      }
    });
    return hubHttpClient.post(chirpGraphQLGatewayEndpoint, {
      data
    }).then(response => parseChirpGraphQLResponse(response)).catch(error => {
      syncStackError.cause = error;
      throw syncStackError;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}
const _unhandledEnumCase = Symbol('unhandled-enum-case');
/**
 * `unhandledEnumCase` is an opaque type brand representing values which could
 * later be added to an enum. You can access the underlying `string` type of
 * unhandled enum cases using the `readUnhandledEnumCase` utility function. The
 * presence of this type is intended to force consumers to handle enum expansion
 * gracefully. This guardrail is massively beneficial to backend engineers
 * because it means enum expansion doesn't count as a breaking change from a
 * schema evolution perspective.
 */

export function readUnhandledEnumCase(unhandledEnumCase) {
  if (typeof unhandledEnumCase === 'string') {
    return unhandledEnumCase;
  }
  // this should never happen. The type unhandledEnumCase should always at
  // runtime be a string. We don't support non-string enums.
  throw new Error('unhandledEnumCase had unexpected non-string type');
}
export function createChirpAvroRpcClientV1({
  hubHttpClient
}) {
  function executeRemoteProcedure(details, {
    request
  }) {
    const {
      auth,
      serviceName,
      rpcName,
      fingerprint
    } = details;
    const syncStackError = new Error(`CHIRP RPC failed for ${rpcName}`);
    return hubHttpClient.post(`${getAvroGateway(auth)}/${serviceName}/${rpcName}`, {
      data: request,
      headers: {
        'x-hs-fingerprint': fingerprint
      }
    }).then(response => parseResultUnion(response)).catch(error => {
      syncStackError.cause = error;
      throw syncStackError;
    });
  }
  return {
    call: executeRemoteProcedure
  };
}