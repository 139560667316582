/**
 * handle which step to show based on the mode and current selection values
 *
 * | Steps | card section | body info | footer |
 * |-|-|-|-|
 * | Step 1 | Nothing scheduled | null | null |
 * | Step 2 | Scheduling controls | reflects current scheduling values | schedule/cancel |
 * | Step 3 | Currently scheduled info | Info for where to find scheduled content | null |
 * | Step 4 | Edit schedule | reflects current scheduling values | reschedule/cancel |
 */
export const SchedulingSteps = {
  NOTHING_SCHEDULED: 1,
  SCHEDULING: 2,
  SCHEDULED: 3,
  RESCHEDULING: 4
};