import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["promptData"];
import { useEffect, useCallback } from 'react';
import { useSendOnCopilotChannel } from '../channel/hooks/useSendOnCopilotChannel';
import { TOPIC_NAMES } from '../types/Channel';
import { useIsCopilotWidgetActive } from './useIsCopilotWidgetActive';
import { useSendCollaborationPrompt, useSendCollaborationAction } from './useSendCollaboration';
import { useSubscribeToCopilotChannel } from '../channel/hooks/useSubscribeToCopilotChannel';
import { consoleIfNotProd } from 'chatspot-core/loggers/consoleIfNotProd';
export const useCollaborationSession = ({
  onCopilotAction,
  onGetCurrentSessionContext,
  sessionId,
  appName,
  plugins
}) => {
  const isCopilotWidgetActive = useIsCopilotWidgetActive();
  const sendCollaborationPrompt = useSendCollaborationPrompt();
  const sendCollaborationAction = useSendCollaborationAction();
  const sendToCopilotFromParentApp = useSendOnCopilotChannel(TOPIC_NAMES.COLLABORATE_PARENT_APP_MESSAGE);
  const promptUserForMoreInfo = useSendOnCopilotChannel(TOPIC_NAMES.PROMPT_USER_FOR_MORE_INFO);
  useEffect(() => {
    if (isCopilotWidgetActive && appName && sessionId && plugins && plugins.length > 0) {
      sendToCopilotFromParentApp({
        payload: {
          appName,
          sessionId,
          plugins
        }
      });
    }
  }, [appName, isCopilotWidgetActive, plugins, sendToCopilotFromParentApp, sessionId]);
  const sendCollaborationStateChangeFromAppToCopilot = useCallback(collaborationState => {
    sendToCopilotFromParentApp({
      payload: {
        parentAppState: collaborationState,
        sessionId,
        plugins
      }
    });
  }, [plugins, sendToCopilotFromParentApp, sessionId]);
  const sendActionFromAppToCopilot = useCallback(messageAction => {
    if (!messageAction) {
      consoleIfNotProd.warn('No message action provided');
      return;
    }
    const {
        promptData
      } = messageAction,
      messageActionWithoutPrompt = _objectWithoutPropertiesLoose(messageAction, _excluded);
    if (promptData) {
      promptUserForMoreInfo({
        messageAction: messageActionWithoutPrompt,
        messageMetaInfo: {
          source: 'PLUGIN',
          type: 'IN-APP'
        },
        promptData
      });
    } else {
      sendCollaborationAction({
        messageAction,
        messageMetaInfo: {
          source: 'PLUGIN',
          type: 'IN-APP'
        }
      });
    }
  }, [promptUserForMoreInfo, sendCollaborationAction]);
  const sendPromptFromAppToCopilot = useCallback(({
    messageText,
    collaborationContext
  }) => {
    sendCollaborationPrompt({
      messageText,
      messageMetaInfo: {
        source: 'PLUGIN',
        type: 'IN-APP',
        collaborationContext
      }
    });
  }, [sendCollaborationPrompt]);
  useSubscribeToCopilotChannel(TOPIC_NAMES.COLLABORATE_COPILOT_ACTON, onCopilotAction);
  const handleOnGetCurrentSessionContext = useCallback(({
    messagePassthrough,
    requestSessionsId
  }) => {
    if (requestSessionsId !== sessionId) {
      return;
    }
    // Allows for a promise, or a function
    Promise.resolve(onGetCurrentSessionContext).then(resolvedContext => {
      const isFunction = typeof resolvedContext === 'function';
      let collaborationContext = isFunction ? resolvedContext() : resolvedContext;
      if (!collaborationContext) {
        consoleIfNotProd.warn(`No context found for appName: ${appName} session: ${sessionId}`);
        collaborationContext = '';
      }
      sendPromptFromAppToCopilot({
        messageText: messagePassthrough,
        collaborationContext
      });
    }).catch(() => {
      consoleIfNotProd.error('Error getting current session context');
      sendPromptFromAppToCopilot({
        messageText: messagePassthrough,
        collaborationContext: ''
      });
    });
  }, [appName, sendPromptFromAppToCopilot, onGetCurrentSessionContext, sessionId]);
  useSubscribeToCopilotChannel(TOPIC_NAMES.COLLABORATE_COPILOT_GET_CONTEXT, handleOnGetCurrentSessionContext);
  return {
    sendPromptFromAppToCopilot,
    sendActionFromAppToCopilot,
    sendCollaborationStateChangeFromAppToCopilot
  };
};