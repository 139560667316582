import { useCallback } from 'react';
import { useSendOnCopilotChannel } from '../channel/hooks/useSendOnCopilotChannel';
export const useSendCollaborationPrompt = () => {
  const sendCopilotMessageOnChannel = useSendOnCopilotChannel('SEND_MESSAGE');
  return useCallback(({
    messageText = '',
    messageMetaInfo
  }) => {
    if (messageText) {
      sendCopilotMessageOnChannel({
        messageText,
        messageMetaInfo
      });
    }
  }, [sendCopilotMessageOnChannel]);
};
export const useSendCollaborationAction = () => {
  const sendCopilotActionOnChannel = useSendOnCopilotChannel('SEND_ACTION');
  return useCallback(({
    messageAction,
    messageMetaInfo
  }) => {
    sendCopilotActionOnChannel({
      messageAction,
      messageMetaInfo
    });
  }, [sendCopilotActionOnChannel]);
};
export const useAskUserForMoreInfo = () => {
  const sendCopilotActionOnChannel = useSendOnCopilotChannel('PROMPT_USER_FOR_MORE_INFO');
  return useCallback(({
    messageAction,
    messageMetaInfo,
    promptData
  }) => {
    sendCopilotActionOnChannel({
      messageAction,
      messageMetaInfo,
      promptData
    });
  }, [sendCopilotActionOnChannel]);
};