import { createSelector } from 'reselect';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { getHasContainers, getHasAnyLayoutSections
// @ts-expect-error not yet migrated
} from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIsReadOnlyMode, getIframeReady, getAddModuleSidebarLoaded } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { isApplyChangesModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
// @ts-expect-error not yet migrated
import { getPatagoniaAddSidebarSubCategories } from 'ContentEditorUI/redux/selectors/sidebarCategorySelectors';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
// @ts-expect-error not yet migrated
import { shouldShowSchema } from 'ContentEditorUI/lib/moduleSearchHelpers';
import { getCategoryToModulesMap } from 'ContentEditorUI/js/redux/selectors/categorizedModuleSchemaSelectors';
import { getThemeSections, getSavedSections, getThemelessSections } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { LAYOUT_COLUMNS, LAYOUTS_KEY_TO_LABEL_MAP, translatedLabelKey
// @ts-expect-error not yet migrated
} from 'ContentEditorUI/containers/sections/utils';
import { shouldShowSection } from 'ContentEditorUI/utils/sidebarUtil';
export const getSidebarState = basicSelector(state => state.sidebarState);
export const getSidebarSearchQuery = createSelector([getSidebarState], sidebarState => sidebarState.searchQuery);
export const getXrayReadabilityType = createSelector([getSidebarState], sidebarState => sidebarState.xrayReadabilityType);
export const getXrayReadabilityArr = createSelector([getSidebarState], sidebarState => {
  return sidebarState.xrayReadabilityArr;
});
export const getActiveSidebarPanel = createSelector([getSidebarState, getIsReadOnlyMode], (sidebarState, isReadOnlyMode) => {
  let activePanel = sidebarState ? sidebarState.activePanel : '';
  if (isReadOnlyMode && activePanel === SIDEBAR_PANELS.addWidget) {
    activePanel = SIDEBAR_PANELS.widgetList;
  }
  return activePanel;
});
export const getSidebarPanels = createSelector([getHasContainers, getHasAnyLayoutSections, getIsReadOnlyMode], (hasContainers, hasAnyLayoutSections, isReadOnlyMode) => {
  const panels = [SIDEBAR_PANELS.preview, SIDEBAR_PANELS.optimizer, SIDEBAR_PANELS.widgetList, SIDEBAR_PANELS.edit];
  if (!isReadOnlyMode && (hasContainers || hasAnyLayoutSections)) {
    panels.push(SIDEBAR_PANELS.addWidget);
  }
  return panels;
});
// NOTE These are editSidebar selectors. It's a different piece of redux state,
// but we're keping them in the same selector file because we should eventually
// consolidate them.
const getEditSidebar = basicSelector(state => state.editSidebar);
export const getEditSidebarActiveTab = createSelector([getEditSidebar], editSidebar => editSidebar.activeTab);
export const getIsPatagoniaSidebarCollapsed = createSelector([getSidebarState], sidebarState => sidebarState.patagoniaSidebarCollapsed);
export const getIsSidebarExpanded = createSelector([getActiveSidebarPanel, getIsPatagoniaSidebarCollapsed], (activeSidebarPanel, patagoniaSidebarCollapsed) => activeSidebarPanel !== '' && !patagoniaSidebarCollapsed);
export const getIsPatagoniaSidebarCollapseAnimationFinished = createSelector([getSidebarState], sidebarState => sidebarState.patagoniaSidebarCollapseAnimationFinished);
export const getPatagoniaSidebarIframeVisible = createSelector([getIsPatagoniaSidebarCollapsed, getIsPatagoniaSidebarCollapseAnimationFinished, getActiveSidebarPanel], (sidebarCollapsed, collapseAnimationFinished, activePanel) => {
  return (!sidebarCollapsed && collapseAnimationFinished || sidebarCollapsed && !collapseAnimationFinished) && activePanel === SIDEBAR_PANELS.addWidget;
});
export const getSidebarIframeNeedsLoadingIndicator = createSelector([getIframeReady, getAddModuleSidebarLoaded, getActiveSidebarPanel], (iframeReady, addModuleSidebarLoaded, activePanel) => {
  return (!iframeReady || !addModuleSidebarLoaded) && activePanel === SIDEBAR_PANELS.addWidget;
});
export const getModuleEditSidebarNeedsLoadingIndicator = createSelector([getIframeReady, getActiveSidebarPanel, getIsReadOnlyMode], (iframeReady, activePanel, isReadOnlyMode) => {
  return !iframeReady && !isReadOnlyMode && activePanel === SIDEBAR_PANELS.edit;
});
export const getScrollToModule = createSelector([getSidebarState], sidebarState => sidebarState.scrollToModule);
export const getApplyChangesButtonOpen = createSelector([getEditSidebar, isApplyChangesModalOpen], (editSidebar, applyChangesModalOpen) => editSidebar.applyChangesButtonOpen && !applyChangesModalOpen);
export const getPreviousSidebarPanel = createSelector([getSidebarState], sidebarState => {
  return sidebarState.previousPanel;
});
export const getSelectedField = createSelector([getEditSidebar], sidebarState => sidebarState.selectedField);
export const getSidebarCategory = createSelector([getSidebarState], sidebarState => sidebarState.category);
export const getSidebarSubCategory = createSelector([getSidebarState], sidebarState => sidebarState.subCategory);

// generally will be be the first item, but can be overridden
// E.g. Don't show an empty section when a useful section exists
export const getDefaultSubCategoryMap = createSelector([getSidebarState, getPatagoniaAddSidebarSubCategories, getSidebarCategory], (sidebarState, subCategories, sidebarCategory) => {
  if (sidebarCategory === PATAGONIA_ADD_PANEL_CATEGORIES.MODULES) {
    return {
      MODULES: subCategories[sidebarCategory][0],
      SECTIONS: 'SAVED',
      LAYOUTS: 'NONE'
    };
  }
  return sidebarState.defaultSelectedSubCategoryMap;
});
export const getSidebarSearchResultsMap = createSelector([getSidebarSearchQuery, getCategoryToModulesMap, getThemeSections, getSavedSections, getThemelessSections, getIsUngatedForPatagonia], (searchQuery, categoryToModulesMap, themeSections, savedSections, themelessSections, isUngatedForPatagonia) => {
  const filteredResultsMap = {
    MODULES: {
      results: {
        theme: {
          modules: [],
          count: 0
        },
        nonTheme: {
          modules: [],
          count: 0
        }
      },
      count: 0
    },
    SECTIONS: {
      SAVED: {
        results: []
      },
      THEME: {
        results: []
      },
      NO_THEME: {
        results: []
      },
      count: 0
    },
    LAYOUTS: {
      results: {},
      count: 0
    },
    firstCategoryWithResult: PATAGONIA_ADD_PANEL_CATEGORIES.MODULES
  };
  if (!isUngatedForPatagonia) {
    return filteredResultsMap;
  }
  //this selector is for universal search that is part of module categories work which searches
  //through all categories instead of within a category/sub category and maps the results and count out in one place

  //filter modules
  const modulesCollection = categoryToModulesMap['ALL'] || [];
  const filteredModulesCollection = {
    theme: {
      modules: [],
      count: 0
    },
    nonTheme: {
      modules: [],
      count: 0
    }
  };
  let filteredModulesCount = modulesCollection.length || 0;
  if (searchQuery) {
    modulesCollection.forEach(w => {
      if (shouldShowSchema(w, searchQuery.toLowerCase())) {
        if (w.isThemeAsset) {
          filteredModulesCollection.theme.modules.push(w);
        } else {
          filteredModulesCollection.nonTheme.modules.push(w);
        }
      }
    });
    const filteredThemeCount = filteredModulesCollection.theme.modules.length || 0;
    filteredModulesCollection.theme.count = filteredThemeCount;
    const filteredNonThemeCount = filteredModulesCollection.nonTheme.modules.length || 0;
    filteredModulesCollection.nonTheme.count = filteredNonThemeCount;
    filteredModulesCount = filteredThemeCount + filteredNonThemeCount;
  }
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.MODULES] = {
    results: filteredModulesCollection,
    count: filteredModulesCount
  };
  if (filteredModulesCount) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.MODULES;
  }

  //filter sections
  const filteredThemeSections = searchQuery ? themeSections.filter(section => {
    return shouldShowSection(searchQuery, section);
  }) : themeSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS][PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.THEME].results = filteredThemeSections;
  const filteredSavedSections = searchQuery ? savedSections.filter(section => {
    return shouldShowSection(searchQuery, section);
  }) : savedSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS][PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.SAVED].results = filteredSavedSections;
  const filteredThemelessSections = searchQuery ? themelessSections.filter(embed => {
    return shouldShowSection(searchQuery, embed);
  }) : themelessSections;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS][PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS.NO_THEME].results = filteredThemelessSections;

  // TODO: Revisit this `|| 0` to see if we can clean it up
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS].count = filteredThemeSections.length + filteredSavedSections.length + filteredThemelessSections.length || 0;
  if (!filteredResultsMap.firstCategoryWithResult && (filteredThemeSections.length || filteredSavedSections.length || filteredThemelessSections.length)) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS;
  }

  //filter layouts
  const filteredLayouts = searchQuery ? Object.keys(LAYOUT_COLUMNS).filter(key => translatedLabelKey(key).includes(searchQuery.toUpperCase()) || LAYOUTS_KEY_TO_LABEL_MAP[key].includes(searchQuery)).reduce((cur, key) => {
    return Object.assign(cur, {
      [key]: LAYOUT_COLUMNS[key]
    });
  }, {}) : LAYOUT_COLUMNS;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS].results = filteredLayouts;
  filteredResultsMap[PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS].count = Object.keys(filteredLayouts).length || 0;
  if (!filteredResultsMap.firstCategoryWithResult && Object.keys(filteredLayouts).length) {
    filteredResultsMap.firstCategoryWithResult = PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS;
  }
  return filteredResultsMap;
});