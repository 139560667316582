'use es6';

import { isJsRenderedModule, moduleHasAnyJs } from './ModuleHelpers';
import * as moduleRenderClient from '../api/moduleRenderClient';
import * as RenderContext from '../constants/RenderContext';
import { isEmptyObject } from 'ContentUtils/helpers/ObjectHelpers';
const ISLAND_ATTRIBUTE_SELECTOR = '[data-hs-island="true"]';
function isJsRenderedModuleWithIsland(schema, renderedDomNode) {
  return isJsRenderedModule(schema) && Boolean(renderedDomNode.querySelector(ISLAND_ATTRIBUTE_SELECTOR));
}
export default class AppModuleMarkupRequestManager {
  constructor({
    onFormatModuleForRequest,
    onFormatRequestData,
    onHtmlReceivedForModule,
    onModuleHtmlRequestIsInvalid,
    onRenderError,
    allowCss = false
  }) {
    this.formatModuleForRequest = (module, smartRuleModuleBody) => {
      if (this.onFormatModuleForRequest) {
        return this.onFormatModuleForRequest(module);
      } else {
        const existingBody = !smartRuleModuleBody || isEmptyObject(smartRuleModuleBody) ? module.body : smartRuleModuleBody;
        const formattedModule = Object.assign({}, module, {
          body: Object.assign({}, existingBody || {}, {
            no_wrapper: false
          })
        });
        if (formattedModule.module_id && !formattedModule.body.module_id) {
          formattedModule.body.module_id = formattedModule.module_id;
        }
        return formattedModule;
      }
    };
    this.fetchHtmlForModule = (module, smartRuleModuleBody, query = {}) => {
      const moduleForRequest = this.formatModuleForRequest(module, smartRuleModuleBody);
      let data;
      if (this.onFormatRequestData) {
        data = this.onFormatRequestData(moduleForRequest);
      } else {
        data = moduleForRequest;
      }
      return moduleRenderClient.renderWithMeta(data, query);
    };
    this.requestModuleMarkup = ({
      module,
      moduleName,
      moduleVersion,
      options = {},
      schema,
      smartRuleModuleBody
    }) => {
      if (module && !moduleHasAnyJs(schema)) {
        this.fetchHtmlForModule(module, smartRuleModuleBody, options.query).then(resp => {
          const {
            assets,
            html,
            renderedWithContext
          } = resp;
          const moduleHasJs = assets && !!(assets.jsHead.length || assets.jsFooter.length);
          const moduleHasCss = assets && !!assets.css.length;
          const hasRenderErrors = resp.errors && resp.errors.length > 0;
          const isFullRenderContextAndIgnoringAssets = renderedWithContext === RenderContext.FULL_CONTENT;
          const parsedModuleNode = this.domParser.parseFromString(html, 'text/html');
          if (isFullRenderContextAndIgnoringAssets && !hasRenderErrors || !moduleHasJs && (this.allowCss || !moduleHasCss) && !isJsRenderedModuleWithIsland(schema, parsedModuleNode) && !hasRenderErrors) {
            const cssToAdd = [...assets.cssToAdd];
            if (this.allowCss && !isFullRenderContextAndIgnoringAssets) {
              cssToAdd.unshift(...assets.css);
            }
            this.onHtmlReceivedForModule({
              cssToAdd,
              moduleHtml: html,
              moduleName,
              moduleVersion,
              options
            });
          } else {
            if (hasRenderErrors && this.onRenderError) {
              this.onRenderError(module, resp.errors);
            }
            // The api request succeeded, but we still can't use the html
            this.onModuleHtmlRequestIsInvalid({
              moduleHasCss,
              moduleHasJs,
              moduleName,
              moduleVersion,
              options
            });
          }
        }).catch(() => {
          // The api request failed, so we do not have access to the html
          this.onModuleHtmlRequestIsInvalid({
            moduleName,
            moduleVersion,
            options
          });
        });
      } else {
        // We can't make use of this html when modules have custom js
        this.onModuleHtmlRequestIsInvalid({
          moduleHasJs: true,
          moduleName,
          moduleVersion,
          options
        });
      }
    };
    // Required config
    this.onModuleHtmlRequestIsInvalid = onModuleHtmlRequestIsInvalid;
    this.onHtmlReceivedForModule = onHtmlReceivedForModule;
    // Optional config
    this.onFormatModuleForRequest = onFormatModuleForRequest;
    this.onFormatRequestData = onFormatRequestData;
    this.onRenderError = onRenderError;
    this.allowCss = allowCss;
    this.domParser = new DOMParser();
  }
}