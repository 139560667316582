import { combineReducers } from 'redux';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import * as RequestStatusTypes from 'ContentUtils/constants/RequestStatus';
import { isNullOrUndefined } from 'ContentUtils/helpers/ObjectHelpers';
import Routes from 'ContentUtils/Routes';
const {
  BRAND_SETTINGS_FETCH_COLORS,
  BRAND_SETTINGS_FETCH_STARTED,
  BRAND_SETTINGS_FETCH_SUCCEEDED,
  BRAND_SETTINGS_FETCH_FAILED,
  BRAND_SETTINGS_BUSINESS_UNIT_FETCH,
  BRAND_SETTINGS_LOGO_FETCH,
  BRAND_SETTINGS_BRAND_KIT_FETCH
} = ActionTypes;
const {
  FAILED,
  PENDING,
  SUCCEEDED,
  UNINITIALIZED
} = RequestStatusTypes;
const COLOR_ALIASES = {
  PRIMARY_COLOR: 'primaryColor',
  SECONDARY_COLOR: 'secondaryColor',
  ACCENT1_COLOR: 'accentColor1',
  ACCENT2_COLOR: 'accentColor2',
  ACCENT3_COLOR: 'accentColor3'
};
const brandSettings = (state = {}, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        const {
          brandData,
          businessUnitId
        } = action;
        const {
          branding,
          brandKitData
        } = brandData;
        if (Routes.isUngated('CMv2:DisplayInheritanceIcon')) {
          const currentBrandKit = brandKitData.find(_brandKit => {
            const {
              brandKit
            } = _brandKit;
            if (!businessUnitId && brandKit.primaryBrandKit || businessUnitId && businessUnitId === brandKit.businessUnitId) {
              return _brandKit;
            }
            return null;
          }) || {};

          // only use values from selected brand kit
          const {
            colors: brandColors = [],
            logos: brandLogos = [],
            primaryColor,
            secondaryColor,
            accentColor1,
            accentColor2,
            accentColor3,
            brandKit
          } = currentBrandKit;

          // colors
          const brandColorAliases = {
            [COLOR_ALIASES.PRIMARY_COLOR]: primaryColor && primaryColor.color,
            [COLOR_ALIASES.SECONDARY_COLOR]: secondaryColor && secondaryColor.color,
            [COLOR_ALIASES.ACCENT1_COLOR]: accentColor1 && accentColor1.color,
            [COLOR_ALIASES.ACCENT2_COLOR]: accentColor2 && accentColor2.color,
            [COLOR_ALIASES.ACCENT3_COLOR]: accentColor3 && accentColor3.color
          };
          const additionalColors = brandColors.slice(1).map(color => color.color);
          const secondaryAndAccentColors = Object.entries(brandColorAliases).reduce((hash, colorAlias) => {
            const [key, hex] = colorAlias;
            if (key && key !== COLOR_ALIASES.PRIMARY_COLOR && hex) {
              hash = [...hash, hex];
            }
            return hash;
          }, []);
          const primaryColorArr = brandColorAliases[COLOR_ALIASES.PRIMARY_COLOR] ? [brandColorAliases[COLOR_ALIASES.PRIMARY_COLOR]] : [];
          const colors = [...primaryColorArr, ...additionalColors, ...secondaryAndAccentColors];

          //logos
          let primaryLogo = null;
          const logos = brandLogos.map(logo => {
            if (!primaryLogo && logo.primaryLogo) primaryLogo = logo;
            logo.src = logo.logoUrl;
            logo.alt = logo.logoAltText;
            return logo;
          });
          if (primaryLogo) {
            primaryLogo['src'] = primaryLogo['logoUrl'];
            primaryLogo['alt'] = primaryLogo['logoAltText'];
          }
          const newBrandingState = Object.assign({}, state, {
            brandKitId: brandKit && brandKit.id,
            colors,
            logos,
            primaryLogo
          }, branding, brandColorAliases);
          return newBrandingState;
        } else {
          let brandLogos = [];
          let currentBrandKit = {};
          brandKitData.forEach(_brandKit => {
            const {
              brandKit,
              logos
            } = _brandKit;
            brandLogos = brandLogos.concat(logos);
            if (!businessUnitId && brandKit.primaryBrandKit || businessUnitId && businessUnitId === brandKit.businessUnitId) {
              currentBrandKit = _brandKit;
            }
          });
          const {
            colors: brandColors = []
          } = currentBrandKit;
          const colors = brandColors.map(colorData => colorData.color);
          let primaryLogo = null;
          const logos = brandLogos.map(logo => {
            if (!primaryLogo && logo.primaryLogo) primaryLogo = logo;
            logo.src = logo.logoUrl;
            logo.alt = logo.logoAltText;
            return logo;
          });
          if (primaryLogo) {
            primaryLogo['src'] = primaryLogo['logoUrl'];
            primaryLogo['alt'] = primaryLogo['logoAltText'];
          }
          return Object.assign({}, state, {
            colors,
            logos,
            primaryLogo
          }, branding);
        }
      }
    case BRAND_SETTINGS_FETCH_COLORS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return Object.assign({}, state, {
          colors: response.map(colorData => colorData.color)
        });
      }
    default:
      return state;
  }
};
const colorsRequestStatus = (state = UNINITIALIZED, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_COLORS:
      {
        const {
          response,
          error
        } = action;
        let requestStatus = PENDING;
        if (response) requestStatus = SUCCEEDED;
        if (error) requestStatus = FAILED;
        return requestStatus;
      }
    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        return SUCCEEDED;
      }
    default:
      return state;
  }
};
const requestStatus = (state = UNINITIALIZED, action) => {
  switch (action.type) {
    case BRAND_SETTINGS_FETCH_STARTED:
      {
        return PENDING;
      }
    case BRAND_SETTINGS_FETCH_SUCCEEDED:
      {
        return SUCCEEDED;
      }
    case BRAND_SETTINGS_FETCH_FAILED:
      {
        return FAILED;
      }
    default:
      return state;
  }
};
const businessUnitId = (state = null, action) => {
  const {
    type,
    businessUnitId: _businessUnitId
  } = action;
  if (type === BRAND_SETTINGS_BUSINESS_UNIT_FETCH && !isNullOrUndefined(_businessUnitId)) {
    return _businessUnitId;
  } else {
    return state;
  }
};
const brandKitId = (state = null, action) => {
  const {
    type,
    brandKitId: _brandKitId
  } = action;
  if (type === BRAND_SETTINGS_BRAND_KIT_FETCH && _brandKitId) {
    return _brandKitId;
  } else {
    return state;
  }
};
const brandedLogo = (state = null, action) => {
  if (action.type === BRAND_SETTINGS_LOGO_FETCH && action.brandedLogo) {
    const {
      logoUrl,
      logoAltText
    } = action.brandedLogo;
    return {
      src: logoUrl,
      alt: logoAltText
    };
  } else {
    return state;
  }
};
export default combineReducers({
  brandedLogo,
  brandSettings,
  businessUnitId,
  brandKitId,
  colorsRequestStatus,
  requestStatus
});