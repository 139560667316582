import { createSelector } from 'reselect';
// @ts-expect-error not yet migrated
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getCategoryId, getIsEmail } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import ContentRoutes from 'ContentUtils/Routes';
import { CURRENT_REVISION_ID } from 'ContentEditorUI/api/RevisionsApi';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
// @ts-expect-error not yet migrated
import { getHardSavedContentModelIsPublishedNotScheduled } from 'ContentEditorUI/redux/selectors/hardSavedContentModelSelectors';
// @ts-expect-error not yet migrated
import { getIsEmbeddedEmailEditorRoute } from 'ContentEditorUI/redux/selectors/routingSelectors';
import get from 'hs-lodash/get';
export const getRevisionsData = basicSelector(state => state.revisions);
export const getScheduledRevision = createSelector(getRevisionsData, revisionsData => revisionsData.scheduledRevision);
export const getRevisions = createSelector(getRevisionsData, revisionsData => revisionsData.objects);
export const getRevisionsWithScheduledUpdate = createSelector(getRevisions, getScheduledRevision, getHardSavedContentModelIsPublishedNotScheduled, (revisionsList, scheduledRevision, isPublished) => {
  const revisions = revisionsList || [];
  return isPublished && scheduledRevision ? [scheduledRevision, ...revisions] : revisions;
});
export const getHasFirstRevisionForCurrentState = createSelector(getRevisions, revisions => revisions.length > 0 && revisions[0].id === CURRENT_REVISION_ID);
const getLimit = createSelector(getRevisionsData, revisionsData => revisionsData.limit);
export const getCurrentOffset = createSelector(getRevisionsData, revisionsData => revisionsData.offset);
export const getTotalRevisions = createSelector(getRevisionsData, getScheduledRevision, (revisionsData, scheduledRevision) => revisionsData.total + (scheduledRevision ? 1 : 0));
export const getHasMoreRevisions = createSelector([getCurrentOffset, getLimit, getTotalRevisions], (offset, limit, total) => {
  return offset + limit < total;
});
export const getRevisionsError = createSelector(getRevisionsData, revisionsData => revisionsData.error);
export const getRevisionHref = basicSelectorWithStats((state, revisionId) => {
  const id = getContentModelId(state);
  const isEmbeddedEmailEditorRoute = getIsEmbeddedEmailEditorRoute(state);
  const contentEditUrl = ContentRoutes.edit(id, getCategoryId(state));
  if (getIsEmail(state)) {
    const revisions = getRevisions(state);
    const matchingRevisions = revisions.find(r => r.id === revisionId);
    const revisionTemplateMode = get(matchingRevisions, ['object', 'emailTemplateMode']);
    const url = revisionTemplateMode === 'DRAG_AND_DROP' ? ContentRoutes.editDragDropEmail(id) : contentEditUrl;
    if (isEmbeddedEmailEditorRoute) {
      return url.replace('edit', 'edit-csp');
    }
  }
  return contentEditUrl;
});