// Replacement for Immutable.Set.isSuperset as we migrate them to native Sets.
// Can't use Set.prototype.isSupersetOf because it's not well supported in
// Firefox: https://caniuse.com/?search=isSupersetOf
//
// Inspired by the core-js polyfill https://github.com/zloirock/core-js/blob/master/packages/core-js/internals/set-is-superset-of.js
// but simplified for our use case as we don't need to support all the edge cases
// like checking for non-Set objects or if Set is defined.
export const isSuperset = (thisSet, otherSet) => {
  if (thisSet.size < otherSet.size) return false;
  for (const item of otherSet) {
    if (!thisSet.has(item)) {
      return false;
    }
  }
  return true;
};
export const unionSets = (firstSet, ...sets) => {
  const result = new Set(firstSet);
  for (const set of sets) {
    for (const item of set) {
      result.add(item);
    }
  }
  return result;
};