import { useSelector } from 'react-redux';
import { getGates } from '../selectors/auth';
import { useMemo } from 'react';
export const useGates = gatesSelector => {
  const gates = useSelector(gatesSelector || getGates);
  return useMemo(() => {
    let isUngatedForRejectApprovals = false;
    gates.forEach(gate => {
      if (gate === 'ProductApprovals:RejectApprovals') {
        isUngatedForRejectApprovals = true;
      }
    });
    return {
      isUngatedForRejectApprovals
    };
  }, [gates]);
};