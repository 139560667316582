let DEBUG_ENABLED = false;
export const GROUP_SPLIT_TYPES = {
  SPLIT_NOW: {
    now: true,
    next: false
  },
  SPLIT_NEXT: {
    next: true,
    now: false
  },
  DO_NOT_SPLIT: {
    now: false,
    next: false
  }
};
function allButFirst(arr) {
  return arr.slice(1);
}
function allButLast(arr) {
  if (arr.length === 0 || arr.length === 1) {
    return [];
  }
  return arr.slice(0, arr.length - 1);
}
function getLast(arr) {
  return arr[arr.length - 1];
}
function immutablePrepend(baseArr, val) {
  return [val, ...baseArr];
}
function immutablePush(baseArr, val) {
  return [...baseArr, val];
}
function getShouldTrackActions(skipActionTracking, __history) {
  return !skipActionTracking;
}
const logMessage = message => {
  if (DEBUG_ENABLED) {
    console.log(`Undo/Redo: ${message}`);
  }
};
function logStatus(message, history, action) {
  if (DEBUG_ENABLED) {
    console.groupCollapsed(`Undo/Redo: ${message} from ${action.type}`);
    console.log('Action', action);
    console.log('Past', history.past);
    console.log('Present', history.present);
    console.log('Future', history.future);
    if (history.groupKey) {
      console.log('Grouping by', history.groupKey);
    }
    console.log('Current present created at', history.currentPresentStart);
    console.groupEnd();
  }
}
function getInitialState(initialPresent, {
  ignoreInitialState,
  skipActionTracking
}) {
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: [],
    pastActionTypes: []
  };
  return Object.assign({
    _skipPastUpdate: ignoreInitialState,
    currentPresentStart: null,
    future: [],
    groupKey: null,
    past: [],
    present: initialPresent,
    redoCount: 0,
    undoCount: 0
  }, optionalProps);
}
function handleAdd(history, present, limit, groupKey, actionType, skipActionTracking) {
  let newPast = history.past;
  let _skipPastUpdate = history._skipPastUpdate;
  const optionalProps = skipActionTracking ? {} : {
    futureActionTypes: [],
    pastActionTypes: history.pastActionTypes
  };

  // Trim off least recent item (index 0) if we've exceeded the limit
  if (limit && history.past.length >= limit) {
    newPast = allButFirst(newPast);
    if (!skipActionTracking && optionalProps.pastActionTypes) optionalProps.pastActionTypes = allButFirst(optionalProps.pastActionTypes);
  }
  if (!_skipPastUpdate) {
    newPast = [...newPast, history.present];
    if (!skipActionTracking && optionalProps.pastActionTypes) optionalProps.pastActionTypes = [...optionalProps.pastActionTypes, actionType];
  } else {
    logMessage('leaving past unaltered during update (_skipPastUpdate = true)');
    _skipPastUpdate = false;
  }
  return Object.assign({
    _skipPastUpdate,
    currentPresentStart: Date.now(),
    future: [],
    groupKey,
    past: newPast,
    present
  }, optionalProps);
}
const undoRedoHelper = () => ({
  groupKey: null,
  currentPresentStart: null
});
function handleUndo(history, skipActionTracking) {
  if (history.past.length === 0) {
    return history;
  }
  const optionalProps = getShouldTrackActions(skipActionTracking, history) ? {
    futureActionTypes: immutablePrepend(history.futureActionTypes, getLast(history.pastActionTypes)),
    pastActionTypes: allButLast(history.pastActionTypes)
  } : {};
  return Object.assign({
    future: immutablePrepend(history.future, history.present),
    past: allButLast(history.past),
    present: getLast(history.past),
    undoCount: history.undoCount + 1
  }, optionalProps, undoRedoHelper());
}
function handleRedo(history, skipActionTracking) {
  if (history.future.length === 0) {
    return history;
  }
  const optionalProps = getShouldTrackActions(skipActionTracking, history) ? {
    futureActionTypes: allButFirst(history.futureActionTypes),
    pastActionTypes: history.futureActionTypes.length > 0 ? immutablePush(history.pastActionTypes, history.futureActionTypes[0]) : history.pastActionTypes
  } : {};
  return Object.assign({
    future: allButFirst(history.future),
    past: immutablePush(history.past, history.present),
    present: history.future[0],
    redoCount: history.redoCount + 1
  }, optionalProps, undoRedoHelper());
}
function generateUndoTrackingMessage(history, skipActionTracking) {
  return getShouldTrackActions(skipActionTracking, history) ? `Undo ${history.futureActionTypes[0]}` : 'Undo action';
}
function generateRedoTrackingMessage(history, skipActionTracking) {
  return getShouldTrackActions(skipActionTracking, history) ? `Redo ${history.pastActionTypes[0]}` : 'Redo action';
}

// higher order reducer to add history to redux state
export function withUndoableHistory(reducer, opts) {
  if (opts.debug) {
    DEBUG_ENABLED = true;
  }
  const config = {
    filter: opts.filter || (() => true),
    groupBy: opts.groupBy || (() => null),
    ignoreInitialState: opts.ignoreInitialState || false,
    limit: opts.limit,
    redoType: opts.redoType,
    splitGrouping: opts.splitGrouping || (() => GROUP_SPLIT_TYPES.DO_NOT_SPLIT),
    undoType: opts.undoType,
    clearUndoRedoTypes: opts.clearUndoRedoTypes || [],
    skipActionTracking: opts.skipActionTracking || false
  };
  if (!config.undoType || !config.redoType) {
    logMessage('Missing required options, undoType/redoType');
  }

  // Forces the next grouped action to split the grouping. Allows an action to
  // be grouped in "present", but still cause a split.
  let _splitNextGroupedAction = false;
  return function undoableReducer(state, action) {
    let history = state;
    if (!state) {
      logMessage('history uninitialized, creating initial state');
      const initialPresent = reducer(state, {
        type: '__INITIAL_REDUX_DISPATCH_SCP__'
      });
      history = getInitialState(initialPresent, config);
    }
    if (config.clearUndoRedoTypes.includes(action.type)) {
      const optionalProps = config.skipActionTracking ? {} : {
        futureActionTypes: [],
        pastActionTypes: []
      };
      const updatedHistory = Object.assign({}, history, {
        past: [],
        future: [],
        present: reducer(history.present, action),
        undoCount: 0,
        redoCount: 0
      }, optionalProps);
      logStatus('Clear undo/redo action', updatedHistory, action);
      return updatedHistory;
    }
    switch (action.type) {
      case config.undoType:
        {
          const updatedHistory = Object.assign({}, history, handleUndo(history, config.skipActionTracking));
          const message = generateUndoTrackingMessage(updatedHistory, config.skipActionTracking);
          logStatus(message, updatedHistory, action);
          return updatedHistory;
        }
      case config.redoType:
        {
          const updatedHistory = Object.assign({}, history, handleRedo(history, config.skipActionTracking));
          const message = generateRedoTrackingMessage(updatedHistory, config.skipActionTracking);
          logStatus(message, updatedHistory, action);
          return updatedHistory;
        }
      default:
        {
          const newPresent = reducer(history.present, action);
          if (history.present === newPresent) {
            // Undoable state was not altered
            return history;
          }
          const isAllowedToUpdatePast = config.filter(action);
          if (!isAllowedToUpdatePast) {
            // Filtered, only update present
            const updatedHistory = Object.assign({}, history, {
              present: newPresent
            });
            logStatus('filtered update', updatedHistory, action);
            return updatedHistory;
          }
          const newGroupKey = config.groupBy(action);
          const hasGroupKey = Boolean(newGroupKey);
          const splitGroupType = hasGroupKey ? config.splitGrouping(action, history) : GROUP_SPLIT_TYPES.DO_NOT_SPLIT;
          if (hasGroupKey && newGroupKey === history.groupKey) {
            // This is typically a grouped action, check if it needs to be split
            if (!splitGroupType.now && !_splitNextGroupedAction) {
              // Update splitNextGroupedAction so it applies to next grouped action, not this one
              _splitNextGroupedAction = splitGroupType.next;

              // Grouped, only update present
              const updatedHistory = Object.assign({}, history, {
                present: newPresent
              });
              logStatus('grouped update', updatedHistory, action);
              return updatedHistory;
            }
          }

          // Perform a standard update
          const updatedHistory = Object.assign({}, history, handleAdd(history, newPresent, config.limit, newGroupKey, action.type, config.skipActionTracking));
          let message = 'standard update';
          if (splitGroupType.now) {
            message = `grouping split, ${message}`;
          } else if (_splitNextGroupedAction) {
            message = `grouping split (via splitNextGroupedAction), ${message}`;
          }
          logStatus(message, updatedHistory, action);

          // Update splitNextGroupedAction because the next action might attempt to group
          _splitNextGroupedAction = splitGroupType.next;
          return updatedHistory;
        }
    }
  };
}