import I18n from 'I18n';
// @ts-expect-error not typed yet
import * as SimpleDate from 'UIComponents/core/SimpleDate';
export const getDateTimeInMinutes = dateInMilliseconds => {
  if (!dateInMilliseconds) {
    return undefined;
  }
  return (
    // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
    I18n.moment(dateInMilliseconds).portalTz().hours() * 60 +
    // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
    I18n.moment(dateInMilliseconds).portalTz().minutes()
  );
};
function getMaxDate(dateA, dateB) {
  return dateA.valueOf() > dateB.valueOf() ? dateA : dateB;
}
export function getEarliestDateForPublishing(publishDateInMs) {
  // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
  const currentTime = I18n.moment().portalTz();
  if (!publishDateInMs) {
    return currentTime;
  }
  // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
  return getMaxDate(currentTime, I18n.moment(publishDateInMs).portalTz());
}
export function createSimpleDateFromMomentTsIfExists(date) {
  if (!date) {
    return null;
  }
  // @ts-expect-error Property 'portalTz' does not exist on type 'Moment'.
  return SimpleDate.fromMoment(I18n.moment(date).portalTz());
}