import { FETCH_MODULE_VERSIONS_STARTED, FETCH_MODULE_VERSIONS_SUCCESS, FETCH_MODULE_VERSIONS_FAILURE } from 'ContentEditorUI/redux/actions/actionTypes';
import * as ModuleVersionsApi from 'ContentEditorUI/api/ModuleVersionsApi';
export const requestModuleVersions = () => ({
  type: FETCH_MODULE_VERSIONS_STARTED
});
export const receiveModuleVersions = payload => ({
  type: FETCH_MODULE_VERSIONS_SUCCESS,
  payload
});
export const receiveModuleVersionsFailure = error => ({
  type: FETCH_MODULE_VERSIONS_FAILURE,
  error
});
export const fetchModuleVersions = () => dispatch => {
  dispatch(requestModuleVersions());
  const success = resp => dispatch(receiveModuleVersions(resp));
  const error = resp => dispatch(receiveModuleVersionsFailure(resp));

  // We used to cache module schemas locally here.
  // See this PR for how that worked:
  // https://git.hubteam.com/HubSpot/ContentEditorUI/pull/15555

  return ModuleVersionsApi.fetchModuleVersions().then(success, error);
};