import IntegrationStatuses from 'ContentUtils/constants/IntegrationStatuses';
import * as FieldTypes from 'ContentUtils/constants/CustomWidgetFieldTypes';
import AdditionalResources from 'ContentUtils/constants/AdditionalResources';
export const RESOURCES_BY_TYPE = Object.freeze({
  [FieldTypes.BLOG]: 'blogs',
  [FieldTypes.CTA]: 'ctas',
  [FieldTypes.FOLLOW_UP_EMAIL]: 'emails',
  [FieldTypes.FORM]: 'forms',
  [FieldTypes.HUBDB_TABLE]: 'hubdb',
  [FieldTypes.MENU]: 'menus',
  [FieldTypes.TAG]: 'tags',
  [FieldTypes.EMAIL]: 'users',
  [FieldTypes.WORKFLOW]: 'workflows',
  [FieldTypes.PAGE]: 'pages',
  [FieldTypes.MEETING]: 'meetings',
  [FieldTypes.SFDC_CAMPAIGN]: 'salesforceCampaigns',
  [AdditionalResources.SALESFORCE_INTEGRATION_STATUS]: 'salesforceIntegrationStatus'
});
export const SUPPORTED_DATA_TOKEN_FIELDS = Object.freeze({
  [FieldTypes.TEXT]: FieldTypes.TEXT
});

/* TODO: Remove once refactor is done and we stop using  in the ContentUILib */
export const RESOURCES_TO_FETCH_BY_TYPE = {};
export const DEFAULT_PAGE_SIZE = 15;
export const CAN_CREATE_RESOURCES = Object.freeze([FieldTypes.WORKFLOW, FieldTypes.FOLLOW_UP_EMAIL, FieldTypes.FORM]);
export const VALID_INTEGRATION_STATUSES = Object.freeze({
  [IntegrationStatuses.INTEGRATED]: IntegrationStatuses.INTEGRATED,
  [IntegrationStatuses.INTEGRATED_AND_DISABLED]: IntegrationStatuses.INTEGRATED_AND_DISABLED,
  [IntegrationStatuses.REAUTHORIZE]: IntegrationStatuses.REAUTHORIZE,
  [IntegrationStatuses.CONNECTED]: IntegrationStatuses.CONNECTED,
  [IntegrationStatuses.USER_DISABLED]: IntegrationStatuses.USER_DISABLED
});
export const CTA_PICKER_STATE = Object.freeze({
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  EDIT: 'EDIT'
});
export const HOST_TEMPLATE_TYPES = Object.freeze({
  NONE: 'NONE',
  ANY: 'ANY',
  PAGE: 'PAGE',
  EMAIL: 'EMAIL',
  BLOG_POST: 'BLOG_POST',
  BLOG_LISTING: 'BLOG_LISTING'
});
export const ASYNC_SELECTOR_DEBOUNCE = 300;
export const CSS_UNITS = Object.freeze({
  '%': '%',
  ch: 'ch',
  em: 'em',
  ex: 'ex',
  in: 'in',
  lh: 'lh',
  pc: 'pc',
  pt: 'pt',
  px: 'px',
  Q: 'Q',
  rem: 'rem',
  vh: 'vh',
  vmax: 'vmax',
  vmin: 'vmin',
  vw: 'vw'
});
export const RELATIVE_SIZE_UNITS = Object.freeze({
  [CSS_UNITS.em]: CSS_UNITS.em,
  [CSS_UNITS.rem]: CSS_UNITS.rem,
  [CSS_UNITS.ex]: CSS_UNITS.ex,
  [CSS_UNITS.ch]: CSS_UNITS.ch
});
export const RELATIVE_SIZE_UNIT_STEP_SIZE = 0.1;
export const ABSOLUTE_SIZE_UNIT_STEP_SIZE = 1;
export const FORM_RESPONSE_TYPE_OPTIONS = Object.freeze({
  REDIRECT: 'redirect',
  INLINE: 'inline'
});
export const TEXT_ALIGN = Object.freeze({
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  JUSTIFY: 'JUSTIFY'
});
export const HORIZONTAL_ALIGNMENT = Object.freeze({
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
});
export const VERTICAL_ALIGNMENT = Object.freeze({
  BOTTOM: 'BOTTOM',
  MIDDLE: 'MIDDLE',
  TOP: 'TOP'
});
export const ALIGNMENT_DIRECTION = Object.freeze({
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  BOTH: 'BOTH'
});
export const FIELD_DISPLAY_WIDTHS = Object.freeze({
  HALF_WIDTH: 'half_width'
});
export const ICON_UNICODE_HUBSPOT = 'f3b2';
export const HUBSPOT_META_VALUE_KEY = 'hs_meta';
export const DISPLAY_OPTIONS = Object.freeze({
  DRILLDOWN: 'drilldown',
  ACCORDION: 'accordion',
  INLINE: 'inline'
});
export const FIELD_PROPS_WITH_LINKS = ['disabled_controls.message', 'help_text', 'inline_help_text', 'validation_error_message' //text field
];