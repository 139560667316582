import PortalIdParser from 'PortalIdParser';
import { KycApplicationType } from '../types/kycApplicationType';
import { ProcessorPickerStatus } from '../types/processorPickerStatus';
const SETTINGS_BASE_URL = '/settings';
export const getRootUrl = () => `${SETTINGS_BASE_URL}/${PortalIdParser.get()}`;
export const getUsersAndTeamsURL = () => `${getRootUrl()}/users`;
export const termsOfServiceURL = 'https://legal.hubspot.com/payments-terms-of-use';
export const stripeIntegrationBetaTerms = 'https://legal.hubspot.com/stripe-integration-beta-terms';
export const PaymentMarketplaceURL = `/ecosystem/${PortalIdParser.get()}/marketplace/apps/apps-for-payments`;
export const userPermissionsKnowledgeURL = 'https://knowledge.hubspot.com/settings/edit-user-permissions';
export const getPaymentsURL = () => `${getRootUrl()}/sales/payments`;
export const getCommerceHomeUrl = () => `/commerce/${PortalIdParser.get()}`;
export const getCommerceHomeZeroStateUrl = () => `/commerce/${PortalIdParser.get()}/zero-state`;
export const getCommerceExpertLink = () => 'https://www.hubspot.com/meetings/jcoopersmith1/payments';
export const getPaymentsKYCOpenURL = () => `${getCommerceHomeUrl()}?kyc=${KycApplicationType.native}`;
export const getPaymentsBYOSOpenURL = () => `${getCommerceHomeUrl()}?kyc=${KycApplicationType.stripe}`;
export const getPaymentsProcessorPickerOpenURL = () => `${getCommerceHomeUrl()}?processor-picker=${ProcessorPickerStatus.active}`;

// We can't use the enum from payments-enrollment-embed-lib for the query param
// value here. Could potentially look into moving those type definitions into
// growth-payments-core.
export const getCommerceHomeProcessorPickerIframeOpenURL = () => `${getCommerceHomeZeroStateUrl()}?paymentsEnrollment=processorPicker`;
export const getCommerceHomeBYOSIframeOpenURL = () => `${getCommerceHomeZeroStateUrl()}?paymentsEnrollment=stripe`;
export const getCommerceHomeHSPIframeOpenURL = () => `${getCommerceHomeZeroStateUrl()}?paymentsEnrollment=native`;
export const getPaymentLinksURL = () => `/payment-links/${PortalIdParser.get()}`;
export const getSharePaymentsUsePaymentLinkOrQuote = () => `/share-payments/${PortalIdParser.get()}/usePaymentLinkOrQuote`;
export const getPaymentLinksTemplateURL = template => {
  const baseUrl = getPaymentLinksURL();
  const templateQuery = template ? `?selectedTemplate=${template}` : '';
  return `${baseUrl}/templates${templateQuery}`;
};
export const getQuotesURL = () => `/quotes/${PortalIdParser.get()}`;
export const getCreateQuoteURL = () => `${getQuotesURL()}/edit/new`;
export const getInvoicesURL = () => `/invoices/${PortalIdParser.get()}`;
export const getCreateInvoicesURL = () => `${getInvoicesURL()}/invoice/create`;
export const getPaymentsPaywallURL = () => {
  return `/upgrade/${PortalIdParser.get()}/payments`;
};
export const getProductsURL = () => {
  return `/contacts/${PortalIdParser.get()}/objects/0-7`;
};
export const getSubscriptionsURL = () => `/contacts/${PortalIdParser.get()}/objects/0-69/views/all/list`;
export const getCommerceHubPricingPageURL = () => `/pricing/${PortalIdParser.get()}/commerce`;
export const getPaymentsIndexURL = () => `/contacts/${PortalIdParser.get()}/objects/0-101/views/all/list`;
export const getAccountBrandingURL = () => `${SETTINGS_BASE_URL}/${PortalIdParser.get()}/account-defaults/branding`;
export const getWebsitesURL = () => `/website/${PortalIdParser.get()}/pages/site/all`;