'use es6';

import { createSelector } from 'reselect';
import { normalizeModuleErrorTokens } from 'ContentEditorUI/utils/moduleErrorUtils';
import { getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { findModuleById } from 'ContentEditorUI/data/moduleUtils';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
const ID_MATCHER = /^((widget|module)_*)(\d*)/;
const parseModuleId = id => {
  if (ID_MATCHER.test(id)) {
    return ID_MATCHER.exec(id)[3];
  }
  return id;
};
const convertErrorTokensToObject = error => {
  return error.get('errorTokens').map(v => v.get(0)).toJS();
};
const moduleIdForError = error => convertErrorTokensToObject(error).id;
const getRawModuleErrors = basicSelector(state => state.moduleErrors);
export const getResources = basicSelector(state => state.resources);
export const getModulesWithErrors = createSelector([getRawModuleErrors], moduleErrors => {
  return moduleErrors.filter(error => error.getIn(['errorTokens', 'id', 0])).map(error => parseModuleId(error.getIn(['errorTokens', 'id', 0]))).toSet();
});
export const getModuleHasErrors = basicSelectorWithStats((state, module) => {
  return getModulesWithErrors(state).includes(parseModuleId(`${module.get('id')}`));
});
export const makeDataValidationErrors = modulesErrorsSelector => createSelector([modulesErrorsSelector, getResources, getModules, getAllModuleSchemasAsArray, getModuleIdsByBuiltinType, getCategoryId], (moduleErrors, resources, modules, allModuleSchemasArray, moduleIdsByBuiltinType, categoryId) => {
  return moduleErrors.reduce((result, error) => {
    const tokens = convertErrorTokensToObject(error);
    const module = findModuleById(modules, tokens.id);
    if (!module) {
      // module has been deleted since validation call or is "orphaned" (has a deleted_at property)
      return result;
    }
    const schema = getSchemaForModuleHelper(module, allModuleSchemasArray, moduleIdsByBuiltinType, categoryId);
    const errorTokens = normalizeModuleErrorTokens(module, schema, tokens, resources);
    if (errorTokens) {
      result[`moduleData.${errorTokens.id}.${errorTokens.path}`] = {
        pane: 'content',
        type: 'moduleDataValidation',
        errorType: error.get('errorType'),
        errorTokens,
        message: error.get('message')
      };
    }
    return result;
  }, {});
});
export const getDataValidationErrors = makeDataValidationErrors(getRawModuleErrors);
export const getHasAnyModulesWithErrors = createSelector(getRawModuleErrors, getModules, (moduleErrors, modules) => {
  const errorsForModulesThatExist = moduleErrors.filter(error => {
    // The modules map already omits modules that are orphaned (deleted_at) or in orphaned containers
    return findModuleById(modules, moduleIdForError(error));
  });
  return errorsForModulesThatExist.size > 0;
});