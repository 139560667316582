export const Alignments = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  NONE: 'none',
  JUSTIFY: 'justify'
};
export const VerticalAlignments = {
  BOTTOM: 'baseline',
  MIDDLE: 'middle',
  TOP: 'top'
};
export const AlignmentsFormat = {
  [Alignments.LEFT]: 'alignleft',
  [Alignments.CENTER]: 'aligncenter',
  [Alignments.RIGHT]: 'alignright',
  [Alignments.JUSTIFY]: 'alignjustify',
  [Alignments.NONE]: ''
};
export const AlignmentIconMap = {
  left: 'imageAlignmentLeft',
  center: 'imageAlignmentMiddle',
  right: 'imageAlignmentRight',
  none: 'inline',
  baseline: 'blockAlignmentBottom',
  middle: 'blockAlignmentCenter',
  top: 'blockAlignmentTop'
};